import React from "react";
import { history } from "../../..";
import './UploadSuccess.scss';

class UploadSuccess extends React.Component {
  state = {
    h: '',
    // timer: 8,
    // timerObj: null
  }

  goToNext = () => {
    history.push(`/order-status?h=${this.state.h}`);
  }

  componentDidMount() {
    this.setState({
      h: this.props.location.search.split('?h=')[1]
    });
      // timerObj: setInterval((e) => {
      //   if(this.state.timer === 0) {
      //     clearInterval(this.state.timerObj);
      //     history.push(`/order-status?h=${this.state.h}`)
      //   } else {
      //     this.setState({timer: this.state.timer - 1})
      //   }
      // }, 1000)
  }

  render() {
    return (
    <div className="upload-success">
      <div className="upload-success-card">
        <h2>Uploaded!</h2>
        <p>We have notified the seller to prepare your order.</p>
        <button className="confirm-btn" onClick={this.goToNext}>Proceed</button>

        {/* {this.state.timer <= 5 &&  <p>Redirecting you in {this.state.timer}</p>} */}
      </div>
    </div>);
  }
}

export default (UploadSuccess);

import axios from 'axios';
import { readObject, saveObject, saveValue } from '../libs/localstorage';
import {history} from '../index';
import { toast } from 'react-toastify';

export const onSessionCheck = () => {
  return dispatch => {
    const credentials = readObject('userObject');
    const credentialsMerchant = readObject('merchant');
    dispatch(onSessionsCheck( { isLoggedIn: !!credentials && !!credentialsMerchant, session: credentials} ));
  }
};

export const onLoginRequest = ({email, password}) => {
  return dispatch => {
    axios.post(`${process.env.REACT_APP_API_URL}/users/login`, { user: {
      email, password }})
    .then(res => { 
      saveObject(res.data["user"], 'userObject');
      saveObject(res.data["merchant"], 'merchant');
      saveObject(res.data["onboarding_session"], 'onboarding');
      saveValue(res.data["token"], 'access_token');
      localStorage.removeItem('signupState');
      
      history.push('/dashboard');
      dispatch(onLogin(res.data));
     })
    .catch(error => {dispatch(onLoginFailed(error.message))});
  }
};

export const onLogoutAct = (redirectToHome = true, url = '/') => {
  return dispatch => {
    localStorage.removeItem('userObject');
    localStorage.removeItem('onboarding');
    localStorage.removeItem('access_token');
    localStorage.removeItem('merchant');
    localStorage.removeItem('signupState');
    if(redirectToHome) {
      history.push(url);
    }

    dispatch(onLogout());
  }
}

export const onSessionLogoutOnExpiry = () => {
  return dispatch => {
    dispatch(onLogoutAct(true, '/login'));
    toast("We have logged you out of your account for server maintenance, kindly log in again.", {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 10000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: "error"});
  }
};



const onSessionsCheck = data => ({
  type: 'SESSION_CHECK',
  payload: {
    ...data
  }
});

export const onLoginData = data => ({
  type: 'SESSION_LOGIN_STATE_ONLY',
  payload: data
  
});

const onLogin = data => ({
  type: 'SESSION_LOGIN',
  payload: {
    ...data
  }
});

const onLoginFailed = error => ({
  type: 'SESSION_LOGIN_FAILED',
  payload: {
    error
  }
});

const onLogout = () => ({
  type: 'SESSION_LOGOUT'
});

export const signupStateCheck = (data) => ({
  type: 'SIGNUP_STATE_CHECK',
  payload: data
});


export const onSessionErrorClear = () => ({
  type: 'SESSION_CLEAR_ERROR'
});


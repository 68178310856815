
import axios from 'axios';
import { toast } from 'react-toastify';
import {history} from '../index';
import { saveObject, saveValue, readObject } from '../libs/localstorage';

export const onOtp = ({otp}) => {
  return dispatch => {
    dispatch(otpStarted());
    const user_id = readObject("userObject")["id"];
    axios.post(`${process.env.REACT_APP_API_URL}/otp`, { otp, user_id } )
    .then(res => { 
      dispatch(otpSuccess(res.data));
      saveObject(res.data["onboarding_session"], 'onboarding');
      saveValue(res.data["token"], 'access_token');
      saveObject({page: `/signup/${res.data['onboarding_session']['step']}`}, "signupState");
      history.push(res.data["onboarding_session"]["step"]);
     })
    .catch(error => {
      toast(error.response.data.error, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: "error"});
      dispatch(otpFailed(error.message));
    });
  }
};

export const onResendOtp = () => {
  return dispatch => {
    dispatch(otpStarted());
    const email = readObject("userObject")["email"];
    axios.post(`${process.env.REACT_APP_API_URL}/resend_otp`, { user: {email} } )
    .then(res => {
      toast("Successfully generated a new OTP, please check your email", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: "success"});
     }).catch( () => {
      toast("Error resending your OTP, please try again later", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: "error"});
    });
  }
}

const otpSuccess = data => ({
  type: 'OTP_SUCCESS',
  payload: {
    ...data
  }
});

const otpStarted = () => ({
  type: 'OTP_STARTED'
});

const otpFailed = error => ({
  type: 'OTP_FAILED',
  payload: {
    error
  }
});
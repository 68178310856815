import { readObject, saveObject } from "../libs/localstorage";

const initializeState = () => {
  let cart = readObject(`cart`);

  if(!cart) {
    cart = {merchantCarts: [], createdAt: new Date()};
    saveObject( cart ,"cart");
  }
  return cart;
}

const getOrInitializeMerchantCart = (state, merchant_store_id) => {
  const merchantCart = state.merchantCarts.filter(m => m.id === merchant_store_id)[0];
  if(merchantCart) return merchantCart;

  return {
    id: merchant_store_id,
    cartItems: [],
    currentStep: 0,
    form: {}
  };
}

export default function cartReducer(state = initializeState(), action) {
  switch(action.type) {
    case 'CART_ADD_PRODUCT':
      const cart = getOrInitializeMerchantCart(state, action.payload.merchant_store_id);
      let existingItem = cart.cartItems.findIndex((p) => {
        return p.product.id === action.payload.product.id;
      });


      if(existingItem > -1) {
        cart.cartItems[existingItem]["quantity"] += action.payload.quantity;
      } else {
        cart.cartItems = cart.cartItems.concat({ product: action.payload.product, quantity: action.payload.quantity, parentProduct: action.payload.parentProduct });
      }

      let actualCart = state;
      //replace merchant cart
      actualCart.merchantCarts = actualCart.merchantCarts.filter( c => c.id !==  action.payload.merchant_store_id).concat(cart);
      saveObject( actualCart ,"cart");
      return { ...state, ...actualCart };
    case 'REMOVE_PRODUCT_FROM_CART':
      const cart2 = getOrInitializeMerchantCart(state, action.payload.merchant_store_id);
      cart2.cartItems = cart2.cartItems.filter( (e, idx) => idx !== action.payload.index);
      let actualCart2 = state;

      //replace merchant cart
      actualCart2.merchantCarts = actualCart2.merchantCarts.filter( c => c.id !==  action.payload.merchant_store_id).concat(cart2);
      saveObject( actualCart2 ,"cart");
      return { ...state, ...actualCart2 };
    case 'CLEAR_REDUX_CART':
      const cart3 = getOrInitializeMerchantCart(state, action.payload);
      cart3.cartItems = [];
      let actualCart3 = state;

      //replace merchant cart
      actualCart3.merchantCarts = actualCart3.merchantCarts.filter( c => c.id !==  action.payload).concat(cart3);
      saveObject( actualCart3 ,"cart");
      return { ...state, ...actualCart3};
    case 'CHANGE_QUANTITY_TO_CART_ITEM':
      const cart4 = getOrInitializeMerchantCart(state, action.merchantStoreId);
      let existingItem2 = cart4.cartItems.findIndex((p) => {
        return p.product.id === action.id;
      });
      let actualCart4 = state;

      if(existingItem2 > -1) {
        cart4.cartItems[existingItem2]["quantity"] = action.quantity;
        actualCart4.merchantCarts = actualCart4.merchantCarts.filter( c => c.id !==  action.merchantStoreId).concat(cart4);
        saveObject( actualCart4 ,"cart");
        return { ...state, ...actualCart4};
        
      } else {
        return { ...state}
      }
    case 'UPDATE_CART_QUANTITIES_FROM_BE':
      const cart5 = getOrInitializeMerchantCart(state, action.merchantStoreId);
      let actualCart5 = state;
      
      for(let ci of action.payload) {
        let existingItem3 = cart5.cartItems.findIndex((p) => {
          return p.product.id === ci.id;
        });
        if(existingItem3 > -1) {
          cart5.cartItems[existingItem3]["product"]["quantity"] = ci.current_quantity;

          if("deleted_product" in ci &&  !!ci.deleted_product) {
            cart5.cartItems[existingItem3]["product"]["deleted_product"] = true;
          }
        }
      }

      actualCart5.merchantCarts = actualCart5.merchantCarts.filter( c => c.id !==  action.merchantStoreId).concat(cart5);
      saveObject( actualCart5 ,"cart");
      return { ...state, ...actualCart5};
    default:
      return state;
  }
}
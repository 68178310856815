import React from 'react';
import Logo from '../Logo/Logo';
import './Login.scss';
import { Link } from 'react-router-dom';
import { ValidationTextFieldRff } from '../../libs/ob_material';
import { connect } from 'react-redux';
import { onLoginRequest, onSessionErrorClear } from '../../actions/sessionActions';
import { Form } from 'react-final-form';
import { toast } from 'react-toastify';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { IconButton, InputAdornment } from '@material-ui/core';
import { history } from '../..';

class Login extends React.Component {
  state = {
    showPassword: false,
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleClickShowPassword = () => {
    this.setState({showPassword: !this.state.showPassword });
    setTimeout( () => {
    })
  };

  validate = values => {
    const errors = {};
   
    if (!values.email) {
      errors.email = 'Required';
    }
    // if(values.email) {
    //   const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //   if(!re.test(String(values.email).toLowerCase())) {
    //     errors.email = 'Please enter a valid email address'
    //   }
    // }

    if (!values.password) {
      errors.password = 'Required';
    }
    return errors;
  };

  componentDidMount() {
    if(this.props.isLoggedIn) {
      history.push('/dashboard/home');
    }
  }

  componentDidUpdate = () => {
    if(this.props.error) {
      this.displayCustomServerError('Login credentials incorrect')
      this.props.onSessionErrorClear();
    }

    if(this.props.isLoggedIn) {
      history.push('/dashboard/home');
    }
  }
  // TODO : emojis
  displayCustomServerError = (errorMsg) => {
    toast(`${errorMsg}`, {
      position: "top-right",
      autoClose: 3500,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: "error"
    });
  }

  handleSubmit = ({email, password} ) => {
    this.props.onLoginRequest({email, password});
  }



  render() {
    return (
      <div className="container">
        <Logo></Logo>
        <div className='login-container' onSubmit={this.handleSubmit}>
          <div className="headerText">Log In</div>
          <div className="subHeaderText">Using your existing OrderBasket merchant account</div>
          <br/>
          <Form
            onSubmit={this.handleSubmit}
            validate={this.validate}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="login-input-container">
                  <ValidationTextFieldRff
                    variant="outlined"
                    label="Email Address"
                    name="email"
                    type="email"
                    required={true}
                  />
                </div>
                <div className="login-input-container">
                  <ValidationTextFieldRff
                    variant="outlined"
                    label="Password"
                    name="password"
                    required={true}
                    type={this.state.showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (<InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}>
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>)
                    }}
                    />
                </div>
                <input type="submit" className="submit confirm-btn"></input>
              </form>
              )}>
            </Form>
          <div className="subHeaderText">
            <Link to="/forgot-password">Forgot password?</Link> 
            <br/>
            <br/>
            Don't have an account yet? &nbsp;&nbsp;
            <Link to="/signup">Sign up!</Link>
          </div>
        </div>
  
      </div>
      );
  }
    
}

const mapStateToProps = state => { 
  return {
    error: state.session.error,
    isLoggedIn: state.session.isLoggedIn
  }
};

const mapDispatchToProps = {
  onLoginRequest, onSessionErrorClear
}

export default connect(mapStateToProps,mapDispatchToProps)(Login);
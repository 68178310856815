import './CustomerInfo.scss';
import React from "react";
import { connect } from 'react-redux';
import { ValidationTextFieldRff } from '../../../../libs/ob_material';


class CustomerInfo extends React.Component {
  render() {
    return (<div className="cf-order-wizard-customer-info cf-order-wizard-content">
      <div className='step-header' style={{marginBottom: 30}}>Customer Info</div>
      <div className="form-container">
        <ValidationTextFieldRff
          variant='outlined'
          name="order_first_name"
          label="First Name *"/>
      </div>
      <div className="form-container">
        <ValidationTextFieldRff
          variant='outlined'
          name="order_last_name"
          label="Last Name *"/>
      </div>
      <div className="form-container">
        <ValidationTextFieldRff
          variant='outlined'
          name="cellphone_number"
          label="Phone No. *"/>
      </div>

      <div className="form-container">
        <ValidationTextFieldRff
          variant='outlined'
          name="email"
          label="Email *"/>
      </div>

      <div className="form-container">
        <ValidationTextFieldRff
          variant='outlined'
          name="insta_handle"
          label="Instagram Name"/>
      </div>

      <div className="form-container">
        <ValidationTextFieldRff
          variant='outlined'
          name="fb_handle"
          label="Facebook Name"/>
      </div>

      { this.props.w_form.purchase_type === 'Delivery' && 
        <div>
        <div className='step-header' style={{marginBottom: 30}}>Delivery Address</div>
        <div className="form-container">
          <ValidationTextFieldRff
            variant='outlined'
            name="house_number"
            label="Unit and Floor Number *"/>
        </div>
        <div className="form-container">
          <ValidationTextFieldRff
            variant='outlined'
            name="street"
            label="Street *"/>
        </div>
        <div className="form-container">
          <ValidationTextFieldRff
            variant='outlined'
            name="building"
            label="Building"/>
        </div>
        <div className="form-container">
          <ValidationTextFieldRff
            variant='outlined'
            name="subdivision"
            label="Subdivision"/>
        </div>
        <div className="form-container">
          <ValidationTextFieldRff
            variant='outlined'
            name="barangay"
            label="Barangay *"/>
        </div>
        <div className="form-container">
          <ValidationTextFieldRff
            variant='outlined'
            name="city"
            label="City *"/>
        </div>
        <div className="form-container">
          <ValidationTextFieldRff
            variant='outlined'
            name="province"
            label="Province *"/>
        </div>
        <div className="form-container">
          <ValidationTextFieldRff
            variant='outlined'
            name="zip"
            label="Zip"/>
        </div>
      </div>
      }
    </div>);
  }
}



const mapStateToProps = (state) => {
  return {
    merchant_store: state.customer.merchant_store,
    w_form: state.wizard.form,
  }
}


export default connect(mapStateToProps)(CustomerInfo);
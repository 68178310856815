import React from 'react';
import './BackButton.scss';
import { ChevronLeft } from 'react-feather';
import { history } from '../../..';

function BackButton(props){
  return (
    <ChevronLeft onClick={() => props.isBack ? history.goBack() : history.push(props.pathToBack)} className="ob-md-back-btn"></ChevronLeft>
  );
}

export default BackButton;


const initialState = {
  loading: false,
  orders: [],
  selected_order: null,
  reload_selected_order: false,
  selectedOrderStatus: 'Unconfirmed',
  count: 0,
  filterToggled: false,
  filters: [
  ],
  sort: null,
  plan_metadata: {},
  showGlance: false
};

// HERE FOR MOBILE - INITIAL ORDER SELECTION
function getFirstPossibleOrder(o) {
  const width = window.innerWidth

  if (width > 768) {
    if (o.orders["For Confirmation"].length) {
      return o.orders["For Confirmation"][0]
    } else if (o.orders["Payment Pending"].length) {
      return o.orders["Payment Pending"][0]
    }
  }
}

function flatt(orders) {
  return Object.values(orders.orders).flat();
}

function getOrderWithOrderNumber(orderNumber,orders) {
  const arr = flatt(orders);
  let foundObject = arr.find(e => e.order_number === orderNumber);

  if(!foundObject) {
    foundObject = getFirstPossibleOrder(orders);
  }

  return foundObject;
}

// HERE FOR MOBILE NO SELECTION ON CHANGE ORDER STATUS
function getFirstOrderWithOrderStatus(orderStatus,orders) {
  const width = window.innerWidth
  if (width > 768) {
    if (orderStatus === 'Unconfirmed') {
      if (orders.orders["For Confirmation"].length) {
        return orders.orders['For Confirmation'][0];
      } else if (orders.orders["Payment Pending"].length ) {
        return orders.orders["Payment Pending"][0]
      }
    }
    return orders.orders[orderStatus][0];
  }
}

export default function orderReducer(state = initialState, action) {
  switch(action.type) {
    case 'LOAD_ORDER_STARTED':
      return {...state, loading: true};
    case 'LOAD_ORDER_COMPLETED':
      let selectedOrder = null;
      if(state.reload_selected_order && state.selected_order) {
        selectedOrder = getOrderWithOrderNumber(state.selected_order.order_number, action.payload.orders);
      } else {
        if(!state.selected_order) {
          selectedOrder = getFirstPossibleOrder(action.payload.orders);
        } else {
          selectedOrder = state.selected_order;
        }
      }

      let selectedOS = state.selectedOrderStatus === 'Unconfirmed' ? 'Unconfirmed' : selectedOrder.order_status;
      return {
        ...state, 
        loading: false,
        orders: action.payload.orders,
        count: flatt(action.payload.orders).length,
        selected_order: selectedOrder,
        reload_selected_order: false,
        filterToggled: false,
        plan_metadata: action.payload.orders.plan_metadata,
        selectedOrderStatus: selectedOS
      };

    case 'CHANGE_SELECTED_ORDER':
      return {...state, selected_order: action.payload.selected_order, showGlance: false};
    case 'CHANGE_SELECTED_ORDER_STATUS':
      const order = getFirstOrderWithOrderStatus(action.payload.selectedOrderStatus, state.orders);
      return {...state, selectedOrderStatus: action.payload.selectedOrderStatus, filterToggled: true, selected_order: order};
    case 'CHANGE_SELECTED_ORDER_STATUS_NO_TOGGLE':
      const order2 = getFirstOrderWithOrderStatus(action.payload.selectedOrderStatus, state.orders);
      return {...state, selectedOrderStatus: action.payload.selectedOrderStatus, filterToggled: false,selected_order: order };
    case 'TOGGLE_GLANCE':
      return {...state, showGlance: !state.showGlance}
    case 'MODIFY_ORDER_COMPLETED':
      return {...state, reload_selected_order: true, filterToggled: false};
    case 'CHANGE_ORDER_FILTER':
      return {...state, filters: action.payload.filters, filterToggled: true};
    case 'SET_INITIAL_FILTER_OBJECT':
      return {...state, filters: action.payload.filters, filterToggled: false};
    case 'CLEAR_ORDER_FILTER':
      return {...state, filters: initialState.filters, filterToggled: true};
    case 'CLEAR_ORDER_SORT':
      return {...state, sort: initialState.sort, filterToggled: true};
    case 'CHANGE_ORDER_SORT':
      return {...state, sort: action.payload.sort, filterToggled: true};
    default:
      return state;
  }
}
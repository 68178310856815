import React from "react";
import './QuantityTicker.scss';

class QuantityTicker extends React.Component {

  render() {
    return (<div className="display-quantity">
      {!!this.props.displayLabel && <b style={{marginRight: 9}}><span className="red">*</span>&nbsp;Quantity:</b>}
      <div className='display-quantity-controls' onClick={this.props.decrementQuantity}>-</div>
      <div className='display-quantity-controls'>{this.props.currentQuantity}</div>
      <div className='display-quantity-controls' onClick={this.props.incrementQuantity}>+</div>
      {/* {!!this.props.errorText && <div className='error-text'>{this.props.errorText}</div>} */}
      {!!this.props.showInfoText && <div className={this.props.maxValue >= this.props.currentQuantity  ? 'info-text' : 'error-text'}>Available: {this.props.maxValue}</div>}
    </div>);
  }
}

export default QuantityTicker;
import { TextField as TextFieldRff} from 'mui-rff';
import { Checkboxes as CheckboxRff} from 'mui-rff';
import {Switches} from 'mui-rff';
// import { Select as SelectRff} from 'mui-rff';
import TextField from '@material-ui/core/TextField';
import {
  withStyles,
} from '@material-ui/core/styles';
import { Checkbox, FormControl, Radio, StepConnector, Switch } from '@material-ui/core';
import React from 'react';

export const ValidationTextFieldRff = withStyles({
  root: {
    width: '100%',
    borderRadius: 10,

    '& input': {
      // backgroundColor: 'white',
      borderRadius: 10,
    },
    '& input:valid + fieldset': {
      borderWidth: 2,
      borderRadius: 10,
    },
    "& label.Mui-focused": {
      color: "#A357C6"
    },
    '& input:valid:focus + fieldset': {
      // borderLeftWidth: 6,
      borderColor: '#A357C6',
      padding: '4px !important'
    },
    '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: '#A357C6 !important',
      padding: '4px !important',
      borderRadius: 10,
    },
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      padding: '4px !important',
      borderRadius: 10,
      borderWidth: 2,
    }
  }
})(TextFieldRff);

export const ValidationTextField = withStyles({
  root: {
    width: '100%',
    borderRadius: 10,
    backgroundColor: 'white',

    '& input:valid + fieldset': {
      borderWidth: 2,
      borderRadius: 10
    },
    "& label.Mui-focused": {
      color: "#A357C6"
    },
    '& input:valid:focus + fieldset': {
      // borderLeftWidth: 6,
      borderColor: '#A357C6',
      padding: '4px !important'
    },
    '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: '#A357C6 !important',
      padding: '4px !important',
      borderRadius: 10
    },
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      padding: '4px !important',
      borderRadius: 10,
      borderWidth: 2,

    }
  },
})(TextField);

export const OBFormControl = withStyles({
  root: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: 10
    },
    '& .Mui-focused': {
      color: "#A357C6",
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#A357C6 !important',
    }
  }
})(FormControl);


export const OBSwitch = withStyles({
  switchBase: {
    // color: '#A357C6',
    '&$checked': {
      color: '#A357C6',
    },
    '&$checked + $track': {
      backgroundColor: '#A357C6',
    },
  },
  checked: {},
  track: {},
})(Switch);

export const OBSwitchRff = withStyles({
  switchBase: {
    // color: '#A357C6',
    '&$checked': {
      color: '#A357C6',
    },
    '&$checked + $track': {
      backgroundColor: '#A357C6',
    },
    // '.&MuiTypography-body1.MuiFormControlLabel-label': {
    //   fontWeight: 'bold'
    // }
  },
  checked: {},
  track: {},
})(Switches);

export const ObCheckbox = withStyles({
  root: {
    // color: '#A357C6',
    '&$checked': {
      color: '#A357C6',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const ObCheckboxRff = withStyles({
  root: {
    // color: '#A357C6',
    '&$checked': {
      color: '#A357C6',
    },
  },
  checked: {},
})((props) => <CheckboxRff color="default" {...props} />);

export const OBConnector = withStyles({
  active: {
    '& $line': {
      borderColor: '#A357C6',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#A357C6',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 2,
    borderRadius: 1,
  },
})(StepConnector);

export const OBRadio = withStyles({
  root: {
    color: '#A357C6',
    '&$checked': {
      color: '#A357C6',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
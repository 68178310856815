import { Radios } from "mui-rff";
import React from "react";
import { ArrowLeft, Camera } from "react-feather";
import { connect } from "react-redux";
import { OnLoadOrder } from "../../../../actions/customerFacingActions";
import OBLoader from "../../../Loader/OBLoader";
import './ProofOfPayment.scss';
import {history} from '../../../../index';
import Axios from "axios";
import { toast } from "react-toastify";
import UploadSuccess from "../../UploadSuccess/UploadSuccess";
import { createGlobalStyle } from 'styled-components';
import { OBRadio } from "../../../../libs/ob_material";
import { Form, Field } from "react-final-form";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useRadioGroup } from '@mui/material/RadioGroup';

class ProofOfPayment extends React.Component {
  photoDiv = React.createRef();
  photoFileselect = React.createRef();
  state = {
    setDisplayHeaderCamera: 'block',
    proofOfPayment: null,
    submitSuccess: false,
    paymentOption: null,
  }

  componentDidMount() {
    this.props.OnLoadOrder(this.props.locations);
  }

  handleImageChange = (e) => {
    if(e.target.files[0]) {
      this.setState({proofOfPayment: e.target.files[0], setDisplayHeaderCamera: 'none'})
      const url = URL.createObjectURL(e.target.files[0]);
      this.photoDiv.current.style.backgroundImage = `url(${url})`;
      this.photoDiv.current.style.backgroundSize = 'contain';
      this.photoDiv.current.style.backgroundRepeat = 'no-repeat';
      this.photoDiv.current.style.backgroundPosition = 'center';
    }
  }


  handleLogoUploadClick = e => {
    this.photoFileselect.current.click();
  };

  onSubmit = () => {

    const formData = new FormData();
    formData.append("order_hash", this.props.locations);
    formData.append("proof_of_payment", this.state.proofOfPayment);
    formData.append("bank", this.state.paymentOption);

    console.log(formData);

    Axios.post(`${process.env.REACT_APP_API_URL}/order/upload_proof_of_payment`, formData).then( x => {
      toast("Proof of Payment Uploaded!", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: "success"});
        this.setState({submitSuccess: true});
    }).catch( e => {
      toast("Cannot upload proof of payment as of ths tme, please try again later", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: "error"});
    })
  }

  validate = () => {
    const errors = {};
    return errors;
  }

  controlProps = (item) => ({
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

  getPaymentOption = (e, hex, value) => {
    return (
      <div><b>{e.mode_of_payment}</b>

        { e.mode_of_payment === value ? 
        <div>
          <div className="option-container">
            <div className="qr-text-container">
              <div>
                <div>Account name:</div>
                <div>Account number:</div>
              </div>
              <div className="option-text">
                <div>{e.account_name}</div>
                <div>{e.account_number}</div>
              </div>
            </div>
          </div>
          {
            e.photo.url ?
            <div className="qr-container">
              <div className="qr-text-container">
                <div>
                  <p>QR Code:</p>
                  <a href={`${e.photo.url}`} download target="_blank">Download</a>
                </div>
              </div>
              <div className="qr-img">
                <img src={`${e.photo.url}`}></img>
              </div>
            </div> : <div></div>
          }
          <div className="upload">
            <p className="upload-text">Upload Proof of Payment:</p>
            <div className="fileselect" id="fileselect"  ref={this.photoDiv} onClick={this.handleLogoUploadClick}>
              <Camera color={hex} size={48} style={{display: this.state.setDisplayHeaderCamera, width: "100%"}}></Camera>
              <input ref={this.photoFileselect} type="file" name="product_image" accept="image/*" onChange={this.handleImageChange}></input>
          </div>
        </div> 
        </div>: <div></div>
        }
      
      </div>
    )
  }

  render() {
    if(!this.props.order || !this.props.merchant_store) {
      return <OBLoader/>;
    }

    let hex = this.props.merchant_store.hex_code || '#A357C6';
    const MerchantStyle =  createGlobalStyle`
      .cf-proof-of-payment {
        --merchant-color: ${hex};
      }
    `;

    if(this.state.submitSuccess) {
      console.log(this.props);
      window.location.reload();
    }
    
    return (<div className="cf-proof-of-payment post-order-order-status">
      <MerchantStyle/>
      <div className="section-label">Online Payment Options</div>
      <p>View payment details and upload your proof of payment here.</p>
      <div className='cf-proof-of-payment-body'>
      <FormControl component="fieldset" className="payment-option-options">
        <RadioGroup value={this.state.paymentOption} onChange={(e) => {
              this.setState({paymentOption: e.target.value});
            }}>
          {
            this.props.merchant_store.payment_options.map(option => {
              return (
              <div
                className="payment-option-options">
              <FormControlLabel
                label={this.getPaymentOption(option, hex, this.state.paymentOption)}
                control={<Radio/>}
                value={option.mode_of_payment}
              /></div>);
            })
          }
        </RadioGroup>
      </FormControl>
      <div className="proof-button-upload-container">
        <button className="confirm-btn" disabled={!this.state.proofOfPayment} onClick={this.onSubmit}>Submit Proof of Payment</button> 
      </div>
      </div>
    </div>);
  }

}

const mapStateToProps = (state) => {
  return {
    order: state.customer.order,
    merchant_store: state.customer.merchant_store
  }
}

const mapDispatchToProps = {
  OnLoadOrder
}
export default connect(mapStateToProps,mapDispatchToProps)(ProofOfPayment); 
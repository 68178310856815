import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Link } from "react-router-dom";
import { history } from "../../..";

import { onSelectSectionId } from "../../../actions/shopActions";
import { wizardGoToStep } from "../../../actions/wizardActions";

import { OnLoadStore, OnSearchStore } from '../../../actions/customerFacingActions';
import OBLoader from "../../Loader/OBLoader";
import './Shop.scss';
// import { ValidationTextField } from "../../../libs/ob_material";
import { Search } from "react-feather";
import { IconButton, TextField, InputAdornment, useMediaQuery } from "@material-ui/core";

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Tabs from 'react-responsive-tabs';
import './ShopTab.scss';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import Grid from '@material-ui/core/Grid';

import * as qs from 'query-string';

const DEFAULT_PRODUCT_IMAGE = 'https://orderbasket-staging.s3-ap-southeast-1.amazonaws.com/default-product.png';

// const styledBy = (property, mapping) => (props) => mapping[props[property]];

const useStyles = makeStyles((theme) => ({
  pagination: {
    '& > *': {
      marginTop: theme.spacing(2),
      color: 'red',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  moreIcon: {
    marginBottom: -2,
    marginLeft: 5,
    width: 17,
    height: 'auto'
  },
  noResult: {
    width: '100%',
    textAlign: 'center',
    padding: 50
  },
  productImage: {
    width: '100%',
    height: window.innerHeight / 3.5,
    objectFit: 'cover',
    borderRadius: 5
  },
  productImageMobile: {
    width: '100%',
    height: window.innerHeight / 4,
    objectFit: 'cover',
    borderRadius: 5
  },
  productTitle: {
    lineHeight: '1.5em',
    maxHeight: '3em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
    marginTop: 10
  },
  horizontalBorder: {
    borderBottomColor: '#e5e8ee',
    width: '100%',
    height: 1.2,
    backgroundColor: '#e5e8ee'
  }
}));

function Shop(props){

  const classes = useStyles();

  const isMobileView = useMediaQuery('(max-width:600px)');

  const {
    location,
    merchant_store,
    selected_product_section,
    shown_product_sections,
    OnSearchStore,
    OnLoadStore,
  } = props;

  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentProducts, setCurrentProducts] = useState({products: []});

  useEffect(() => {
    /** RECONFIG URL PAGES */
    let urlConfig = JSON.parse(localStorage.getItem('last-merchant-id'));
    if(urlConfig && urlConfig.currentLoadedMerchant === merchant_store.shop_url){
      routeChange(urlConfig.url);
      setSelectedTab(urlConfig.selectedTab);
      if(urlConfig.currentPage){
        setCurrentPage(urlConfig.currentPage);
      }
    }
  },[])

  useEffect(() => {
    if(merchant_store){
      setCurrentProducts(merchant_store)
    }
  },[merchant_store])

  useEffect(() => {
    if(location){
      if(location.search === "?page=1"){
        setSelectedTab(0);
        setCurrentPage(1);
        let currentURL = `/shop/${merchant_store.shop_url}?page=1`;
        localStorage.setItem('last-merchant-id', JSON.stringify({ selectedTab: 0, url:  currentURL, currentPage: 1, currentLoadedMerchant: merchant_store.shop_url }));
      }
    }
  },[location])

  function routeChange(route, data){
    history.push(route, data);
  }

  const getProductImage = (p) => {
    let productImageUrl = DEFAULT_PRODUCT_IMAGE;

    // since main product has no image, get main image from the first variant that has an image...
    if(p.variants) {
      for(let e of p.variants) {
          if(e.photo.url) {
            return e.photo.url;
          }
        }
    }

    return productImageUrl;
  }

  function setQuery(){
    let params = {
      url: extractStoreName(),
      query: searchText
    }
    OnSearchStore(params);
  }

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    setCurrentProducts({products: []});

    const searchParams = {
      page: newPage
    };
    if(selected_product_section) {
      searchParams["category"] = selected_product_section;
    }

    let currentURL = `/shop/${merchant_store.shop_url}?${qs.stringify(searchParams)}#shop`;
    localStorage.setItem('last-merchant-id', JSON.stringify({ selectedTab: selectedTab, url:  currentURL, currentPage: newPage, currentLoadedMerchant: merchant_store.shop_url }));
    routeChange(currentURL)
  };

  const handleCategoryChange = (index) => {
    let currentURL = '';

    setSelectedTab(index);
    setCurrentPage(1);
    setCurrentProducts({products: []});
    if(index === 0){
      currentURL = `/shop/${merchant_store.shop_url}?page=1`;
      routeChange(currentURL)
      localStorage.setItem('last-merchant-id', JSON.stringify({ selectedTab: index, url:  currentURL, currentPage: 1, currentLoadedMerchant: merchant_store.shop_url }));
    } else {
      currentURL = `/shop/${merchant_store.shop_url}?page=1&category=${shown_product_sections[index].product_section_id}#shop`;
      localStorage.setItem('last-merchant-id', JSON.stringify({ selectedTab: index, url:  currentURL, currentPage: 1, currentLoadedMerchant: merchant_store.shop_url }));
      routeChange(currentURL)
    }
  }

  const extractStoreName = () => {
    return location.pathname.split("/shop/")[1].split("/")[0];
  }

  const allVariantsOutOfStock = (variants) => {
    let truth = false;
    if(merchant_store.display_sold_out_as_sold_out && !merchant_store.allow_sold_out) {
      for(let v of variants) {
        if(v.quantity > 0 ) {
          truth = false;
          break;
        }
        truth =  true;
      }
    }

    return truth;
  }

  const getVariantPriceRange = (product) => {

    if(!merchant_store.allow_sold_out && !product.variations_enabled && product.track_stock && merchant_store.display_sold_out_as_sold_out && product.quantity <= 0) {
      return 'Out of Stock';
    }

    if(product.variations_enabled && product.variants.length) {

      if(allVariantsOutOfStock(product.variants) && product.track_stock) {
        return 'Out of Stock';
      }

      let allPrices = product.variants.map(e => parseFloat( e.price));
      allPrices = [...new Set(allPrices)].sort(function(a,b) { return a - b;});

      if(allPrices.length === 1) {
        return `₱ ${new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
       }).format(allPrices[0])}`;
      } else {
        return `₱ ${new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          }).format(allPrices[0])} - ₱ ${new Intl.NumberFormat('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
          }).format(allPrices[allPrices.length -1])}`;
      }
    } else {
      return `₱ ${new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
     }).format(product.price)}`;
    }
  }

  function renderCard(product){
    return(
      <Link className="product-item" to={`/shop/${merchant_store.shop_url}/product/${product.id}`} key={product.id}>
          <div className="product-img-container">
          {product.photo && product.photo.url && <img src={product.photo.url} className={isMobileView ? classes.productImageMobile : classes.productImage} alt={product.product_name}></img>}
          {product.photo && !product.photo.url && <img src={getProductImage(product)} className={isMobileView ? classes.productImageMobile : classes.productImage} alt={product.product_name}></img>}
        </div>
        <div>
          <div className={classes.productTitle}>{product.product_name}</div>
          <div className='merchant-color' style={{fontWeight: '600', fontSize: 16.5}}>{`${getVariantPriceRange(product)}`}</div>
        </div>
      </Link>
    )
  }

  const getCFProductsContainer = (MERCHANT_PRODUCTS) => {
    const PRODUCTS = MERCHANT_PRODUCTS.page_meta ? MERCHANT_PRODUCTS.products : MERCHANT_PRODUCTS;

    return(
      <div className="cf-shop-products-container">
        <Grid
          style={{paddingTop: 40, paddingBottom: 20}}
          spacing={isMobileView ? 3 : 10}
          direction={isMobileView ? 'row' : 'row'}
          justify="flex-start"
          alignItems="flex-start"
          container>
          {
              PRODUCTS[0].product_section_id !== -1 ?
                PRODUCTS && PRODUCTS.map(product => {
                return (
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                    {renderCard(product)}
                  </Grid>
                )
              })
              :
              PRODUCTS && PRODUCTS.length > 0 && PRODUCTS[0].products && PRODUCTS[0].products.map(product => {
                return (
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                    {renderCard(product)}
                  </Grid>
                )
              })
          }
          {
            PRODUCTS && PRODUCTS.length > 0 && PRODUCTS[0].product_section_id === -1 && PRODUCTS[0].products.length === 0  &&
            <div className={classes.noResult}>No results</div>
          }
        </Grid>
      </div>
    )
  }

  function renderSearchHeader(){
    return(
      <div className="product-header-row">
        <span className="section-label">Shop</span>
        {
          showSearch &&
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <InputAdornment position="start">
              {/* <SearchIcon style={{width: 30, height: 30}} /> */}
              <Search />

            </InputAdornment>
            <TextField
              value={searchText}
              placeholder={'Search'}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={(e)=> {
                if(e.key === 'Enter') {
                  setQuery();
                }
              }}
              onBlur={()=> {
                if(searchText.length === 0){
                  setShowSearch(false);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start" style={{cursor: 'pointer' }} onClick={()=> {
                    setSearchText('');
                    if(searchText.length === 0){
                      setShowSearch(false);
                    }
                    clearTheSearch();
                  }}>
                    <CloseIcon />
                  </InputAdornment>
                ),
              }} />
          </div>
        }
        {
          !showSearch &&
            <span>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={() => {
                  // routeChange(`/shop/${merchant_store.shop_url}#shop`);
                  setShowSearch(!showSearch);
                }}
                className="menu-btn">
                <Search />
              </IconButton>
            </span>
        }
      </div>
    )
  }

  function renderHeaderTabs(){
    return(
      <div>
        <Tabs
          showInkBar={true}
          showMore={true}
          showMoreLabel={
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <div>More</div>
              <KeyboardArrowDownIcon className={classes.moreIcon} />
            </div>
          }
          selectedTabKey={selectedTab}
          transform={false}
          items={renderCategoryTabs()}
          onChange={handleCategoryChange}/>
          <div className="cf-shop-category-navigation">
            {/* {
              selected_product_section === -1 &&
              <div
                className="clear-search"
                onClick={() => {
                  setSearchText('');
                  setShowSearch(false);

                  let currentURL = `/shop/${merchant_store.shop_url}?page=1`;
                  routeChange(currentURL);
                  setSelectedTab(0);
                  setCurrentPage(1);
                  localStorage.setItem('last-merchant-id', JSON.stringify({ selectedTab: 1, url:  currentURL, currentPage: 1, currentLoadedMerchant: merchant_store.shop_url }));

                }}>Clear search</div>
            } */}
        </div>
        <div className={classes.horizontalBorder} />
      </div>
    )
  }

  function clearTheSearch() {
    setSearchText('');
    setShowSearch(false);

    let currentURL = `/shop/${merchant_store.shop_url}?page=1`;
    routeChange(currentURL);
    setSelectedTab(0);
    setCurrentPage(1);
    localStorage.setItem('last-merchant-id', JSON.stringify({ selectedTab: 1, url:  currentURL, currentPage: 1, currentLoadedMerchant: merchant_store.shop_url }));
  }

  function renderCategoryTabs() {
    return shown_product_sections && shown_product_sections.map((value, index) => ({
      title: value.product_section,
      key: index,
    }));
  }

  function renderPagination(MERCHANT_PRODUCTS){
    const PAGE_META = MERCHANT_PRODUCTS ? MERCHANT_PRODUCTS.page_meta : 0;

    return(
      <div className={classes.pagination}>
        {
          PAGE_META && PAGE_META.pages && PAGE_META.pages > 1 &&
          <Pagination
            classes={{
              root: 'paginate',
              ul: 'ul'
            }}
            count={PAGE_META && PAGE_META.pages}
            page={currentPage}
            siblingCount={0}
            onChange={handleChangePage}
            shape="rounded"
            size="large" />
        }
      </div>
    );
  }

  function renderProductLoader(){
    return(
      <>
        {
          currentProducts && currentProducts.products && currentProducts.products.length === 0 &&
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20vh'}}>
            <OBLoader/>
          </div>
        }
      </>
    )
  }

  function renderProducts(){
    return(
      <div className="inner-content cf-shop">
        <div className="inner-content-container" style={{width: '100%'}}>
          {renderSearchHeader()}
          {renderHeaderTabs()}
          {
            currentProducts && currentProducts.products && currentProducts.products.products && currentProducts.products.products.length > 0 &&
            getCFProductsContainer(currentProducts.products)
          }
          {
            currentProducts && currentProducts.products && currentProducts.products[0] && currentProducts.products[0].product_section === "Search Results" &&
            getCFProductsContainer(currentProducts.products)
          }
          {
            currentProducts && currentProducts.products && currentProducts.products.length === 0 &&
            renderProductLoader()
          }
          {renderPagination(merchant_store.products)}
        </div>
      </div>
    )
  }

  return merchant_store ? renderProducts() : <OBLoader/>;
}

const mapStateToProps = state => {
  return {
    merchant_store: state.customer.merchant_store,
    failed: state.customer.load_failed,
    selected_product_section: state.shop.selected_product_section,
    shown_product_sections: state.shop.shown_product_sections,
    merchantCarts: state.cart.merchantCarts,
    pageMetadata: state.customer.merchant_store.products.page_meta
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      onSelectSectionId: params => onSelectSectionId(params),
      OnSearchStore: params => OnSearchStore(params),
      wizardGoToStep: params => wizardGoToStep(params),
      OnLoadStore: params => OnLoadStore(params),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Shop);

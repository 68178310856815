
const initialState = {
  merchantStore: {},
  loading: false, rejected: false,
};

export default function merchantStoreReducer(state = initialState, action) {
  switch(action.type) {
    case 'MERCHANT_LOAD_STARTED':
      return {...state, loading: true};
    case 'MERCHANT_LOAD_COMPLETED': 
      return {...state, loading: false, merchantStore: action.payload};
    case 'MERCHANT_UPDATE_STARTED':
      return {...state, loading: true};
    case 'MERCHANT_UPDATE_FAILED':
      return {...state, loading: false, rejected: true};
    case 'MERCHANT_UPDATE_COMPLETED': 
      return {...state, loading: false, merchantStore: action.payload, rejected: false};
    default: 
      return state;
  }
}
const initialState = {loading: false, signup_success: false, error: null};

export default function signupReducer(state = initialState, action) {
  switch(action.type) {
    case 'SIGNUP_STARTED':
      return {...state, loading: true};
    case 'SIGNUP_SUCCESS':
      return {...state, loading: false, signup_success: !!action.payload.id};
    case 'SIGNUP_FAILED':
      return {...state, loading: false, signup_success: false, error: action.payload.error};
    default:
      return state;
  }
}
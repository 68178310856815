const initialState = {
   mode: 'mobile_closed',
  //  showTutorial: false
};


export default function session(state = initialState, action) {
  switch(action.type) {
    case 'TOGGLE_SIDENAV_MODE':
      if (state.mode === 'mobile_closed') 
         return { mode: 'mobile_open' }
      else if (state.mode === 'mobile_open')
         return { mode: 'mobile_closed' };
      return state;
    // case 'TOGGLE_SIDENAV_SHOW_TUTORIAL':
    //   return {showTutorial: !state.showTutorial};
    default:
      return state;
  }
}
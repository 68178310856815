import { readObject, saveObject } from "../libs/localstorage";

const initialState = {
  form: {
    // special_instruction: 'Paki iwan kay Kuya Kim',
    // order_first_name: 'Karina',
    // order_last_name: 'See',
    // cellphone_number: '12345679',
    // email: 'porkycorn@gmail.com',
    // house_number: '70-1',
    // street: 'Lagmay St',
    // building: 'OrderBasket Headquarters building',
    // barangay: 'Bato Bato Sa Langit',
    // city: 'St John',
    // province: 'Metro Manila',
    // zip: '12345'
  },
  currentStep: 0,
  orderPlaceError: null,
  cartValidationError: null,
  submitting: false,
  showErrorDialog: false,
  showValidateCartError: false,
  validProducts: null
}

const getCurrentMerchantCart = (merchant_store_id) => {
  const cart = readObject('cart');

  return cart.merchantCarts.filter(m => m.id === merchant_store_id)[0]
}

const updateMerchantCart = (merchant_store_id, data) => {
  const cart = readObject('cart');

  const merchantCart = getCurrentMerchantCart(merchant_store_id);

  if(merchantCart) {
    const newMerchantCart = {...merchantCart, ...data};
    cart.merchantCarts = cart.merchantCarts.filter( c => c.id !==  merchant_store_id).concat(newMerchantCart);
    saveObject(cart, 'cart');
  }
}

const getFormState = (merchant_store_id ) => {
  const cart = readObject('cart');
  // const prefil = readObject('ob_prefill_form');

  if(!cart) {
    const cart = {merchantCarts: [], createdAt: new Date()};
    saveObject( cart ,"cart");

    return initialState;
  }

  const merchantCart = getCurrentMerchantCart(merchant_store_id);
  if(merchantCart){

    let theForm = merchantCart.form;
    // let theForm = initialState.form;

    // if(prefil && !Object.keys(merchantCart.form).length && Object.keys(prefil).length) {
    //   theForm = prefil;
    // }

    return {
      form: theForm,
      currentStep: merchantCart.currentStep
    };
  }

  return initialState;
}

export default function wizardReducer(state = initialState, action) {
  switch(action.type) {
    case 'GET_CURRENT_WIZARD_FORM_STATE':
      return getFormState(action.payload.merchant_store_id);
    case 'ORDER_CREATE_COMPLETED':
      return {...state, submitting: false}
    case 'CF_CLEAR_CART_AND_FORM':
      const myCart = readObject('cart');
      myCart.merchantCarts = myCart.merchantCarts.filter( c => c.id !==  action.payload);
      localStorage.removeItem('promo-code');
      localStorage.removeItem('promo-details');
      saveObject(state.form, 'ob_prefill_form');
      saveObject(myCart, 'cart');
      return initialState;
    case 'PROCEED_WIZARD':
      const newWizard = {...state, form: {...action.payload.form}, currentStep: action.payload.step};
      updateMerchantCart(action.payload.merchant_store_id, newWizard);
      return newWizard;
    case 'BACK_WIZARD':
      let newStep = state.currentStep - 1;
      if(newStep < 0) {
        newStep = 0;
      }
      const newWizard2 = {...state , currentStep: newStep};
      updateMerchantCart(action.payload.merchant_store_id, newWizard2);
      return newWizard2;
    case 'GO_TO_STEP_WIZARD':
      updateMerchantCart(action.id, {...state, currentStep: action.payload})
      return {...state, currentStep: action.payload}
    case 'ORDER_CREATE_FAILED':
      return {...state, submitting: false}
    case 'DISMISS_OUT_OF_STOCK_ERROR':
      return {...state, showErrorDialog: false, currentStep: 0}
      case 'DISMISS_VALIDATE_CART_ERROR':
      return {...state, showValidateCartError: false, currentStep: 0}
    case 'ORDER_CREATE_DUE_TO_QUANTITY_FAILED':
      return {...state, orderPlaceError: action.payload, showErrorDialog: true, submitting: false, showValidateCartError: false}
    case 'ORDER_VALIDATE_CART_FAILED':
      return {...state, cartValidationError: action.payload, validProducts: action.valid_products,  showErrorDialog: false, showValidateCartError: true, submitting: false}
    case 'ORDER_CREATE_STARTED':
      return {...state, orderPlaceError: null, cartValidationError:null,  submitting: true}
    case 'ORDER_VALIDATE_CART_SUCCESS':
      return {...state, orderPlaceError: null, cartValidationError:null, validProducts: null, showValidateCartError: false}
    default:
      return state;

  }

}

const initialState = {notifications: []};

export default function notificationReducer(state = initialState, action) {
  switch(action.type) {
    case 'NOTIFY_NOTIFICATIONS':
      return {...state, notifications: action.payload};
      case 'CLEAR_NOTIFICATIONS':
        return {...state, notifications: []};
    default:
      return state;
  }
}
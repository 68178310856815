
import axios from 'axios';
import { history } from '..';


export const onLandingFormSubmit = (formData) => {
  return dispatch => {
    dispatch(landingFormStarted());

    axios.post(`${process.env.REACT_APP_API_URL}/email_lists`, formData).then( res => {
      dispatch(landingFormCompleted());
    }).catch( e => {
      // Donuting!
    });
  };
}

export const onLandingFreeDemoSubmit = (formData) => {
  return dispatch => {
    dispatch(landingFreeDemoFormStarted());

    axios.post(`${process.env.REACT_APP_API_URL}/email_lists`, formData).then( res => {
      dispatch(landingFreeDemoFormCompleted());
    }).catch( e => {
      // Donuting!
    });
  };
}

export const onLandingGetInTouchSubmit = (formData) => {
  return dispatch => {
    dispatch(landingGetInTouchFormStarted());

    axios.post(`${process.env.REACT_APP_API_URL}/email_lists`, formData).then( res => {
      dispatch(landingGetInTouchFormCompleted());
    }).catch( e => {
      // Donuting!
    });
  };
}

export const onLandingSearchOrder = (formData) => {
  return dispatch => {
    dispatch(landingFormOrderSearchStarted());
    axios.post(`${process.env.REACT_APP_API_URL}/order/search_order`, formData).then( res => {
      // dispatch(landingFormOrderSearchCompleted(res.data));
      history.push(`/order-status?h=${res.data.hash}`);
    }).catch( e => {
      dispatch(landingFormOrderSearchFailed());
    });
  }
}

const landingFormOrderSearchStarted = () => ({
  type: 'LANDING_SEARCH_STARTED',
});

const landingFormOrderSearchFailed = () => ({
  type: 'LANDING_SEARCH_FAILED',
});

const landingFormOrderSearchCompleted = (payload) => ({
  type: 'LANDING_SEARCH_COMPLETED',
  payload: payload
});




const landingFormStarted = () => ({
  type: 'LANDING_STARTED',
});

const landingFormCompleted = () => ({
  type: 'LANDING_COMPLETED'
});

const landingFreeDemoFormStarted = () => ({
  type: 'LANDING_SCHEDULE_STARTED',
});

const landingFreeDemoFormCompleted = () => ({
  type: 'LANDING_SCHEDULE_COMPLETED'
});

const landingGetInTouchFormStarted = () => ({
  type: 'LANDING_GET_IN_TOUCH_STARTED',
});

const landingGetInTouchFormCompleted = () => ({
  type: 'LANDING_GET_IN_TOUCH_COMPLETED'
});

import axios from 'axios';
import {history} from '../index';
import { saveObject } from '../libs/localstorage';

export const onSignup = ({email, first_name, last_name, password, password_confirmation }, showErrorToast) => {
  return dispatch => {
    dispatch(signupStarted());
    axios.post(`${process.env.REACT_APP_API_URL}/users`, { user: {
      email, first_name, last_name, password, password_confirmation }
    })
    .then(res => {
      dispatch(signupSuccess(res.data));
      history.push('/signup/pin');
      saveObject(res.data, 'userObject');
      saveObject({page: '/signup/pin'}, "signupState");
     })
    .catch(error => {
      if(error.response.data.errors.email) {
        showErrorToast("Email already taken");
      } else {
        showErrorToast('Something went wrong. Try again.');
      }
      dispatch(signupFailed(error));
    });
  }
};

const signupSuccess = data => ({
  type: 'SIGNUP_SUCCESS',
  payload: {
    ...data
  }
});

const signupStarted = () => ({
  type: 'SIGNUP_STARTED'
});

const signupFailed = error => ({
  type: 'SIGNUP_FAILED',
  payload: {
    error
  }
});
import { combineReducers } from 'redux';
import signupReducer from './signupReducer';
import faqReducer from './faqReducer';
import paymentReducer from './paymentReducer';
import sessionReducer from './sessionReducer';
import productReducer from './productReducer';
import perProductReducer from './perProductReducer';
import productSectionsReducer from './productSectionReducer';
import landingReducer from './landingReducer';
import orderReducer from './orderReducer';
import notificationReducer from './notificationReducer';
import merchantStoreReducer from './merchantStoreReducer';
import homeReducer from './homeReducer';
import sidenavReducer from './sidenavReducer';
import customerFacingReducer from './customerFacingReducer';
import shopReducer from './shopReducer';
import cartReducer from './cartReducer';
import wizardReducer from './wizardReducer';
import orderv2Reducer from './orderv2Reducer';

const rootReducer = combineReducers({
  signup: signupReducer,
  faq: faqReducer,
  payment: paymentReducer,
  session: sessionReducer,
  products: productReducer,
  perProduct: perProductReducer,
  product_sections: productSectionsReducer,
  landing: landingReducer,
  orders: orderReducer,
  notifications: notificationReducer,
  merchantStore: merchantStoreReducer,
  home: homeReducer,
  sidenav: sidenavReducer,
  customer: customerFacingReducer,
  shop: shopReducer,
  cart: cartReducer,
  wizard: wizardReducer,
  // ordersv2: orderv2Reducer,
  });

export default rootReducer;
import React, { Suspense } from 'react';
import './App.scss';
import Landing from './Landingv2/Landing';
import Login from './Login/Login';
import { Route } from "react-router-dom";
import Onboarding from './Onboarding/Onboarding';
// import MerchantDashboard from './MerchantDashboard/MerchantDashboard';
import { connect } from 'react-redux';
import { onSessionCheck } from '../actions/sessionActions';
import { toast, ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga';
import {history} from '../index';
import PasswordReset from './PasswordReset/PasswordReset';
import OBLoader from './Loader/OBLoader';
import CustomerFacing from './CustomerFacing/CustomerFacing';
import PostOrder from './CustomerFacing/PostOrder/PostOrder';
import ProofOfPayment from './CustomerFacing/PostOrder/ProofOfPayment/ProofOfPayment';
import RequestPasswordReset from './RequestPasswordReset/RequestPasswordReset';
import Plans from './Onboarding/Plans/Plans';

import CacheBuster from './CacheBuster';

import { MuiThemeProvider } from "@material-ui/core/styles";
import DEFAULT_THEME from "../theme/default";

class App extends React.Component {
  componentDidMount() {
    ReactGA.initialize('UA-177915557-1');

    history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });

    const currentLocation = window.location.pathname;
    ReactGA.set({ page: currentLocation });
    ReactGA.pageview(currentLocation);
    this.props.onSessionCheck();
  }

  toastCenter() {
    const width = window.innerWidth;
    const leftOffset = (width - 320)/2;
    if(width < 500) {
      return (<ToastContainer style={{bottom: "5%", left: `${leftOffset}px`}}></ToastContainer>);
    } else
      return (<ToastContainer></ToastContainer>);
  }

  render = () => {
    const MerchantDashboard = React.lazy(() => import('./MerchantDashboard/MerchantDashboard'));

    return (
      <MuiThemeProvider theme={DEFAULT_THEME}>
        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              // You can decide how and when you want to force reload
              toast("A new version of the app is available! Re-loading your experience now", {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose:3000,
                closeOnClick: true,
                pauseOnHover: true,
                type: "success",
                draggable: true});
              setTimeout(() => {
                refreshCacheAndReload();
              }, 3000);
            }

            return (<div className="customer-toast">
              {this.toastCenter()}
              <Route exact={true} path="/" component={Landing}></Route>
              <Route path="/shop" component={CustomerFacing}></Route>
              <Route exact={true} path="/order-status" component={PostOrder}></Route>
              <Route exact={true} path="/proof-of-payment" component={ProofOfPayment}></Route>
              <Route exact={true} path="/forgot-password" component={RequestPasswordReset}></Route>
              {/* <Route path="/product" component={ProductView}></Route> */}
              <Route path="/login" component={Login}></Route>
              <Route path="/signup" component={Onboarding}></Route>
              <Route path="/plan/upgrade" component={Plans}></Route>
              <Suspense fallback={OBLoader}>
                <Route path="/dashboard" component={MerchantDashboard}></Route>
              </Suspense>
              <Route path="/reset-password" component={PasswordReset}></Route>
              {/* <Route component={FourOFour} /> */}
            </div>
          );
          }}
        </CacheBuster>
      </MuiThemeProvider>
    );

  }

}

const mapDispatchToProps = {
  onSessionCheck
};
export default connect(null, mapDispatchToProps)(App);

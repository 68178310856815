import React from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import Logo from "../Logo/Logo";
import './RequestPasswordReset.scss';
import Axios from "axios";
import { toast } from "react-toastify";
import { onLogoutAct } from "../../actions/sessionActions";
import { history } from "../..";
import { ValidationTextFieldRff } from "../../libs/ob_material";


 class RequestPasswordReset extends React.Component { 
   state = {
     showPassword: false,
     showConfirmPassword: false,
   }

  submit = (v) => {
    Axios.get(`${process.env.REACT_APP_API_URL}/password_reset`, {params: {email: v.email}}).then(res => {
      this.props.onLogoutAct(false);
      toast("We have sent the password reset instructions to your email", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose:5000,
        closeOnClick: true,
        pauseOnHover: true,
        type: "success",
        draggable: true});
      history.push('/login');
    }).catch(err => {
      toast("We cannot reset your password at this time, please try again later.", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose:3000,
        closeOnClick: true,
        pauseOnHover: true,
        type: "error",
        draggable: true});
    });
  }
  validate = (values) => {
    const errors = {};

    if(!values.email) {
      errors.email = 'Required';
    }
    if(values.email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!values.email.match(re)) {
        errors.email = 'Please put a valid email';
      } 
    } 
    return errors;
  }
   
  render() {
    return (<div className="password-reset">
      <div className="password-reset-card">
        <Logo></Logo>
        <Form
          onSubmit={this.submit}
          validate={this.validate} 
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <div style={{marginBottom: 25,marginTop: 25, fontSize: 20}}><b>Reset your password</b></div>
              <p>To reset your password, input your email registered with OrderBasket</p>
              <ValidationTextFieldRff variant="outlined" required={true} label="Email address" name={"email"}></ValidationTextFieldRff>
              <input type="submit" style={{marginTop: 30}} className="confirm-btn" value="Submit"></input>
            </form>)}/>
      </div>

    </div>);
  }
}

const mapDispatchToProps = {
  onLogoutAct
}



export default connect(null, mapDispatchToProps)(RequestPasswordReset);
import React from 'react';
import './OBLoader.scss';
import { Loader } from 'react-feather';

function OBLoader(){
  return (

    <Loader className="rotating"></Loader>

  );
}


export default OBLoader;

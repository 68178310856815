const initialState = {loading: false, payments: []};

export default function paymentReducer(state = initialState, action) {
  switch(action.type) {
    case 'PAYMENT_ADD_STARTED':
      return {...state, loading: true};
    case 'PAYMENT_ADD_SUCCESS':
      return {...state, loading: false, ...action.payload};
    case 'PAYMENT_DELETE_SUCCESS':
      return {...state, loading: false, ...action.payload};
    case 'PAYMENT_DELETE_STARTED':
      return {...state, loading: true};

    default:
      return state;
  }
}
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import * as serviceWorker from './serviceWorker';
import { Router } from "react-router-dom";
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';

import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();
// ReactGA.initialize('UA-177915557-1');

// history.listen(location => {
//   ReactGA.set({ page: location.pathname }); // Update the user's current page
//   ReactGA.pageview(location.pathname); // Record a pageview for the given page
// });

const composeEnhancers = composeWithDevTools({ 
  actionCreators: null, 
  trace: true, 
  traceLimit: 25 
}); 
const store = createStore(rootReducer,{}, composeEnhancers(
  applyMiddleware(thunk) 
));
// const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
ReactDOM.render(
  <Router history={history}>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
// import { ReactComponent as SparkleSmall } from "../../../assets/Icons/sparkle-sm.svg";
// import { ReactComponent as Sparkle } from "../../../assets/Icons/sparkle.svg";
import { ReactComponent as Check } from "../../../assets/Icons/check.svg";
import { ReactComponent as Dash } from "../../../assets/Icons/dash.svg";
import './SelectPlan.scss';
import { readObject } from '../../../libs/localstorage';
import { toast } from 'react-toastify';
import {history} from '../../../index';

import Axios from 'axios';
import { connect } from 'react-redux';
import { onBuilding } from '../../../actions/buildingActions';

class SelectPlan extends React.Component {

  state = {
    selectedPlan: null
  }

  requestPlanUpgrade = () => {
    this.props.onBuilding();

    if(this.state.selectedPlan === 'free') {
      history.push('/signup/account_created');
      localStorage.removeItem('signupState');
      return;
    }

    const access_token = localStorage.getItem('access_token');
    const merchant_store_id = readObject("merchant")["id"];
    const config = { headers: {Authorization: access_token }, params: {merchant_store_id} };
    Axios.get(`${process.env.REACT_APP_API_URL}/contact_admin/upgrade_plan`, config).then(res => {
      toast("You have selected the Standard Plan. Our team will contact you soon to discuss your account, for now explore the app on the Free Tier!", {
        position: toast.POSITION.BOTTOM_CENTER,
        closeOnClick: true,
        autoClose: false,
        pauseOnHover: true,
        type: "success",
        draggable: true});
        localStorage.removeItem('signupState');
      history.push('/signup/account_created');
    }).catch( e => {
      toast("Cannot request the plan upgrade as of this time, please try again later", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: "error"});
      history.push('/signup/account_created');
      localStorage.removeItem('signupState');

    });
  }

  render() {
    return (
    <div className="select-plan-container">
      <div className="headerText">Select Your Plan</div>
      <br></br>
      <div className="subHeaderText">If you would like a custom plan, please contact us at <a href="mailto:orderbasketph@gmail.com">orderbasketph@gmail.com</a>! :)</div>
      <div className="pricing-plan-container">
        <div className={`pricing-section selectable${this.state.selectedPlan === 'free' ? ' selected' : ''}`} onClick={() => { this.setState({selectedPlan: 'free'})}}>
          <div className="pricing-plan-title">Free Forever</div>
          <div className="pricing-plan-entry">
            <Check></Check>1 Enterprise account
          </div>
          <div className="pricing-plan-entry">
            <Dash></Dash>1 Team member
          </div>
          <div className="pricing-plan-entry">
            <Check></Check>Basic Online e-shop
          </div>
          <div className="pricing-plan-entry">
            <Check></Check>Order management
          </div>
          <div className="pricing-plan-entry">
            <Check></Check>Inventory management
          </div>
          <div className="pricing-plan-entry">
            <Check></Check>Automated notifications
          </div>
          <div className="pricing-plan-entry">
            <Dash></Dash>Up to 8 product listings
          </div>
          <div className="pricing-plan-entry">
            <Dash></Dash>Up to 3 variations per product
          </div>
          <div className="pricing-plan-entry">
            <Dash></Dash>Up to 50 orders a month
          </div>
          <div className="pricing-plan-entry">
            <Dash></Dash>Lite marketing support
          </div>
          <div className="footer">
            Perfect for&nbsp;<b>small businesses and <br/>
            social sellers</b> who are just starting
            out
          </div>
        </div>
      <div className={`pricing-section last selectable${this.state.selectedPlan === 'standard' ? ' selected' : ''}`} onClick={() => { this.setState({selectedPlan: 'standard'})}}>
        <div className="pricing-plan-title">
          Standard
          <div style={{ marginLeft: "auto", fontSize: 16 }}>
            499 PHP
            <br />
            <div style={{ fontSize: 11, textAlign: "center" }}>
              /month
            </div>
          </div>
        </div>
        <div className="pricing-plan-entry">
          <Check></Check>1 Enterprise account
        </div>
        <div className="pricing-plan-entry">
          <Check></Check>3 Team members
        </div>
        <div className="pricing-plan-entry">
          <Check></Check>Customizable Online e-shop
        </div>
        <div className="pricing-plan-entry">
          <Check></Check>Order management
        </div>
        <div className="pricing-plan-entry">
          <Check></Check>Inventory management
        </div>
        <div className="pricing-plan-entry">
          <Check></Check>Automated notifications
        </div>
        <div className="pricing-plan-entry">
          <Check></Check>Up to 30 product listings
        </div>
        <div className="pricing-plan-entry">
          <Check></Check>Up to 20 variations per product
        </div>
        <div className="pricing-plan-entry">
          <Check></Check>Up to 350 orders a month
        </div>
        <div className="pricing-plan-entry">
          <Check></Check>Standard marketing support
        </div>
        <div className="footer">
          Perfect for&nbsp;
          <b>more established <br/> businesses</b> with regular customers
        </div>
      </div>
      {/* <div className="pricing-section">
        <div className="pricing-plan-title">
          <Sparkle style={{ marginRight: 12 }}></Sparkle>Pro
          <div style={{ marginLeft: "auto", fontSize: 14 }}>
            Coming Soon!
          </div>
        </div>
        <div className="pricing-plan-entry">
          <Check></Check>1 Enterprise account
        </div>
        <div className="pricing-plan-entry">
          <Check></Check>Unlimited team members
        </div>
        <div className="pricing-plan-entry">
          <Check></Check>Online e-shop
        </div>
        <div className="pricing-plan-entry">
          <Check></Check>Order management
        </div>
        <div className="pricing-plan-entry">
          <Check></Check>Inventory management
        </div>
        <div className="pricing-plan-entry">
          <Check></Check>Automated notifications
        </div>
        <div className="pricing-plan-entry">
          <SparkleSmall></SparkleSmall>Unlimited product listings
        </div>
        <div className="pricing-plan-entry">
          <SparkleSmall></SparkleSmall>Unlimited orders
        </div>
        <div className="pricing-plan-entry">
          <Check></Check>More features to come
        </div>
        <div className="footer">
          Perfect for&nbsp;
          <b>bigger businesses</b> that need enterprise solutions
        </div>
      </div> */}
    </div>

      {
        !!this.state.selectedPlan &&
        <div style={{textAlign: 'center'}}>
          <p>You selected: <b>{this.state.selectedPlan === 'free' ? 'Free' : 'Standard'}</b></p>
          <button className="confirm-btn" onClick={this.requestPlanUpgrade}>Submit</button>
        </div>
      }
    </div>)
  }
}

const mapDispatchToProps = {
  onBuilding

}

export default connect(null, mapDispatchToProps)(SelectPlan);
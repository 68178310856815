export const STYLES = {
    root: {
        height: '105vh',
        marginRight: '5vw',
        marginLeft: '5vw',
        justifyContent: 'center'
    },
    headerText: {
        fontSize: '2.5em',
        fontFamily: 'Kollektif',
        fontWeight: '600',
        textAlign: 'center',
        marginTop: 15,
    },
    planPaper: {
        borderRadius: 15,
        marginBottom: 30,
    },
    planContainer: {
        marginTop: 40,
    },
    planItemFirst: {
        borderTopLeftRadius: 15,
        borderbottomLeftRadius: 15,
        borderRight: '1px solid #e4e7ed',
    },
    planItem: {
        minHeight: 300,
        padding: 35,
    },
    planItemLast: {
        borderTopRightRadius: 15,
        borderbottomRightRadius: 15,
        borderLeft: '1px solid #e4e7ed',
    },
    planHeaderContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    planHeaderText: {
        fontSize: '28px',
        fontFamily: 'Kollektif',
        fontWeight: 'bold',
        color: '#383938'
    },
    planHeaderCustomText: {
        marginLeft: 20
    },
    planCostContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    planCostText: {
        fontSize: '20px',
        fontFamily: 'Kollektif',
        fontWeight: '500',
        lineHeight: '18px',
        color: '#383938',
        textAlign: 'right'
    },
    planCostLabelText: {
        fontSize: '14px',
        fontFamily: 'Kollektif',
        fontWeight: '400',
        lineHeight: '18px',
        color: '#383938',
        textAlign: 'right'
    },
    planHeaderCustom: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    inclusionsRoot: {
        marginTop: 35
    },
    inclusionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 25
    },
    inclusionsText: {
        fontSize: '1em',
        fontFamily: 'Kollektif',
        fontWeight: '500',
        lineHeight: '18px',
        color: '#383938',
        marginLeft: 15,
        textAlign: 'left'
    },
    upToTextContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 30
    },
    upToText: {
        fontSize: '1em',
        fontFamily: 'Kollektif',
        fontWeight: '600',
        lineHeight: '1.3em',
        color: '#383938',
        marginLeft: 30,
        textAlign: 'center'
    },
    icons: {
        width: 16,
        height: 16
    },
    descriptionContainer: {
        marginTop: 50
    },
    descriptionFirstContainer: {
        marginTop: 70
    },
    descriptionText: {
        fontSize: '1em',
        fontFamily: 'Kollektif',
        fontWeight: '500',
        lineHeight: '1.5em',
        color: '#383938',
        maxWidth: 260
    },
    buttonSelectionContainer: {
        marginTop: 30
    },
    buttonSelection: {
        borderRadius: 10,
        borderWidth: 2,
        borderColor: '#a257c5',
        fontSize: '1.2em',
        fontFamily: 'Kollektif',
        fontWeight: 'bold',
        textTransform: 'none',
        width: '100%',
        minHeight: 55,
        '&:hover': {
            borderColor: '#a257c5',
            borderWidth: 2,
        }
    },
    buttonSelectionSelected: {
        borderColor: '#16bea2',
        color: '#16bea2',
        '&:hover': {
            borderColor: '#16bea2',
            color: '#16bea2',
            borderWidth: 2,
        },
    },

    buttonCurrentPlan: {
        borderColor: '#eff0ef',
        color: '#000',
        backgroundColor: '#eff0ef',
        '&:hover': {
            borderColor: '#eff0ef',
            color: '#000',
        }
    },
    formContainer: {
        marginTop: 60,
        marginBottom: 60,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    formLabelContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 30
    },
    formLabel: {
        fontSize: '20px',
        fontFamily: 'Kollektif',
        fontWeight: '400',
        lineHeight: '24px',
        color: '#000',
        textAlign: 'center'
    },
    formLabelPlan: {
        fontSize: '20px',
        fontFamily: 'Kollektif',
        lineHeight: '24px',
        marginLeft: 10,
        color: '#000',
        textAlign: 'center'
    },
    formInput: {
        width: '40vw',
        marginTop: 20,
        marginBottom: 20
    },
    formInputTextbox: {
        fontSize: '1em',
        fontFamily: 'Kollektif',
        lineHeight: '24px',
        color: '#000',
        textAlign: 'left'
    },
    buttonConfirm: {
        borderRadius: 10,
        borderWidth: 2,
        borderColor: '#a257c5',
        fontSize: '18px',
        fontFamily: 'Kollektif',
        fontWeight: 'bold',
        textTransform: 'none',
        minWidth: 200,
        height: 55,
        marginTop: 30,
        marginBottom: 50,
        '&:hover': {
            borderColor: '#a257c5',
            borderWidth: 2,
        },
    },
    logoMain: {
        marginTop: 50,
    }
}
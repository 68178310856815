import { IconButton, InputAdornment } from "@material-ui/core";
import React from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { ValidationTextFieldRff } from "../../libs/ob_material";
import Logo from "../Logo/Logo";
import './PasswordReset.scss';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Axios from "axios";
import { toast } from "react-toastify";
import { onLogoutAct } from "../../actions/sessionActions";
import { history } from "../..";


 class PasswordReset extends React.Component { 
   state = {
     showPassword: false,
     showConfirmPassword: false,
   }

  submit = (v) => {
    Axios.patch(`${process.env.REACT_APP_API_URL}/password_reset`,{password: v.password, reset_password_token: this.props.location.search.split("token=")[1],password_confirmation: v.password_confirmation }).then(res => {
      this.props.onLogoutAct(false);
      toast("Password Reset successful! Please login again", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose:3000,
        closeOnClick: true,
        pauseOnHover: true,
        type: "success",
        draggable: true});
      history.push('/login');
    }).catch(err => {
      toast("Your password reset token has expired, please request again at the dashboard", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose:3000,
        closeOnClick: true,
        pauseOnHover: true,
        type: "error",
        draggable: true});
    });
  }
  validate = (v) => {
    const errors = {};

    if(v.password && v.password_confirmation) {
      let re = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/);

      if(v.password.match(re)) {
        if(v.password !== v.password_confirmation) {
          errors.password = 'Passwords must match'
        }
      } else {
        errors.password = 'Password must be at least 8 alphanumeric characters with small and capital letters'
      }
    }
    return errors;
  }

  handleClickShowPassword = () => {
    this.setState({showPassword: !this.state.showPassword });
  };


  handleClickShowConfirmPassword = () => {
    this.setState({showConfirmPassword: !this.state.showConfirmPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    return (<div className="password-reset">
      <div className="password-reset-card">
        <Logo></Logo>
        <Form
            onSubmit={this.submit}
            validate={this.validate} 
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}>
                <div style={{marginBottom: 25,marginTop: 25, fontSize: 20}}><b >Change Password</b></div>
                <div style={{marginBottom: 20}}>
                  <ValidationTextFieldRff variant="outlined" type={this.state.showPassword ? 'text' : 'password'} label="New password" name="password"
                      InputProps={{
                        endAdornment: (<InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>)
                       }}
                  ></ValidationTextFieldRff>
                </div>
                <div style={{marginBottom: 20}}>
                  <ValidationTextFieldRff variant="outlined"  type={this.state.showConfirmPassword ? 'text' : 'password'}  label="Confirm new password" name="password_confirmation"
                  InputProps={{
                    endAdornment: (<InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowConfirmPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>)
                   }}
                  ></ValidationTextFieldRff>
                </div>
                <input type="submit" className="confirm-btn" value="Save"></input>
              </form>)}/>
      </div>

    </div>);
  }
}

// const mapStateToProps = state => { 
//   return {
//     merchantStore: state.merchantStore.merchantStore,
//     rejected: state.merchantStore.rejected
//   } 
// }

const mapDispatchToProps = {
  onLogoutAct
}



export default connect(null, mapDispatchToProps)(PasswordReset);
import Axios from "axios";
import { toast } from "react-toastify";
import { history } from "..";
import { onClearReduxCart, updateCartFromBE } from "./cartActions";

export const onOrderCreate = ({
  order_status,
  order_first_name,
  order_last_name,
  cellphone_number,
  city,
  email,
  house_number,
  barangay,
  province,
  building,
  subdivision,
  street,
  zip,
  purchase_type,
  total_price,
  special_instruction,
  mode_of_payment,
  item_orders,
  promo_code,
  fb_handle,
  insta_handle,
  merchant_store_id
}, shop_url) => {
  return dispatch => {
    dispatch(onOrderCreateStarted());

    Axios.post(`${process.env.REACT_APP_API_URL}/order`, {
      order_status,
      order_first_name,
      order_last_name,
      cellphone_number,
      city,
      email,
      house_number,
      province,
      street,
      building,
      subdivision,
      zip,
      purchase_type,
      total_price,
      barangay,
      item_orders,
      promo_code,
      fb_handle,
      insta_handle,
      special_instruction,
      mode_of_payment,
      merchant_store_id
    })
    .then(res => {
      dispatch(onOrderCreateCompleted(res.data));
      dispatch(onClearReduxCart(merchant_store_id));

      setTimeout( () => {
        dispatch(clearCartAndForm(merchant_store_id));
      }, 0);
      history.push(`/shop/${shop_url}/order-placed?h=${res.data.hash}`);
     })
    .catch(error => {
      if( error.response.data.error === 'Order stock error' ) {
        dispatch(onOrderCreateFailedQuantity(error.response.data.meta));
        dispatch(updateCartFromBE(error.response.data.meta, merchant_store_id));
      } else if(error.response.data.error === 'Cart validation failed'){
        dispatch(onOrderValidateCartFailed(error.response.data.meta, error.response.data.valid_products));
      }
      else {
        toast("An error has occured while creating your order, please try again at a later time", {
          autoClose:3000,
          closeOnClick: true,
          pauseOnHover: true,
          type: "error",
          draggable: true});
        dispatch(onOrderCreateFailed());
        window.location.reload(false);
      }
    });
  }
};

export const onValidateCart = (params) => {
  return dispatch => {
    Axios.post(`${process.env.REACT_APP_API_URL}order/validate_cart`, params).then(() => {
      // donuting
      dispatch(onOrderValidateCartSuccess());
    }).catch(error => {
      if(error.response.data.error === 'Cart validation failed'){
        dispatch(onOrderValidateCartFailed(error.response.data.meta, error.response.data.valid_products));
      }
    });
  }
};


export const onOrderCreateStarted = () => ({
  type: 'ORDER_CREATE_STARTED',
});

export const onOrderCreateCompleted = data => ({
  type: 'ORDER_CREATE_COMPLETED',
  payload: data,
});

export const onOrderCreateFailed = () => ({
  type: 'ORDER_CREATE_FAILED',
});

export const dismissOutOfStockError = () => ({
  type: 'DISMISS_OUT_OF_STOCK_ERROR',
});

export const dismissValidateCartError = () => ({
  type: 'DISMISS_VALIDATE_CART_ERROR',
});

export const onOrderValidateCartSuccess = () => ({
  type: 'ORDER_VALIDATE_CART_SUCCESS',
});


export const onOrderCreateFailedQuantity = (err) => ({
  type: 'ORDER_CREATE_DUE_TO_QUANTITY_FAILED',
  payload: err
});

export const onOrderValidateCartFailed = (err, valid_products) => ({
  type: 'ORDER_VALIDATE_CART_FAILED',
  payload: err,
  valid_products: valid_products
});

export const getCurrentFormState = data => ({
  type: 'GET_CURRENT_WIZARD_FORM_STATE',
  payload: data
});

export const clearCartAndForm = data => ({
  type: 'CF_CLEAR_CART_AND_FORM',
  payload: data
});

export const wizardProceed = data => ({
  type: 'PROCEED_WIZARD',
  payload: data
});

export const wizardBack = data => ({
  type: 'BACK_WIZARD',
  payload: data
});

export const wizardGoToStep = (data,id) => ({
  type: 'GO_TO_STEP_WIZARD',
  payload: data,
  id: id
});

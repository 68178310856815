import React, { useState, useEffect, useRef } from 'react';
import './SetupYourShop.scss';
import { connect, useDispatch } from 'react-redux';
import { onSetupShop } from '../../../actions/setupYourShopActions';
import { ObCheckbox, ValidationTextField } from '../../../libs/ob_material';
import { FormControlLabel } from '@material-ui/core';
import { ReactComponent as SparkleSmall } from "../../../assets/Icons/sparkle-sm.svg";
import { history } from '../../..';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  submitButton: {
    width: 160,
    height: 55,
    borderRadius: 12,
    backgroundColor: '#a257c5',
    textTransform: 'none',
    fontSize: 18,
    marginTop: 10,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#a257c5',
    }
  }
}));

function SetupYourShop({isLoggedIn}){

  const classes = useStyles();

  if(isLoggedIn) {
    history.push('/dashboard/home');
  }

  const [merchant_name, setMerchantName] = useState('');
  const [selling, setSelling] = useState('');
  const [tagline, setTagline] = useState('');
  const [requestDemo, setRequestDemo] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if(!merchant_name.length || !selling.length) {
      setDisabled(true);
      return;
    }

    setDisabled(false);
  });

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    if(!merchant_name) {
      toast('Please input the name of your business', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: "error"});
      return;
    }

    if(!selling) {
      toast('Please input what you sell', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: "error"});
      return;
    }

    setIsLoading(true)
    e.preventDefault();
    setDisabled(true);
    if(!isLoading){
      dispatch(onSetupShop({merchant_name, selling, tagline}, requestDemo));
    }
  };

  return (
    <form className="setup-your-shop" onSubmit={handleSubmit}>
      <div className="headerText">Set up Your Shop</div>
      <div className="subHeaderText">Tell us a bit more</div>

      <div className="spacedFormContainer">
        <ValidationTextField variant="outlined" label="Your Shop's Name" onChange={e => setMerchantName(e.target.value)}></ValidationTextField>
      </div>

      <div className="spacedFormContainer">
        <ValidationTextField variant="outlined" label="What do you sell? (This will not be displayed)" onChange={e => setSelling(e.target.value)}></ValidationTextField>
      </div>

      <div className="spacedFormContainer">
        <ValidationTextField variant="outlined" label="Tagline" onChange={e => setTagline(e.target.value)}></ValidationTextField>
      </div>
      <div className="spacedFormContainer">
        <FormControlLabel
          className="guided-set-up-check"
          style={{ color: "#000000C6", textAlign: 'left' }}
          control={
            <ObCheckbox
              value={requestDemo}
              onChange={(e) => {
              setRequestDemo(e.target.checked)
              }}
            />
          }
          label={<div>
            <SparkleSmall className="sparkle"/>
            <b>I would like a 20-min guided set-up + demo session</b>
            <div>We will coordinate with you via email to schedule a call</div>
          </div>}
        />
      </div>
      <Button
        classes={{
          root: classes.submitButton,
        }}
        endIcon={
          isLoading ?
            <CircularProgress color={'inherit'} size={20}/>
            : null
        }
        variant={'contained'}
        color={'primary'}
        disabled={disabled}
        onClick={handleSubmit}>
        {isLoading ? 'Submitting' : 'Submit'}
      </Button>
      {/* <input className="confirm-btn" type="submit" style={{marginTop: 20}} disabled={disabled}></input> */}
    </form>
  );
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.session.isLoggedIn
  }
};

export default connect(mapStateToProps)(SetupYourShop);
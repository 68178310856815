import React from "react";
import { X } from "react-feather";
import { connect } from "react-redux";
import { changeQuantityToCartItem, onRemoveProduct } from "../../../../actions/cartActions";
import { ValidationTextFieldRff } from "../../../../libs/ob_material";
import QuantityTicker from "../../QuantityTicker/QuantityTicker";
import './Cart.scss';
import Axios from "axios";
import _ from 'lodash';

import {
  TextField,
  Typography,
  withStyles,
  makeStyles,
  Grid,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import NextIcon from '@material-ui/icons/ArrowForward';
import { onValidateCart } from "../../../../actions/wizardActions";

const CustomTextField = withStyles({
  root: {
    [`& fieldset`]: {
      borderRadius: 8,
      borderWidth: 2,
    },
  },
})(TextField);
class Cart extends React.Component {
  defaultProductImage = 'https://orderbasket-staging.s3-ap-southeast-1.amazonaws.com/default-product.png';

  state = {
    promotionCode: '',
    isPromotionValid: null,
    promotionData: null,
    promotionErrorMessage: null,
    validatedCart: null,
    currentCart: {
      cartItems: []
    },
  }

  componentDidMount(){
    if(localStorage.getItem('promo-code') !== null){
      let promo = localStorage.getItem('promo-code');
      this.setState({promotionCode: promo}, ()=> {
        this.validateProductAPI(promo)
      })
    }
    if(this.getCurrentCart()){
      this.setState({
        currentCart: { cartItems: this.getCurrentCart().cartItems }
      })
    }
  }

  handlePromotionCode = (value) => {
    let codeRegex = /^[0-9A-Za-z\s\-]+$/
    if(value !== '' && codeRegex.test(value) === false) return
    if(value === '' || codeRegex.test(value)){
        this.setState({
          promotionCode: value.replace(/ /g,"-").toUpperCase().trim()
        })
    }
  }

  handleRemoveCartItem = () => {
    if(this.getCurrentCart()){
      this.setState({
        currentCart: { cartItems: this.getCurrentCart().cartItems }
      })
    }
  }

  combineMatchProducts = (arr) => {
    // WIP
    console.debug('arr', arr)
    console.debug('currentCart', this.state.currentCart.cartItems)
    console.debug(_.merge(this.state.currentCart.cartItems[0].products, arr[0]));
    return this.state.currentCart
    // return _.merge(this.state.currentCart, arr);
  }

  async validateProductAPI(value) {
    console.debug('called')
    const access_token = localStorage.getItem('access_token');
    const config = { headers: { Authorization: access_token } };
    let products = this.getCurrentCart() && this.getCurrentCart().cartItems;
    // let products = this.state.currentCart.cartItems;
    let prodArr = [];
    if(products){
      for(let i = 0; i < products.length; i++){
        prodArr.push({
          id: products[i].product.id,
          quantity: products[i].quantity
        })
      }
    }
    let request = {
      promo_code: value,
      item_orders: prodArr,
      merchant_store_id: this.props.merchant_store.id,
    }
    if(value !== ''){
      Axios.post(`${process.env.REACT_APP_API_URL}order/validate_promo`, request, config).then(res => {
        localStorage.setItem('promo-code', value)
        localStorage.setItem('promo-details', JSON.stringify(res.data))
        console.debug(res)
        this.setState({
          isPromotionValid: true,
          promotionData: res.data,
          validatedCart: res.data.cart ? res.data.cart : null,
          // currentCart: await this.combineMatchProducts(res.data.cart)
        }, ()=> '')
      }).catch( e => {
        localStorage.removeItem('promo-code')
        localStorage.removeItem('promo-details')
        console.debug('e', e.response)
        if(e && e.response){
          if(e.response.status === 404 || e.response.status === 422){
            this.setState({
              isPromotionValid: false,
              promotionData: null,
              promotionErrorMessage: e.response.data.error
            })
          }
        }
      });
    }
  }

  validateCartAPI = (value) => {
    const access_token = localStorage.getItem('access_token');
    const config = { headers: { Authorization: access_token } };
    let products = this.getCurrentCart() && this.getCurrentCart().cartItems;
    // let products = this.state.currentCart.cartItems;
    let prodArr = [];
    for(let i = 0; i < products.length; i++){
      prodArr.push({
        id: products[i].product.id,
        price: products[i].product.price,
        quantity: products[i].quantity,
        product_name: products[i].product.product_name
      })
    }
    let request = {
      promo_code: value,
      item_orders: prodArr,
      merchant_store_id: this.props.merchant_store.id,
    }
    Axios.post(`${process.env.REACT_APP_API_URL}order/validate_cart`, request, config).then(res => {
      console.debug(res)
    }).catch( e => {
      console.debug(e.response)
    });
  }

  getCurrentCart = () => {
    if(this.props.merchantCarts && this.props.merchant_store) {
      const merchantCart = this.props.merchantCarts.filter(m => m.id === this.props.merchant_store.id);
      return merchantCart[0];
    }
  }

  numberWithCommas(value) {
    return value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getCartTotal = (cartItems) => {
    const arrOfPrices = cartItems.map(ci => ci.quantity * ci.product.price);
    let sum = 0;
    arrOfPrices.forEach(element => {
      sum += element;
    });

    return sum;
  }

  prepareItemParams = () => {
    return this.getCurrentCart().cartItems.map(
      e => {
        let product_name = `${e.product.product_name}${e.product.variant1_value ? ", " + e.product.variant1_value : ''}${e.product.variant2_value ? ", " + e.product.variant2_value : ''}`;
        let price = e.product.price;
        let quantity = e.quantity;
        let id = e.product.id;
        let product_image = this.getCartItemProduct(e.product, e.parentProduct);

        const theItem = {product_name,price,quantity, id, product_image};

        if(!!e.product.sku && !!e.product.sku.trim()) {
          theItem['sku'] = e.product.sku;
        }

        return theItem;
      });
  }

  getProductName = (product) => {
    return `${product.product_name}${product.variant1_value ? ", " + product.variant1_value : ''}${product.variant2_value ? ", " + product.variant2_value : ''}`
  }

  incrementQuantity = (id, quantity, merchant_store_id, maxQuantity , track_stock) => {
    if(!track_stock || this.props.merchant_store.allow_sold_out) {
      this.props.changeQuantityToCartItem(id, quantity + 1, merchant_store_id);
      this.validateProductAPI(this.state.promotionCode);
    } else {
      if(maxQuantity >= quantity + 1  ) {
        this.props.changeQuantityToCartItem(id, quantity + 1, merchant_store_id);
        this.validateProductAPI(this.state.promotionCode);
      }
    }
  }

  decrementQuantity = (id, quantity, merchant_store_id) => {
    if(quantity > 1) {
      this.props.changeQuantityToCartItem(id, quantity - 1, merchant_store_id);
      this.validateProductAPI(this.state.promotionCode);
    }
  }

  getCartItemProduct = (product, parentProduct = null) => {
    let photoUrl = product.photo.url;
    if(!photoUrl && !!parentProduct) {
      photoUrl = parentProduct.photo.url;
    }
    return photoUrl || this.defaultProductImage;
  }

  showQtyTickerInfoText = (ci) => {
    if(this.props.merchant_store.allow_sold_out) {
      return false;
    }
    if(ci.product.track_stock) {
      return true;
    }
    return false;
    // return ci.product.deleted_product || (!!(!!this.props.orderPlaceError && ci.product.track_stock && this.props.orderPlaceError.length) && ci.quantity >= ci.product.quantity)
  }

  computeEachDiscountTotal() {
    let discountTotal = 0;
    discountTotal = parseFloat(this.state.promotionData.old_cart_total) - parseFloat(this.state.promotionData.new_cart_total)

    return this.numberWithCommas(discountTotal)
  }

  renderCart(){
    let cartItems = this.state.currentCart.cartItems;
    // if(this.getCurrentCart()) {
    //   cartItems = this.getCurrentCart().cartItems;
    // }

    const promoActive = this.state.promotionData && this.state.promotionData.success;

    return(
      <div className="cf-order-wizard-cart-container">
        {(!cartItems || !cartItems.length) && 'Your Basket is empty!'}
        {
          cartItems && cartItems.length > 0 && cartItems.map((ci, idx) => {
            let productNameClass = 'truncate cf-product-name-container';

            if(this.props.validProducts) {
              if(this.props.validProducts.find(x => {return x === parseInt(ci.product.id)})) {
                productNameClass += ' valid';
              }
            } else {
              productNameClass += ' valid';
            }

            return(
              <div className="cf-order-wizard-cart-container-row" key={ci.product.id}>
                <div
                  className="gray"
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    this.props.onRemoveProduct(
                      {
                        index: idx,
                        merchant_store_id: ci.product.merchant_store.id
                      })
                      this.validateProductAPI(this.state.promotionCode);
                      this.handleRemoveCartItem();

                      setTimeout( () => {
                        this.props.onValidateCart({
                          merchant_store_id:ci.product.merchant_store.id, 
                          item_orders: this.prepareItemParams()
                        });
                      }, 0);

                  }}>
                    <X />
                  </div>
                <div className='product-image-container'>
                  <img src={this.getCartItemProduct(ci.product, ci.parentProduct)} alt='product-img'></img>
                </div>
                <div className="cf-product-main">
                  <div className={productNameClass}>{this.getProductName(ci.product)}</div>
                  <QuantityTicker
                    currentQuantity={ci.quantity}
                    incrementQuantity={() => this.incrementQuantity(ci.product.id, ci.quantity, ci.product.merchant_store.id, ci.product.quantity, ci.product.track_stock )}
                    decrementQuantity={() => this.decrementQuantity(ci.product.id, ci.quantity, ci.product.merchant_store.id)}
                    // errorText={ci.quantity >= ci.product.quantity ? 'You have reached the maximum stock amount' : null}
                    showInfoText={this.showQtyTickerInfoText(ci)}
                    maxValue={ci.product.quantity}>
                  </QuantityTicker>
                </div>
                {
                   this.getDiscountedPrice(ci) && <div className={"promo-active-price"} style={{justifyContent: 'flex-end'}}>
                      <strike>{this.numberWithCommas(ci.quantity * ci.product.price)}</strike>
                      <div>&nbsp;&nbsp;{this.numberWithCommas(parseFloat(this.getDiscountedPrice(ci)))}</div>
                    </div>
                }
                {
                  (!this.getDiscountedPrice(ci)) && <div style={{justifyContent: 'flex-end'}}>{this.numberWithCommas(ci.quantity * ci.product.price)}</div>
                }
                
              </div>
              )
            })
        }
        {
            promoActive ? this.renderTotalSectionDiscount() : this.renderTotalSection()
        }
      </div>
    )
  }

  getDiscountedPrice = (ci) => {
    if(!(this.state.promotionData && this.state.promotionData.success)) {
      return false;
    }

    const cart = this.state.promotionData.cart;
    if(!cart) {
      
      return false;
    }

    const discounted = this.state.promotionData.cart.find(x => x.id === ci.product.id);

    if(discounted){
      if(discounted.discounted === true) {
        return discounted.new_total_price;
      }
    } else {
      return false;
    }
  }

  renderTotalSection(){
    let cartItems = this.state.currentCart.cartItems;
    // if(this.getCurrentCart()) {
    //   cartItems = this.getCurrentCart().cartItems;
    // }
    return(
      <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: '1px solid #ececec',
            paddingTop: 15
        }}>
          <div style={{fontWeight: '600'}}>
            Grandtotal
          </div>
          <span style={{fontWeight: '600'}}>
              ₱ {this.numberWithCommas(cartItems.length && this.getCartTotal(cartItems))}
          </span>
        </div>
      </div>
    )
  }

  renderTotalSectionDiscount(){
    let cartItems = this.state.currentCart.cartItems;
    // if(this.getCurrentCart()) {
    //   cartItems = this.getCurrentCart().cartItems;
    // }
    return(
      <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: '1px solid #ececec',
            paddingTop: 15
        }}>
          <div>
            Subtotal:
          </div>
          <span>
              ₱ {this.numberWithCommas(this.getCartTotal(cartItems))}
          </span>
        </div>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 15
        }}>
          <div>
            Discount:
          </div>
            {
                this.state.promotionData.type === 'free_shipping' && this.state.promotionData.apply_discount_to !== 'each' &&
                <span>
                {'Free Shipping'}
                </span>
            }
            {
                this.state.promotionData.type === 'percent' && this.state.promotionData.apply_discount_to !== 'each' &&
                <span>
                   - {parseFloat(this.state.promotionData.value) * 100 + '% off'}
                </span>
            }
            {
                this.state.promotionData.type === 'amount' && this.state.promotionData.apply_discount_to !== 'each' &&
                <span>
                   - ₱ {this.numberWithCommas(parseInt(this.state.promotionData.value))}
                </span>
            }
            {
              this.state.promotionData.apply_discount_to === 'each' &&
              <span>
                - ₱ {this.computeEachDiscountTotal()}
              </span>
            }
        </div>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 15
        }}>
          <div style={{fontWeight: '600'}}>
            Subtotal with Discount:
          </div>
          <span style={{fontWeight: '600'}}>
              ₱ {this.numberWithCommas(parseFloat(this.state.promotionData.new_cart_total))}
          </span>
        </div>
      </div>
    )
  }

  // enter key catcher
  keyPress = (e) => {
    console.debug(e.target.value)
    if(e.keyCode === 13){
      this.validateProductAPI(e.target.value.replace(/ /g,"-").toUpperCase().trim())
    }
 }

  renderPromoField(){
    return(
      <>
        <div className='step-header' style={{marginBottom: 6}}>Promo Code</div>
          <Grid container alignItems={'center'}>
            <Grid>
              <CustomTextField
                style={{marginRight: 10, marginBottom: 5}}
                error={this.state.isPromotionValid === false}
                placeholder={'Optional'}
                variant="outlined"
                onFocus={e => { e.target.select()}}
                value={this.state.promotionCode}
                onChange={(e)=> this.handlePromotionCode(e.target.value)}
                onKeyDown={this.keyPress}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton style={{ padding: 5, margin: 0 }} onClick={()=> {
                      this.state.promotionCode !== '' ?
                          this.validateProductAPI(this.state.promotionCode.replace(/ /g,"-").toUpperCase().trim())
                        :
                          this.setState({
                            isPromotionValid: null,
                            promotionData: null
                          }, ()=> {
                            localStorage.removeItem('promo-code')
                            localStorage.removeItem('promo-details')
                          })
                      }}>
                        <NextIcon style={{ color: 'gray' }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onBlur={(e) => e.target.value !== '' ?
                  this.validateProductAPI(e.target.value.replace(/ /g,"-").toUpperCase().trim())
                  :
                  this.setState({
                    isPromotionValid: null,
                    promotionData: null,
                    promotionErrorMessage: null
                  }, ()=> {
                    localStorage.removeItem('promo-code')
                    localStorage.removeItem('promo-details')
                  })
                }
               />
            </Grid>
            <Grid>
              {
                this.state.isPromotionValid !== null && this.state.promotionCode !== ''&&
                  this.state.isPromotionValid === false ?
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <HighlightOffIcon style={{color: '#f9766a'}}/>
                    <Typography variant="body1" style={{marginLeft: 10}}>
                        {!this.state.promotionErrorMessage ? 'Promo code invalid' : this.state.promotionErrorMessage}
                    </Typography>
                  </div>
                  : this.state.isPromotionValid && this.state.isPromotionValid === true &&
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <CheckCircleOutlineIcon style={{color: '#6adac6'}}/>
                    {
                      this.state.promotionData && this.state.promotionData.type === 'free_shipping' ?
                      <Typography variant="body1" style={{marginLeft: 10}}>
                        {'Promo:'} <b>{'Free Shipping'}</b>
                      </Typography>
                      : this.state.promotionData && this.state.promotionData.type === 'amount' ?
                      <Typography variant="body1" style={{marginLeft: 10}}>
                        {'Promo:'} <b>{'₱' + parseFloat(this.state.promotionData.value) + ' off'}</b>{' on '}<b>{this.state.promotionData.apply_discount_to === 'each' ? 'selected item/s' : 'entire order'}</b>
                      </Typography>
                      : this.state.promotionData && this.state.promotionData.type === 'percent' &&
                      <Typography variant="body1" style={{marginLeft: 10}}>
                        {'Promo:'} <b>{parseFloat(this.state.promotionData.value) * 100 + '% off'}</b>{' on '}<b>{this.state.promotionData.apply_discount_to === 'each' ? 'selected item/s' : 'entire order'}</b>
                      </Typography>
                    }
                  </div>
              }
            </Grid>
        </Grid>
      </>
    )
  }

  renderInstructions(){
    return(
      <>
        <div
          className='step-header'
          style={{marginBottom: 6}}>
            Special Instructions
        </div>
        <div
          className="semi-black"
          style={{marginBottom: 15, fontSize: 14}}>
            Do not enter your customer and delivery information in this field
        </div>
        <ValidationTextFieldRff
          variant="outlined"
          name='special_instruction'
          multiline
          rows={4}
          placeholder="e.g. Delivery time, Leave with a person, etc"
          rowsmax={4} />
      </>
    )
  }

  render() {
    return (
      <div className="cf-order-wizard-cart cf-order-wizard-content">
        <div className='step-header'>My Basket</div>
        {this.renderCart()}
        {this.renderPromoField()}
        {this.renderInstructions()}
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    merchantCarts: state.cart.merchantCarts,
    merchant_store: state.customer.merchant_store,
    orderPlaceError: state.wizard.orderPlaceError
  };
}

const mapDispatchToProps  = {
  onRemoveProduct,
  changeQuantityToCartItem,
  onValidateCart

}
export default connect(mapStateToProps,mapDispatchToProps)(Cart);


import axios from 'axios';
import {history} from '../index';
import { saveObject } from '../libs/localstorage';
import { toast } from 'react-toastify';
import { onLoginData } from './sessionActions';

export const onSetupShop = ({merchant_name, selling, tagline}, demoRequest = false) => {

  const access_token = localStorage.getItem('access_token');
  const config = { headers: {Authorization: access_token } };

  return dispatch => {
    dispatch(setupShopStarted());
    const data = { merchant_name, selling, tagline };
    if(demoRequest) {
      data["demo_request"] = true;
    }

    axios.post(`${process.env.REACT_APP_API_URL}/merchant_store`,data , config )
    .then(res => { 
      dispatch(setupShopSuccess(res.data));
      saveObject(res.data["merchant"], 'merchant');
      saveObject(res.data["onboarding_session"], 'onboarding');
      saveObject({page: `/signup/plan`}, "signupState");
      history.push("/signup/plan");
      dispatch(onLoginData(true));
     })
    .catch(error => {
      dispatch(setupShopFailed(error.message));
      toast(error.response.data.error, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: "error"});
    });
  }
};

const setupShopSuccess = data => ({
  type: 'SETUPSHOP_SUCCESS',
  payload: {
    ...data
  }
});

const setupShopStarted = () => ({
  type: 'SETUPSHOP_STARTED'
});

const setupShopFailed = error => ({
  type: 'SETUPSHOP_FAILED',
  payload: {
    error
  }
});
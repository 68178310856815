import React from "react";
import { history } from "../../..";
import './OrderPlaced.scss';

class OrderPlaced extends React.Component {
  state = {
    h: '',
    // timer: 8,
    // timerObj: null
  }
  componentDidMount() {
    history.push(`/order-status?h=${this.props.location.search.split('?h=')[1]}`);
    // this.setState({
    //   h: this.props.location.search.split('?h=')[1],
    // });
  //     timerObj: setInterval((e) => {
  //       if(this.state.timer === 0) {
  //         clearInterval(this.state.timerObj);
  //         history.push(`/order-status?h=${this.state.h}`)
  //       } else {
  //         this.setState({timer: this.state.timer - 1})
  //       }
  //     }, 1000)
  //   });
  }

  goToNext = () => {
    history.push(`/order-status?h=${this.state.h}`);
  }

  render() {
    return (<div></div>);
    // <div className="order-placed">
    //   <div className="order-placed-card">
    //     <h2>Order Placed!</h2>
    //     <p>
    //       We have notified the seller to prepare your order. We emailled you an <b>order summary and tracking link</b>- kindly check your promotions folder. <br/> <br/> 
    //       If paying via online payment, please upload proof of payment in the next page.
    //     </p>
    //     {/* {this.state.timer <= 5 &&  <p>Redirecting you in {this.state.timer}</p>} */}
    //     <button className="confirm-btn" onClick={this.goToNext}>Proceed</button>
    //   </div>
      
    // </div>);
  }
}

export default (OrderPlaced);
export const PLANS = [{
    type: 'free',
    title: "Free Forever",
    cost: '',
    description: 'Perfect for small business and social seller who are just starting out',
    selected: false,
    inclusions: [{
        label: 'Online shop website',
        state: 'checked'
    },{
        label: 'Order management',
        state: 'checked'
    },{
        label: 'Stock tracking',
        state: 'checked'
    },{
        label: 'Automated email notifications',
        state: 'checked'
    },{
        label: 'Lite marketing support',
        state: 'checked'
    },{
        label: 'Up to...',
        state: 'up'
    },{
        label: '1 account manager',
        state: 'checked'
    },{
        label: '8 product listings',
        state: 'checked'
    },{
        label: '3 variations per product',
        state: 'checked'
    },{
        label: '1 variation attributes',
        state: 'checked'
    },{
        label: '50 orders per month',
        state: 'checked'
    }]
},{
    type: 'standard',
    title: "Standard",
    cost: '₱ 499',
    costLabel: '/month',
    description: 'Perfect for small business and social seller who are just starting out',
    isSelected: false,
    inclusions: [{
        label: 'Online shop website',
        state: 'checked'
    },{
        label: 'Order management',
        state: 'checked'
    },{
        label: 'Stock tracking',
        state: 'checked'
    },{
        label: 'Automated email notifications',
        state: 'checked'
    },{
        label: 'Pro marketing support',
        state: 'sparkle'
    },{
        label: 'Up to...',
        state: 'up'
    },{
        label: '3 account managers',
        state: 'sparkle'
    },{
        label: '30 product listings',
        state: 'sparkle'
    },{
        label: '20 variations per product',
        state: 'sparkle'
    },{
        label: '2 variation attributes',
        state: 'sparkle'
    },{
        label: '350 orders per month',
        state: 'sparkle'
    }]
},{
    type: 'custom',
    title: "Custom",
    cost: '₱_ _ _',
    description: 'Perfect for more established businesses with regular customers',
    isSelected: false,
    inclusions: [{
        label: 'Online shop website',
        state: 'checked'
    },{
        label: 'Order management',
        state: 'checked'
    },{
        label: 'Stock tracking',
        state: 'checked'
    },{
        label: 'Automated email notifications',
        state: 'checked'
    },{
        label: 'Pro marketing support',
        state: 'sparkle'
    },{
        label: 'Up to...',
        state: 'up'
    },{
        label: 'Unlimited account managers',
        state: 'sparkle'
    },{
        label: 'Unlimited product listings',
        state: 'sparkle'
    },{
        label: 'Unlimited product variations',
        state: 'sparkle'
    },{
        label: '2 variation attribute',
        state: 'sparkle'
    },{
        label: 'Unlimited orders',
        state: 'sparkle'
    }]
}]
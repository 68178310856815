import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Logo/Logo";
import './FourOFour.scss';

class FourOFour extends React.Component {

  getLogo() {
    if (this.props.reason === 'closed')
      return (
        <span>
          <img src={this.props.merchant.logo.url} style={{verticalAlign: "top"}} alt="Logo" width="40px" height="40px"></img>
          <span style={{color: this.props.merchant.hex_code, fontWeight:"600", fontSize:"24px", paddingLeft: "12px", lineHeight: "1.6"}}>{this.props.merchant.merchant_name}</span>
        </span>
      );
    else if (this.props.reason === 'wrong')
      return (
        null
      );
    else
      return <Logo></Logo>;
  }
  
  errorMessage() {
    if (this.props.reason === 'closed')
    return (
      <div>
        <br/>
        <h3 style={{display:'flex', flexDirection:'column', alignItems:'center'}}>Our Shop is Closed</h3>
        <p>Our shop is closed at the moment. Check back on us soon!</p>
      </div>
    )
    else
    return (
    <div>
      <h3 style={{display:'flex', flexDirection:'column', alignItems:'center'}}>Page not Available</h3>
      <p>The page you're accessing is not available as of the moment. Please check if you've entered the correct URL.</p>
    </div>
    )   
  }

  // getLogo() {
  //   if (this.props.reason === 'closed')
  //     return (
  //       <span>
  //         <img src={this.props.merchant.logo.url} style={{verticalAlign: "top"}} alt="Logo" width="40px" height="40px"></img>
  //         <span style={{color: this.props.merchant.hex_code, fontWeight:"700", fontSize:"24px", paddingLeft: "12px", lineHeight: "1.6"}}>{this.props.merchant.merchant_name}</span>
  //       </span>
  //     );
  //   else if (this.props.reason === 'wrong')
  //     return (
  //       <span>
  //         <img src={this.props.merchant.logo.url} style={{verticalAlign: "top"}} alt="Logo" width="40px" height="40px"></img>
  //         <span style={{color: this.props.merchant.hex_code, fontWeight:"700", fontSize:"24px", paddingLeft: "12px", lineHeight: "1.6"}}>{this.props.merchant.merchant_name}</span>
  //       </span>
  //     );
  //   else
  //     return <Logo></Logo>;

  // }

  render() {
    return (
    <div className="four-o-four">
      <div className="four-o-four-card">
        { this.getLogo() }
        { this.errorMessage() }
        {
          this.props.reason === 'wrong' ? 
          <p className="back-to-link"><Link to={this.props.link}>Back to {this.props.merchant.merchant_name}</Link></p> 
          :
          <p className="back-to-link"><Link to="/">Back to OrderBasket Home</Link></p>
        }
      </div>
    </div>);
  }
}

export default (FourOFour);

import {history} from '../index';
import { readObject, saveObject } from '../libs/localstorage';
import axios from 'axios';


export const onBuilding = () => {
  return dispatch => {
    dispatch(buildingStarted());
    // setTimeout( () => {

    const merchant_store_id = readObject("merchant")["id"];
    const onboarding_session_id = readObject("onboarding")["id"];
    const access_token = localStorage.getItem('access_token');
    const config = { headers: {Authorization: access_token } };
    axios.patch(`${process.env.REACT_APP_API_URL}/merchant_store`, {merchant_store_id,onboarding_session_id,onboarding_complete: true }, config).then( res => {
      saveObject(res.data["onboarding_session"], 'onboarding');
      saveObject(res.data["merchant"], 'merchant');
      // history.push(res.data["onboarding_session"]["step"]);
      dispatch(buildingSuccess());
    }).catch((error) => {
      //donuting
    });
    //  }, 3000);
  };
}

const buildingSuccess = data => ({
  type: 'BUILDING_SUCCESS',
  payload: {
    ...data
  }
});

const buildingStarted = () => ({
  type: 'BUILDING_STARTED'
});
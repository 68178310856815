import { IconButton } from "@material-ui/core";
import { Menu, ShoppingCart } from "react-feather";
import { connect } from "react-redux";
import React from 'react';
import { Link } from "react-router-dom";
import { wizardGoToStep } from "../../../actions/wizardActions";
import { toggleCFNavigation } from "../../../actions/customerFacingActions";
import { history } from "../../..";
import './Header.scss';

class Header extends React.Component {
  getCurrentCart = () => {
    if(this.props.merchantCarts) {
      const merchantCart = this.props.merchantCarts.filter(m => m.id === this.props.merchant_store.id);
      return merchantCart[0];
    }
  }

  getCartQuantity = (cart) => {
    if(cart && cart.cartItems.length) {
      return cart.cartItems.map(e => e.quantity).reduce((accumulator, currentValue) => accumulator + currentValue);
    }
    return 0;
  }

  renderCart = (id) => {
    if (this.getCurrentCart() && this.getCartQuantity(this.getCurrentCart()) > 0 ) {
      return (
        <div className="my-cart" onClick={() => {
          this.props.wizardGoToStep(0,id);
          history.push(`/shop/${this.props.merchant_store.shop_url}/cart`);
        }}>
          <ShoppingCart></ShoppingCart> &nbsp;&nbsp;{this.getCartQuantity(this.getCurrentCart()) }
        </div>
      )
    }

  }

  render() {
    const {merchant_store, id, location} = this.props;

    const defaultTarget = !location ? '_blank': null;

    return (
      <div className="customer-facing-header">
        <span></span>
        <span className="store-name-logo">
          {!!merchant_store.logo.url && <img src={merchant_store.logo.url} className="logo" alt="logo"></img>}
          <Link target={defaultTarget} style={{ textDecoration:"none" }} to={`/shop/${merchant_store.shop_url}?page=1`}>
            <div className="merchant-name">{merchant_store.merchant_name}</div>
          </Link>
        </span>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          style={{
            marginLeft: "auto",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            maxWidth: 21,
            height: "auto",
            backgroundColor: 'transparent'
          }}
          onClick={() => {this.props.toggleCFNavigation()}}
          className="menu-btn">
        <Menu />
      </IconButton>
      {
        !location || location.slice(-4) !== "cart" ?
          <span className="cf-navigation">
            <Link target={defaultTarget} className={`navigation-item ${this.props.hash === '#shop' ? 'selected' : ''}`} to={`/shop/${merchant_store.shop_url}#shop`}>Shop</Link>
            <Link target={defaultTarget} className={`navigation-item ${this.props.hash === '#about' ? 'selected' : ''}`} to={`/shop/${merchant_store.shop_url}#about`}>About</Link>
            { !!merchant_store.faqs && !!merchant_store.faqs.length && <Link target={defaultTarget} className={`navigation-item ${this.props.hash === '#faq' ? 'selected' : ''}`} to={`/shop/${merchant_store.shop_url}#faq`}>FAQ</Link> }
            {this.renderCart(id)}
          </span> : null
      }
    </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    merchant_store: state.customer.merchant_store,
    merchantCarts: state.cart.merchantCarts
  };
}

const mapDispatchToProps = {
  toggleCFNavigation,
  wizardGoToStep
}

export default connect(mapStateToProps,mapDispatchToProps)(Header);

import React, { useRef, useState, useEffect } from 'react';

import './PinEntry.scss';
import { connect, useDispatch } from 'react-redux';
import { onOtp,onResendOtp } from '../../../actions/otpActions';
import { ValidationTextField } from '../../../libs/ob_material';
import { history } from '../../..';

function PinEntry({isLoggedIn}){
  const firstRender = useRef(true);
  const [otp, setOtp] = useState('');
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();

  if(isLoggedIn) {
    history.push('/dashboard/home');
  }
  
  useEffect(() => {
  
    if (firstRender.current) {
      firstRender.current = false
      return;
    }
    if(otp.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(onOtp({otp}));
  };

  const handleResend = (e) => {
    e.preventDefault();
    dispatch(onResendOtp());
  }

  return(
  <form className="otp" onSubmit={handleSubmit}>
    <div className='headerText' style={{padding: "15px"}}>We emailed you a code</div>
    <div className="spacedFormContainer">
      <ValidationTextField variant="outlined" label="OTP" onChange={e => setOtp(e.target.value)} type="number"></ValidationTextField>
    </div>
    <div className="subHeaderText" style={{marginBottom: 15, marginTop: 30}}>Didn't receive a code?&nbsp;<a href='/' onClick={handleResend}>Resend</a></div>
    <div className="subHeaderText" style={{marginBottom: 30}}>Kindly check your email Promotions and Socials folder</div>
    <input className="confirm-btn" type="submit" disabled={disabled}/>
  </form>
  );
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.session.isLoggedIn
  }
};

export default connect(mapStateToProps)(PinEntry);
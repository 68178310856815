import React from 'react';
import './AccountCreated.scss';
// import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
// import { onAccountCreated } from '../../../actions/accountCreatedActions';

function AccountCreated(){
  // const dispatch = useDispatch();
  
  // dispatch(onAccountCreated());

  return (<div className="account-created">
    <div className="headerText">Account Created!</div>
      <br/>
      <img src="/account-created.png" alt='account_created'/>
      <Link to="/dashboard/how-to-use">
        <button className="confirm-btn">To Dashboard</button>
      </Link>
    </div>);
}

export default AccountCreated;
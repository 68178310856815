const initialState = {loading: false, merchant_store: null, load_failed: false, showNavigation: false, order: null};


export default function customerFacingReducer(state = initialState, action) {
  switch(action.type) {
    case 'LOAD_STORE_STARTED':
      return {...state, loading: true};
    case 'LOAD_STORE_FAILED':
      return {...state, loading: false, load_failed: true, merchant_store: action.payload.merchant_store};
    case 'LOAD_STORE_COMPLETED':
      return {...state, loading: false, merchant_store: action.payload.merchant_store};
    case 'TOGGLE_CF_NAVIGATION':
      return {...state, showNavigation: !state.showNavigation };
    case 'CF_lOAD_ORDER_STARTED':
      return {...state, loading: true};
    case 'CF_lOAD_ORDER_FAILED':
      return {...state, loading: false, load_failed: true};
    case 'CF_lOAD_ORDER_COMPLETED':
      return {...state, loading: false, order: action.payload.order, merchant_store: action.payload.merchant_store};

    default:
      return state;
  }
}


const initialState = {loading: false, loadedProduct: {data: null}};

export default function perProductReducer(state = initialState, action) {
  switch(action.type) {
    case 'PRODUCT_MODIFIED_STARTED':
      return {...state, loading: true, loadedProduct: null};
    case 'PRODUCT_MODIFIED_COMPLETED':
      return {...state, loading: false, loadedProduct: {data: action.payload}};
    case 'PRODUCT_MODIFIED_FAILED':
      return {...state, loading: false};
    case 'EDIT_PRODUCT_LOAD_STARTED':
      return {...state, loading: true, loadedProduct: null};
    case 'EDIT_PRODUCT_LOAD_COMPLETED':
      return {...state, loading: false, loadedProduct: {data: action.payload}};
    default:
      return state;
  }
}
import React, { useState, useEffect } from 'react';

import './Signup.scss';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Link } from 'react-router-dom';
import { history } from '../../..';
import { connect } from 'react-redux';
import { onSignup } from '../../../actions/signupActions';
import { signupStateCheck } from '../../../actions/sessionActions';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { IconButton, InputAdornment } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

/** utility */
import {
  VALIDATE_EMAIL,
  VALIDATE_PASSWORD
} from '../../../utils/validator';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  submitButton: {
    width: 160,
    height: 55,
    borderRadius: 12,
    backgroundColor: '#a257c5',
    textTransform: 'none',
    fontSize: 18,
    marginTop: 10,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#a257c5',
    }
  }
}));

const CustomTextField = withStyles({
  root: {
    margin: 15,
    borderColor: "yellow !important",
    [`& fieldset`]: {
      borderRadius: 12,
      borderWidth: 2,
    },
    '& label.Mui-focused': {
      color: '#a257c5',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
      },
      '&:hover fieldset': {
        borderColor: '#a257c5',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#a257c5',
      },
    },
  },
})(TextField);

function Signup(props){

  const {
    isLoggedIn,
    signupState,
    signupStateCheck,
    onSignup,
    error
  } = props;

  const classes = useStyles();

  const isMobile = useMediaQuery("(max-width:800px)");

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [registrationParams, setRegistrationParams] = useState({
    email_address: { value: '', error: null },
    first_name: { value: '', error: null },
    last_name: { value: '', error: null },
    password: { value: '', error: null },
    confirm_password: { value: '', error: null },
  });

  /** did mount */
  useEffect(() => {
    if(isLoggedIn){
      history.push('/dashboard/home');
    } else {
      if(signupState && signupState.page !== 'signup'){
        showMessageToast("Welcome back! We'll leave you where you left off.")
        history.push(signupState.page);
      } else {
        signupStateCheck('signup');
      }
    }
  },[])

  /** has error */
  useEffect(() => {
    if(error){
      if(error.response && error.response.status === 422){
        setIsLoading(false);
      }
    }
  },[error])

  /** did change isLoggedIn */
  useEffect(() => {
    if(isLoggedIn){
      history.push('/dashboard/home');
    }
  },[isLoggedIn])

  /** did change signupState */
  useEffect(() => {
    if(signupState && signupState.page !== 'signup'){
      showMessageToast("Welcome back! We'll leave you where you left off.")
      history.push(signupState.page);
    }
  },[signupState])

  const showMessageToast = (message) => {
    const CONFIG = {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 3000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: "success"
    };

    toast(message, CONFIG);
  }

  const showErrorMessageToast = (message) => {
    const CONFIG = {
      position: "top-right",
      autoClose: 3500,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: "error"
    };

    toast(message, CONFIG);
  }

  /** show password handler */
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    setIsLoading(true);

    const {
      email_address,
      first_name,
      last_name,
      password,
      confirm_password,
    } = registrationParams;

    if(!isLoading){
      /** Submit Registration */
      if(email_address.value && first_name.value && last_name.value && confirm_password.value && password.value){
        if(password.value === confirm_password.value){
          let params = {
            email: email_address.value,
            first_name: first_name.value,
            last_name: last_name.value,
            password: password.value,
            confirm_password: confirm_password.value
          };
          onSignup(params , showErrorMessageToast);
        } else {
          showErrorMessageToast("Passwords dont'match!")
          setIsLoading(false);
        }
      } else {
        /** Show Error Message */
        setIsLoading(false);
        showErrorMessageToast('Please complete all fields.')
      }
    }
  };

  function validateFields(value, type){

    if(type === 'email_address'){
      if(VALIDATE_EMAIL(value)){
        setRegistrationParams({...registrationParams, email_address: { value: value, error: null }})
      } else {
        setRegistrationParams({...registrationParams, email_address: { value: value, error: value.length === 0 ? 'Required' : 'Please enter a valid email'}})
      }
    }

    if(type === 'first_name' || type === 'last_name'){
      if(value.length >= 2){
        setRegistrationParams({ ...registrationParams, [type]: { value: value, error: null }})
      } else {
        setRegistrationParams({...registrationParams, [type]: { value: value, error: 'Required'}})
      }
    }

    if(type === 'password'){
      if(VALIDATE_PASSWORD(value)){
        if(registrationParams.confirm_password.value !== '' && registrationParams.confirm_password.value !== value){
          setRegistrationParams({...registrationParams, confirm_password: { value: registrationParams.confirm_password.value, error: 'Passwords must match' }, password: { value: value, error: 'Passwords must match'}})
        } else {
          setRegistrationParams({...registrationParams, confirm_password: { value: registrationParams.confirm_password.value, error: null }, password: { value: value, error: null}})
        }
      } else {
        setRegistrationParams({...registrationParams, password: { value: value, error: value.length === 0 ? 'Required' : 'Password must be at least 8 alphanumeric characters with small and capital letters'}})
      }
    }

    if(type === 'confirm_password'){
      if(value){
        if(VALIDATE_PASSWORD(value)){
          if(registrationParams.password.value !== '' && registrationParams.password.value !== value){
            setRegistrationParams({...registrationParams, password: { value: registrationParams.password.value, error: 'Passwords must match' }, confirm_password: { value: value, error: 'Passwords must match'}})
          } else {
            setRegistrationParams({...registrationParams, password: { value: registrationParams.password.value, error: null }, confirm_password: { value: value, error: null}})
          }
        } else {
          setRegistrationParams({...registrationParams, confirm_password: { value: value, error: 'Password must be at least 8 alphanumeric characters with small and capital letters'}})
        }
      } else {
        setRegistrationParams({...registrationParams, confirm_password: { value: value, error: 'Required' }})
      }
    }

  }

  function renderTitle(){
    return(
      <>
        <div
          className="headerText"
          style={{padding: "15px"}}>
          Sign Up
        </div>
        <div
          className="subHeaderText"
          style={{marginBottom: 20}}>
          The first step to creating your shop
        </div>
      </>
    )
  }

  const REGISTRATION_FIELDS = [{
    label: 'Email Address*',
    state: 'email_address',
    type: 'email',
  },{
    label: 'First Name*',
    state: 'first_name',
    type: 'text',
  },{
    label: 'Last Name*',
    state: 'last_name',
    type: 'text',
  },{
    label: 'Password*',
    state: 'password',
    type: 'password',
  },{
    label: 'Confirm Password*',
    state: 'confirm_password',
    type: 'password',
  }]

  function renderForm(){
    const textFieldStyle = {width: isMobile ? '80vw' : '35vw'}
    const textFieldConfig = {style: textFieldStyle, variant: 'outlined'}

    return(
      <>
        <div className={classes.formContainer}>
          {
            REGISTRATION_FIELDS.map((value, index) => {
              if(value.type === 'password'){
                return(
                  <CustomTextField
                    {...textFieldConfig}
                    key={index}
                    type={showPassword ? "text" : "password"}
                    label={value.label}
                    value={registrationParams[value.state].value}
                    helperText={registrationParams[value.state].error}
                    error={registrationParams[value.state].error ? true : false}
                    onChange={(e)=> validateFields(e.target.value, value.state)}
                    onBlur={(e)=> validateFields(e.target.value, value.state)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }} />
                )
              } else {
                return(
                  <CustomTextField
                    {...textFieldConfig}
                    key={index}
                    type={value.type}
                    label={value.label}
                    value={registrationParams[value.state].value}
                    helperText={registrationParams[value.state].error}
                    error={registrationParams[value.state].error ? true : false}
                    onChange={(e)=> validateFields(e.target.value, value.state)}
                    onBlur={(e)=> validateFields(e.target.value, value.state)} />
                )
              }

            })
          }
        </div>
      </>
    )
  }

  function renderButton(){
    return(
      <>
        <div className={classes.buttonContainer}>
          <Button
            classes={{
              root: classes.submitButton,
            }}
            endIcon={
              isLoading ?
                <CircularProgress color={'inherit'} size={20}/>
                : null
            }
            variant={'contained'}
            color={'primary'}
            onClick={handleSubmit}>
            {isLoading ? 'Submitting' : 'Submit'}
          </Button>
          <div
            className="subHeaderText"
            style={{
              padding: "30px",
              textAlign: "center"
            }}>
            Already have an account? &nbsp;&nbsp;
            <Link to="/login">Log in!</Link>
          </div>
        </div>
      </>
    )
  }

  return(
    <>
      {renderTitle()}
      {renderForm()}
      {renderButton()}
    </>
  )
}

const mapStateToProps = state => {
  return {
    error: state.signup.error,
    isLoggedIn: state.session.isLoggedIn,
    signupState: state.session.signupState
  }
};

const mapDispatchToProps = {
  signupStateCheck, onSignup
}

export default connect(mapStateToProps,mapDispatchToProps)(Signup);
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import "./Header.scss"
import { toggleCFNavigation } from "../../../actions/customerFacingActions";


class HeaderMobile extends React.Component {
  render() {
    if(this.props.showNavigation) {
    const defaultTarget = !this.props.location ? '_blank': null;

      return (<div className="cf-navigation mobile">
        <Link target={defaultTarget} className={`navigation-item ${this.props.hash === '#shop' ? 'selected' : ''}`} onClick={() => this.props.toggleCFNavigation()} to={`/shop/${this.props.shop_url}#shop`}>Shop</Link>
        <Link target={defaultTarget} className={`navigation-item ${this.props.hash === '#about' ? 'selected' : ''}`} onClick={() => this.props.toggleCFNavigation()} to={`/shop/${this.props.shop_url}#about`}>About</Link>
        <Link target={defaultTarget} className={`navigation-item ${this.props.hash === '#faq' ? 'selected' : ''}`} onClick={() => this.props.toggleCFNavigation()} to={`/shop/${this.props.shop_url}#faq`}>FAQ</Link>
    </div>);
    }

  return (<div></div>);
  }
}


const mapStateToProps = (state) => {
  return {
    showNavigation: state.customer.showNavigation
  };
}

const mapDispatchToProps = {
  toggleCFNavigation
}

export default connect(mapStateToProps,mapDispatchToProps)(HeaderMobile);
import React, { useState, useEffect } from 'react';

import _ from 'lodash';

import clsx from 'clsx';
import ScrollIntoView from 'react-scroll-into-view'

import { ReactComponent as Check } from "../../../assets/Icons/check.svg";
import { ReactComponent as Dash } from "../../../assets/Icons/dash.svg";
import { ReactComponent as Sparkle } from "../../../assets/Icons/sparkle-sm.svg";
import { ReactComponent as SparkleMain } from "../../../assets/Icons/sparkle.svg";
import Logo from '../../Logo/Logo';
import { ArrowLeft } from "react-feather";
import { Link } from 'react-router-dom';

import ConfirmDialog from './ConfirmDialog';
import { PLANS } from './Constants';
import { STYLES } from './styles';

import { readObject } from '../../../libs/localstorage';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { history } from '../../../index';
import { connect } from 'react-redux';
import { onBuilding } from '../../../actions/buildingActions';
import { onMerchantStoreLoad } from "../../../actions/merchantStoreActions";
import BackButton from '../../MerchantDashboard/BackButton/BackButton';

import {
    makeStyles,
    withStyles,
    Typography,
    Grid,
    Paper,
    Button,
    TextField,
    CircularProgress,
    useMediaQuery
} from '@material-ui/core';

const useStyles = makeStyles((theme) => (STYLES));

const CustomTextField = withStyles({
    root: {
      marginTop: 15,
      width: '100%',
      borderColor: "yellow !important",
      [`& fieldset`]: {
        borderRadius: 12,
        borderWidth: 2,
      },
      '& label.Mui-focused': {
        color: '#a257c5',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
        },
        '&:hover fieldset': {
          borderColor: '#a257c5',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#a257c5',
        },
      },
    },
  })(TextField);

const PLANS_CONFIG = PLANS;

function Plans(props) {

    const classes = useStyles();

    const [plans, setPlans] = useState([])
    const [selected, setSelected] = useState(null)
    const [currentPlan, setCurrentPlan] = useState(null)

    const [showDialog, setShowDialog] = useState(false);
    const [type, setType] = useState(null)

    const [productCount, setProductCount] = useState('')
    const [orderCount, setOrderCount] = useState('')
    const [concerns, setConcerns] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isCustomFieldsValid, setIsCustomFieldsValid] = useState(false)

    const isMobileView = useMediaQuery('(max-width:600px)');

    const {
        onBuilding,
        match,
        merchantStore,
        onMerchantStoreLoad,
    } = props;

    useEffect(() => {
        setPlans(PLANS_CONFIG)

        onMerchantStoreLoad();
        setCurrentPlan(null);
    },[])

     useEffect(()=> {
        if(match.path === '/plan/upgrade'){
            setCurrentPlan(merchantStore && merchantStore.plan)
        } else {
            setCurrentPlan(null)
        }
     },[merchantStore])

    useEffect(() => {
        if(productCount !== '' && orderCount !== ''){
            setIsCustomFieldsValid(true)
        } else {
            setIsCustomFieldsValid(false)
        }
    },[productCount, orderCount, concerns])

    const showMessageToast = (message) => {
      const CONFIG = {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: "success"
      };

      toast(message, CONFIG);
    }

    const showErrorMessageToast = (message) => {
      const CONFIG = {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3500,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: "error"
      };

      toast(message, CONFIG);
    }

    function showConfirmation(type){
        setShowDialog(true);
        setType(type)
    }

    function selectedPlan(plan){
        setSelected(plan)
    }

    function handleSelectPlan(plan){
        setIsLoading(true);

        if(match.path === '/signup/plan'){
            onBuilding();
        }

        const ACCESS_TOKEN = localStorage.getItem('access_token');
        const MERCHANT_STORE_ID = readObject("merchant")["id"];
        const ENDPOINT = `${process.env.REACT_APP_API_URL}/contact_admin/upgrade_plan`;

        let REQUEST = {
            merchant_store_id: MERCHANT_STORE_ID,
            source: match.path,
            product_listings_needed: productCount,
            monthly_orders: orderCount,
            concerns: concerns,
            intended_plan: plan, // free | standard | custom
        }

        const CONFIG = {
            headers: {
                Authorization: ACCESS_TOKEN
            },
            params: {
                ...REQUEST
            }
        };

        Axios.get(ENDPOINT, CONFIG).then(response => {
            if(response?.data.message === 'success'){
                showConfirmation(plan)
                setIsLoading(false);
                return
            }
            if(response.error) {
                setIsLoading(false);
                throw(response.error);
            }
            return response;
        })
        .catch(error => {
            showErrorMessageToast("Oops. Something went wrong. Try again.");
            setIsLoading(false);
            return error;
        })
    }

    function renderPlans(value, index){
        return(
            <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl
                key={index}
                className={clsx(classes.planItem, (index === 0 && classes.planItemFirst), (index === 2 && classes.planItemLast))}>
                <div className={classes.planHeaderContainer}>
                    <div className={classes.planHeaderCustom}>
                        {index === 2 && <SparkleMain/>}
                        <Typography variant="h6" className={clsx(classes.planHeaderText, (index === 2 && classes.planHeaderCustomText))}>
                            {value.title}
                        </Typography>
                    </div>
                    {
                        index === 1 &&
                        <div className={classes.planCostContainer}>
                            <Typography variant="body2" className={classes.planCostText}>
                                {value.cost}
                            </Typography>
                            <Typography variant="body2" className={classes.planCostLabelText}>
                                {value.costLabel}
                            </Typography>
                        </div>
                    }
                    {
                        index === 2 &&
                        <div className={classes.planCostContainer}>
                            <Typography variant="body2" className={classes.planCostText}>
                                {value.cost}
                            </Typography>
                        </div>
                    }
                </div>
                <div className={classes.inclusionsRoot}>
                    {
                        value.inclusions.map((value, index) => {
                            if(value.state !== 'up'){
                                return(
                                    <div key={index} className={classes.inclusionsContainer}>
                                        {value.state === 'checked' && <Check className={classes.icons} />}
                                        {value.state === 'dash' && <Dash className={classes.icons} />}
                                        {value.state === 'sparkle' && <Sparkle className={classes.icons} />}
                                        <Typography variant="body2" className={classes.inclusionsText}>
                                            {value.label}
                                        </Typography>
                                    </div>
                                )
                            } else {
                                return(
                                    <div key={index} className={classes.upToTextContainer}>
                                        <Typography variant="body1" className={classes.upToText}>
                                            {value.label}
                                        </Typography>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
                <div className={clsx(classes.descriptionContainer)}>
                {
                    renderDescription(index)
                }
                </div>
                <div className={classes.buttonSelectionContainer}>
                    {renderButtons(value)}
                </div>
            </Grid>
        )
    }

    function renderDescription(index){
        switch(index){
            case 0:
                return(
                    <span>
                        <Typography variant="body1" className={classes.descriptionText}>
                            {'Perfect for '}<b>{'small businesses and social sellers '}</b>{'who are just starting out'}
                        </Typography>
                    </span>
                )
            case 1:
                return(
                    <span>
                        <Typography variant="body1" className={classes.descriptionText}>
                            {'Perfect for '}<b>{'more established businesses '}</b>{'with regular customers'}
                        </Typography>
                    </span>
                )
            case 2:
                return(
                    <span>
                        <Typography variant="body1" className={classes.descriptionText}>
                            {'Let us adjust to whatever you business needs, might they be '}<b>{'big or small'}</b>
                        </Typography>
                    </span>
                )
            default:
                break;

        }
    }

    function renderButtons(value){
        return(
        <>
            {
             value.type === 'free' &&
             <ScrollIntoView selector="#footer">
                 {
                    match.path === '/plan/upgrade' && currentPlan === 'free' ?
                    <Button
                        variant="outlined"
                        color="primary"
                        disableRipple
                        className={clsx(classes.buttonSelection, classes.buttonCurrentPlan)}>
                        {'Your Current Plan'}
                    </Button>
                    :
                    <Button
                        onClick={()=> selectedPlan(value.type)}
                        variant="outlined"
                        color="primary"
                        className={clsx(classes.buttonSelection, (selected === 'free' && classes.buttonSelectionSelected))}
                        startIcon={selected === 'free' && <Check className={classes.icons} />}>
                        {selected === 'free' ? 'Free Plan Selected' : 'Select Free'}
                    </Button>
                 }
             </ScrollIntoView>
            }
            {
             value.type === 'standard' &&
             <ScrollIntoView selector="#footer">
                {
                    match.path === '/plan/upgrade' && currentPlan === 'standard' ?
                    <Button
                        variant="outlined"
                        color="primary"
                        disableRipple
                        className={clsx(classes.buttonSelection, classes.buttonCurrentPlan)}>
                        {'Your Current Plan'}
                    </Button>
                    :
                    <Button
                        onClick={()=> selectedPlan(value.type)}
                        variant="outlined"
                        color="primary"
                        className={clsx(classes.buttonSelection, (selected === 'standard' && classes.buttonSelectionSelected))}
                        startIcon={selected === 'standard' && <Check className={classes.icons} />}>
                        {selected === 'standard' ? 'Standard Plan Selected' : 'Select Standard'}
                    </Button>
                 }
            </ScrollIntoView>
            }
            {
             value.type !== 'free' && value.type !== 'standard' &&
             <ScrollIntoView selector="#footer">
                 {
                    match.path === '/plan/upgrade' && currentPlan !== 'free' && currentPlan !== 'standard' ?
                    <Button
                        variant="outlined"
                        color="primary"
                        disableRipple
                        className={clsx(classes.buttonSelection, classes.buttonCurrentPlan)}>
                        {'Your Current Plan'}
                    </Button>
                    :
                    <Button
                        onClick={()=> selectedPlan(value.type)}
                        variant="outlined"
                        color="primary"
                        className={clsx(classes.buttonSelection, (selected === 'custom' && classes.buttonSelectionSelected))}
                        startIcon={selected === 'custom' && <Check className={classes.icons} />}>
                        {selected === 'custom' ? 'Custom Plan Selected' : 'Select Custom Plan'}
                    </Button>
                 }
            </ScrollIntoView>
            }
        </>
        )
    }

    function renderForm(){
        switch(selected){
            case 'free':
                return(
                    <div className={classes.formContainer}>
                        <Grid container justify="center" className={classes.formLabelContainer}>
                            <Typography variant="body1" className={clsx(classes.formLabel)}>
                                {'You selected:'}
                            </Typography>
                            <Typography variant="body1" className={clsx(classes.formLabelPlan)}>
                                <b>{'Free Forever'}</b>
                            </Typography>
                        </Grid>
                        <Button
                             onClick={()=> handleSelectPlan('free')}
                             variant="contained"
                             color="primary"
                             endIcon={
                                isLoading ?
                                  <CircularProgress color={'inherit'} size={20}/>
                                  : null
                              }
                             className={clsx(classes.buttonConfirm)}>
                             {'Confirm'}
                         </Button>
                    </div>
                )
            case 'standard':
                return(
                    <div className={classes.formContainer}>
                        <Grid container justify="center" className={classes.formLabelContainer}>
                            <Typography variant="body1" className={clsx(classes.formLabel)}>
                                {'You selected:'}
                            </Typography>
                            <Typography variant="body1" className={clsx(classes.formLabelPlan)}>
                                <b>{'Standard'}</b> {' for '} <b>{'₱499'}</b>{'/ month'}
                            </Typography>
                        </Grid>
                        <Button
                             onClick={()=> handleSelectPlan('standard')}
                             variant="contained"
                             color="primary"
                             endIcon={
                                isLoading ?
                                  <CircularProgress color={'inherit'} size={20}/>
                                  : null
                              }
                             className={clsx(classes.buttonConfirm)}>
                             {'Confirm'}
                         </Button>
                    </div>
                )
            case 'custom':
                return(
                    <div className={classes.formContainer}>
                        <Grid container justify="center" className={classes.formLabelContainer}>
                            <Typography variant="body1" className={clsx(classes.formLabel)}>
                                {'You selected:'}
                            </Typography>
                            <Typography variant="body1" className={clsx(classes.formLabelPlan)}>
                                <b>{'Custom Plan'}</b>
                            </Typography>
                        </Grid>
                        <div className={classes.formInput}>
                            <Typography variant="body1" className={clsx(classes.formInputTextbox)}>
                                {'How many '}<b>{'products listings'}</b>{' will you need?'}
                            </Typography>
                            <CustomTextField
                                placeholder={'Ex. 100 Products'}
                                variant="outlined"
                                value={productCount}
                                className={classes.formTextbox}
                                onChange={(e)=> {
                                    if(e.target.value !== '' && !/^[0-9]+$/.test(e.target.value)){
                                        return
                                    }
                                    setProductCount(e.target.value)
                                }} />
                        </div>
                        <div className={classes.formInput}>
                            <Typography variant="body1" className={clsx(classes.formInputTextbox)}>
                                 {'How many '}<b>{'monthly orders'}</b>{' will you need?'}
                            </Typography>
                            <CustomTextField
                                placeholder={'Ex. 500 Orders'}
                                variant="outlined"
                                value={orderCount}
                                className={classes.formTextbox}
                                onChange={(e)=> {
                                    if(e.target.value !== '' && !/^[0-9]+$/.test(e.target.value)){
                                        return
                                    }
                                    setOrderCount(e.target.value)
                                }} />
                        </div>
                        <div className={classes.formInput}>
                            <Typography variant="body1" className={clsx(classes.formInputTextbox)}>
                                {'Any other concerns'}
                            </Typography>
                            <CustomTextField
                                placeholder={'Ex. I would like assistance in uploading products'} variant="outlined"
                                value={concerns}
                                className={classes.formTextbox}
                                onChange={(e)=> setConcerns(e.target.value)} />
                        </div>
                        <Button
                             onClick={()=> handleSelectPlan('custom')}
                             variant="contained"
                             color="primary"
                             disabled={!isCustomFieldsValid}
                             endIcon={
                                isLoading ?
                                  <CircularProgress color={'inherit'} size={20}/>
                                  : null
                              }
                             className={clsx(classes.buttonConfirm)}>
                             {'Confirm'}
                         </Button>
                    </div>
                )
            default:
                break;

        }
    }

    return(
        <div className={classes.root}>
            <ConfirmDialog
                show={showDialog}
                showDialog={setShowDialog}
                routeChange={()=> history.push('/dashboard/how-to-use')}
                currentPlan={currentPlan}
                type={type} />
            {
                match.path === '/plan/upgrade' &&
                <div className={classes.logoMain}>
                    <Logo />
                </div>
            }
            {
                match.path === '/plan/upgrade' &&
                <div style={{position: 'absolute', top: 125, left: 160}}>
                    <Link to={''}
                        onClick={(e) => {e.preventDefault(); history.goBack()}}
                        style={{
                            textDecoration: 'none',
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                        <ArrowLeft/> Back
                    </Link>
                </div>
            }
            <Typography variant="body1" className={classes.headerText}>
                Select a Plan
            </Typography>
            <Paper elevation={2} className={classes.planPaper} style={{marginLeft: match.path === '/plan/upgrade' && !isMobileView ? 100 : 0, marginRight: match.path === '/plan/upgrade' && !isMobileView ? 100 : 0}}>
                <Grid container align={'center'} className={classes.planContainer}>
                {
                    plans.map((value, index) => {
                        return renderPlans(value, index)
                    })
                }
                </Grid>
            </Paper>
            <div id='footer'>
                {renderForm()}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
  return {
    merchantStore: state.merchantStore.merchantStore,
    rejected: state.merchantStore.rejected
  }
}

const mapDispatchToProps = {
    onBuilding,
    onMerchantStoreLoad
}

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
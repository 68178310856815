import './Shipping.scss';
import React from "react";
import { connect } from 'react-redux';
import { Radios } from 'mui-rff';
import { Select as SelectRff} from 'mui-rff';
import { MenuItem } from '@material-ui/core';

class Shipping extends React.Component {

  getAvailableRadios = (will_ship_to_customer, customer_can_book_order, customer_can_pickup, delivery_cities, enable_fixed_delivery_fee, fixed_delivery_fee) => {

    const available = [];
    if(will_ship_to_customer) {
      available.push(
        {
          label:
          <div>Ship item to me - {enable_fixed_delivery_fee ? 'Fixed shipping rates apply' : 'Customer will shoulder shipping fee upon delivery'}
            <br/>
            {
              <div>
                <span> Available: {delivery_cities}</span>
              </div>
            }
            {
              localStorage.getItem('promo-details') && JSON.parse(localStorage.getItem('promo-details')).type === 'free_shipping' &&
              <div>
                <span style={{color: '#a257c5'}}>Promo: <b>Free Shipping</b></span>
              </div>
            }
            {
              enable_fixed_delivery_fee && fixed_delivery_fee.length > 0 && !localStorage.getItem('promo-details') &&
              <SelectRff
                disabled={this.props.values.purchase_type !== 'Delivery'}
                label={'Select shipping area'}
                name="delivery_location"
                variant="outlined">
                {
                  fixed_delivery_fee.map((e, idx) => {
                    return (<MenuItem value={e.location} key={idx}>
                      <div className="shipping-menu-item">
                        <span className="shipping-location">{e.location}</span>
                        <span>{e.fee == 0 ? '-' : `₱ ${e.fee}`}</span>
                        </div>
                      </MenuItem>);
                  })
                }
              </SelectRff>
            }
            {
              enable_fixed_delivery_fee && fixed_delivery_fee.length > 0 && localStorage.getItem('promo-details') && JSON.parse(localStorage.getItem('promo-details')).type !== 'free_shipping' &&
              <SelectRff
                disabled={this.props.values.purchase_type !== 'Delivery'}
                label={'Select shipping area'}
                name="delivery_location"
                variant="outlined">
                {
                  fixed_delivery_fee.map((e, idx) => {
                    return (<MenuItem value={e.location} key={idx}>
                      <div className="shipping-menu-item">
                        <span className="shipping-location">{e.location}</span>
                        <span>{e.fee == 0 ? '-' : `₱ ${e.fee}`}</span>
                        </div>
                      </MenuItem>);
                  })
                }
              </SelectRff>
            }
          </div>,
          value: 'Delivery'})
    }

    if(customer_can_book_order) {
      available.push({label: 'I will book courier', value: 'Book'});
    }

    if(customer_can_pickup) {
      available.push({label: 'I will pick up the item/s', value: 'Pick-up'});
    }

    return available;
  }

  getPaymentOptions = (payment_options,cod) => {
    const pOptions = [];

    if(payment_options.length) {
      pOptions.push({label:
        <div>Online
          <div>
            Available: {payment_options.map(e => e.mode_of_payment).join(', ')}
          </div>
        </div>, value: 'Online'});

    }

    if(cod) {
      pOptions.push({label: 'Cash', value: 'Cash'});
    }
    return pOptions;
  }

  render() {

    console.debug(JSON.parse(localStorage.getItem('promo-details')))

    const {
      will_ship_to_customer,
      customer_can_book_order,
      customer_can_pickup,
      delivery_cities,
      payment_options,
      enable_fixed_delivery_fee,
      fixed_delivery_fee,
      cod
    } =  this.props.merchant_store;

    return (<div className="cf-order-wizard-shipping cf-order-wizard-content">
      <div className='step-header'>Shipping</div>
      <Radios
         name="purchase_type"
         data={this.getAvailableRadios(will_ship_to_customer, customer_can_book_order, customer_can_pickup, delivery_cities,enable_fixed_delivery_fee, fixed_delivery_fee)}
      />
      {/* {
        will_ship_to_customer & id !== 12
        ?
        <p>*Delivery fee will be shouldered by buyer upon delivery</p>
        :
        null
      } */}
      <div className='step-header'>Mode of Payment</div>
      <Radios
         name="mode_of_payment"
         data={this.getPaymentOptions(payment_options,cod)}
      />
      <p>*For online payments: Upload the proof of payment after placing an order</p>
    </div>);
  }
}

const mapStateToProps = (state) => {
  return {
    merchant_store: state.customer.merchant_store,
  }
}

export default connect(mapStateToProps)(Shipping);
import React from "react";
import { AlertTriangle } from "react-feather";
import { connect } from "react-redux";
import { OnLoadOrder } from "../../../actions/customerFacingActions";
import OBLoader from "../../Loader/OBLoader";
import OrderSummary from "./OrderSummary/OrderSummary";
import './PostOrder.scss';
import dayjs from 'dayjs';
import { history } from "../../..";
import Axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide, Menu, MenuItem } from "@material-ui/core";
import Header from "../Header/Header";
import HeaderMobile from "../Header/HeaderMobile";
import '../CustomerFacing.scss';
import { createGlobalStyle } from 'styled-components';
import ProofOfPayment from "./ProofOfPayment/ProofOfPayment";


class PostOrder extends React.Component {
  
  state = {
    showCancelDialog: false,
    anchorEl: null
  }

  componentDidMount() {
    this.props.OnLoadOrder(this.props.location.search.split('?h=')[1]);
  }

  getOrderStatus(order_status) {
    return order_status;
  }

  transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  cancelOrder = () => {
    Axios.post(`${process.env.REACT_APP_API_URL}/order/cancel_order`, {order_hash: this.props.location.search.split('?h=')[1]} ).then(res => {
      toast('We have informed the shop to cancel your order.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: "success"});
      this.props.OnLoadOrder(this.props.location.search.split('?h=')[1]);
        this.setState({showCancelDialog: false});
    }).catch( error => {
      toast('We could not cancel your order, please contact the store', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: "error"});
    });
  }

  handleOptionsClick = (event) => {
    this.setState({ anchorEl: event.currentTarget})
  }

  handleOptionsClose = () => {
    this.setState({ anchorEl: null})
  }

  render() {
    if(!this.props.order || !this.props.merchant_store ) {
      return <OBLoader/>;
    }

    let hex = this.props.merchant_store.hex_code || '#A357C6';

    const MerchantStyle =  createGlobalStyle`
      .customer-facing-parent {
        --merchant-color: ${hex};
      }
    `;
    return <div className="customer-facing-parent">
      <MerchantStyle/>
      <Dialog
        open={this.state.showCancelDialog}
        TransitionComponent={this.transition}
        className="confirm-order-dialog"
        keepMounted>
        <DialogTitle id="alert-dialog-slide-title">Cancel order</DialogTitle>
        <DialogContent>
          <p>
          Are you sure you want to cancel this order?
          </p>
        </DialogContent>
        <DialogActions style={{padding: "30px"}}>
          <button className="confirm-btn-white small" onClick={() => {this.setState({showCancelDialog: false})}}>Back</button>
          <button className="confirm-btn small" style={{marginRight: 10}}onClick={() => {this.cancelOrder()}}>Confirm</button>
        </DialogActions>
      </Dialog>
      <Header />
      <HeaderMobile shop_url={this.props.merchant_store.shop_url}/>
      <div className="customer-facing">
      {
        !!this.props.merchant_store.header_photo.url && <div className="header-img">
          <img src={`${this.props.merchant_store.header_photo.url}`} alt="header_photo"></img>
        </div>
      }
      </div>
      <div className="thank-you">Thank you for your order! ☺️</div>
      <div className= "post-order">
        {this.props.order.order_status === 'Payment Pending'  && 
          <ProofOfPayment locations={this.props.location.search.split('?h=')[1]}></ProofOfPayment>
        }
        
        <div className="post-order-order-status">
          <div className="header-row">
            <div className="section-label">Order #{this.props.order.order_number}</div>
            {
              (this.props.order.order_status === 'Payment Pending' || this.props.order.order_status === 'For Confirmation') && 
              <button className="options-btn" aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleOptionsClick}>Options</button>
            }
            {
            (this.props.order.order_status === 'Payment Pending' || this.props.order.order_status === 'For Confirmation') &&
              <Menu 
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleOptionsClose}>
                <MenuItem onClick={(e) => {this.handleOptionsClose(e); this.setState({showCancelDialog: true})}}>Cancel Order</MenuItem>
              </Menu>
            }
          </div>
          <div className="order-status-grid">
            <div className="gray">
                Placed on:
            </div>
            <div className="field">
              {dayjs(this.props.order.created_at).format('MMM D, YYYY')}
            </div>
            <div className="gray">
                Customer:
            </div>
            <div className="field">
              {this.props.order.customer_information.first_name + " " + this.props.order.customer_information.last_name}
            </div>
            <div className="gray">
                Order Total:
            </div>
            <div className="field">
              ₱ {this.props.order.total_price}
            </div>
          </div>
        </div>
        
        {
          this.props.order.order_status_history && 
            <div className="post-order-order-status">   
              <div className="header-row">
                <div className="section-label">Order Status</div>
              </div>
                { this.props.order.order_status_history.map((status, i) => {
                    return (
                    <div className={`order-statuses status-index-${i}`}>
                      <div className="status-indicator">
                        <div className="circle"></div>
                      </div>
                      <div className="status-timestamp">{status.timestamp}</div>
                      <div className="status-msg">{status.message}</div>
                      {/* {status.timestamp} - {status.message} */}
                    </div>);
                  })
                }
            </div>
        }

        <OrderSummary className="post-order-order-status" form={this.props.order} merchant_store={this.props.merchant_store}/>

        <div className="post-order-order-status">   
          <div className="header-row">
            <div className="section-label">Order Information</div>
          </div>
          <div className="order-status-grid">
            <div className="gray">Name:</div>
            <div>{this.props.order.customer_information.first_name + " " + this.props.order.customer_information.last_name}</div>
            <div className="gray">Contact No:</div>
            <div>{this.props.order.customer_information.cellphone_number}</div>
            <div className="gray">Email:</div>
            <div>{this.props.order.customer_information.email}</div>
            { this.props.order.customer_information.fb_handle && <div className="gray">Facebook:</div> }
            { this.props.order.customer_information.fb_handle && <div className="field">{this.props.order.customer_information.fb_handle}</div> }
          </div>
          <div className="gray-line"></div>
          <div className="order-status-grid">
            <div className="gray" style={{paddingTop:"20px"}}>Mode of Payment:</div>
            <div style={{paddingTop:"20px"}}>{this.props.order.mode_of_payment}</div>
            <div className="gray">Mode of Shipping:</div>
            <div>{this.props.order.purchase_type}</div>
            <div className="gray">Shipping Address:</div>
            <div>{this.getDeliveryAddress()}</div>
            { this.props.order.zip && <div className="gray">ZIP Code:</div> }
            { this.props.order.zip && <div className="field">{this.props.order.zip}</div> }
          </div>
        </div>

        <div className="cf-footer">
          <Link target='_blank' to={`/shop/${this.props.merchant_store.shop_url}#about`}>
            <button className="contact-btn">Contact {this.props.merchant_store.merchant_name}</button> 
          </Link>
        </div>

        <div className="cf-footer">
          <a href="https://orderbasket.app">
            <img src="/ob-gray-logo.svg" alt="Logo"></img>
          </a>
        </div>
      </div>
    </div>;
  }

  getDeliveryAddress = () => {
    var address = "";
    if(this.props.order.delivery_address) {
      address = [this.props.order.delivery_address.house_number, this.props.order.delivery_address.street, this.props.order.delivery_address.building, this.props.order.delivery_address.subdivision,this.props.order.delivery_address.barangay, this.props.order.delivery_address.city, this.props.order.delivery_address.province, this.props.order.delivery_address.zip];
      address = address.join(" ");
    }
    return address;
  }
}

const mapStateToProps = (state) => {
  return {
    order: state.customer.order,
    merchant_store: state.customer.merchant_store
  }
}

const mapDispatchToProps = {
  OnLoadOrder
}

export default connect(mapStateToProps,mapDispatchToProps)(PostOrder);
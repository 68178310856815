import Axios from "axios";
import { ArrowLeft, ShoppingCart } from "react-feather";
import React from "react";
import { connect } from "react-redux";
import FourOFour from "../../FourOFour/FourOFour";
import { Link } from 'react-router-dom';
import OBLoader from "../../Loader/OBLoader";
import './ProductView.scss';
import { onAddCartProduct } from "../../../actions/cartActions";
import { history } from "../../..";
import { toast } from 'react-toastify';
import { wizardGoToStep } from "../../../actions/wizardActions";
import QuantityTicker from "../QuantityTicker/QuantityTicker";

class ProductView extends React.Component {
  state = {
    error: false,
    product: null,
    showNavigation: false,
    currentQuantity: 1,
    currentPrice: 0,
    variant1_selected: '',
    variant2_selected: '',
    variant1_displayed: [],
    variant2_displayed: [],
    showQtyError: false,
    description: {
      currentDescription: '',
      descriptionShort: true,
      descNormal:  true,
    },
  }

  defaultProductImage = 'https://orderbasket-staging.s3-ap-southeast-1.amazonaws.com/default-product.png';


  getDescriptionDisplay = () => {

    const showMore = () => 
      this.setState({description: {...this.state.description, currentDescription : this.state.description.origDescription, descriptionShort : false}});

    const showLess = () => 
      this.setState({description: {...this.state.description, currentDescription : this.state.description.shortenedDescription, descriptionShort : true}});

    return (
      <div>
        {
          this.state.description.descNormal ?
          <p>
            {this.state.description.currentDescription}
          </p> :
          <p>
            {this.state.description.currentDescription}
            <br></br>

            {
              this.state.description.descriptionShort ?
              <span className="desc-show" style={{fontWeight: '600', cursor: 'pointer', lineHeight: 2}} onClick={showMore}>Show more</span>
                :
              <span className="desc-show" style={{fontWeight: '600', cursor: 'pointer', lineHeight: 2}} onClick={showLess}>Show less</span>
            }
          </p>
        }
        </div>
      );
  }
  
  addTuCart = () => {
    if(this.state.product.variations_enabled) {
      this.props.onAddCartProduct({
        merchant_store_id: this.state.product.merchant_store.id,
        product: this.getCurrentProductVariant()[0],
        parentProduct: this.state.product,
        quantity: this.state.currentQuantity
      },this.state.product.merchant_store.shop_url );
    } else {
      this.props.onAddCartProduct({
        merchant_store_id: this.state.product.merchant_store.id,
        product: this.state.product,
        parentProduct: null,
        quantity: this.state.currentQuantity
      },this.state.product.merchant_store.shop_url );
    }
  }

  getCurrentCart = () => {
    if(this.props.merchantCarts && this.props.merchant_store) {
      const merchantCart = this.props.merchantCarts.filter(m => m.id === this.props.merchant_store.id);
      return merchantCart[0];
    }
  }

  componentDidMount() {
    Axios.get(`${process.env.REACT_APP_API_URL}/product/${this.props.location.pathname.split('/product/')[1]}`).then(e => {
      let changeState = {product: e.data.product};

      if(e.data.product.variations_enabled) {
        changeState.currentPrice = 0;
        changeState = {...changeState, ...this.getResetVariations(e.data.product)};
      } else {
        changeState.currentPrice = e.data.product.price;
      }  

      var descObj = {
        origDescription : e.data.product.description,
        shortenedDescription :  e.data.product.description.split(/\s+/).slice(0,35).join(" ") + '...',
        currentDescription: e.data.product.description.split(" ").length > 35 ? e.data.product.description.split(/\s+/).slice(0,35).join(" ") + '...' : e.data.product.description,
        descriptionShort: true,
        descNormal:  e.data.product.description.split(" ").length <= 35,
      }
      
      changeState.description = descObj;
      this.setState(changeState);
    }).catch( err => {
      this.setState({error: true});
    });
  }

  getResetVariations = (product) => {
    const changeState = {};

    let variant1_displayed = [...new Set(product.variants.map( x => x.variant1_value))]
    let variant2_displayed = [...new Set(product.variants.map( x => x.variant2_value))]
    
    if(!variant2_displayed[0]) {
      variant2_displayed = []
    }
    changeState.variant1_displayed = variant1_displayed;
    changeState.variant2_displayed = variant2_displayed;

    return changeState;
  }

  getCurrentProductVariant = (
    product = this.state.product,
    variant1 = this.state.variant1_selected,
    variant2 = this.state.variant2_selected ) => {
    let variant = [];
    if(this.state.product.variant2_key) {
      variant = product.variants.filter(e => {
        const variant1match = variant1 === e.variant1_value;
        if(variant2 && this.state.variant2_displayed.length) {
          return variant1match && variant2 === e.variant2_value;
        } else {
          return false;
        }
      });
    } else if(product.variants) { 
      variant = product.variants.filter(e => {
        return variant1 === e.variant1_value
      });
    }

    return variant;
  }

  getProductImage = (p) => {
    let productImageUrl = p.photo.url;
    if(!productImageUrl) {
      productImageUrl = this.defaultProductImage;
    }

    // snce main product has no image, get main image  from the first variant that has an image...
    if(p.variants && productImageUrl === this.defaultProductImage) {
      for(let e of p.variants) {
          if(e.photo.url) {
            return e.photo.url;
          }
        }
    }

    return productImageUrl;
  }

  selectVariant1 = (variant, product) => {
    if(variant === this.state.variant1_selected) {
      this.setState( {...this.getResetVariations(product), variant1_selected: '', variant2_selected: '', currentPrice: 0, currentQuantity: 1});
    } else {
      const changeState = {variant1_selected: variant, currentQuantity: 1};
      const selectedVariants = product.variants.filter( e => {
        return e.variant1_value === variant
      }).map(e => e.variant2_value);
      changeState.variant2_displayed = selectedVariants;

      const currentVariant = this.getCurrentProductVariant(product,variant, this.state.variant2_selected)[0];
      if(currentVariant) {
        changeState.currentPrice = currentVariant.price;
      }

      this.setState(changeState);
    }
  }

  selectVariant2 = (variant, product) => {
    if(variant === this.state.variant2_selected) {
      this.setState({...this.getResetVariations(product), variant1_selected: '',variant2_selected: '', currentPrice: 0, currentQuantity: 1});
    } else {
      const changeState = {variant2_selected: variant, currentQuantity: 1};
      const selectedVariants = product.variants.filter( e => {
        return e.variant2_value === variant
      }).map(e => e.variant1_value);
      changeState.variant1_displayed = selectedVariants;

      const currentVariant = this.getCurrentProductVariant(product,this.state.variant1_selected, variant)[0];
      if(currentVariant) {
        changeState.currentPrice = currentVariant.price;
      }

      this.setState(changeState);
    }
  }

  currentProductIsOutOfStockDueToCart = (product, is_variant = false) => {
    const cart = this.getCurrentCart();
    // get current cart quantity of product
    if(cart) {
      const currentVariant = this.getCurrentProductVariant();

      const theProductInCart = is_variant && !!currentVariant && !!currentVariant[0] ? cart.cartItems.find( p => p.product.id === currentVariant[0].id) : cart.cartItems.find( p => p.product.id === product.id);
      if(theProductInCart) {
        const lessQuant = is_variant && !!currentVariant && !!currentVariant[0] ? currentVariant[0].quantity -  theProductInCart.quantity : product.quantity - theProductInCart.quantity;
        return lessQuant <= 0;
      }
    }

    return false;
  }

  getVariantPriceRange = (product) => {
    if(!this.props.merchant_store.allow_sold_out && this.props.merchant_store.display_sold_out_as_sold_out && product.track_stock && !product.variations_enabled && product.quantity <= 0) {
      return 'Out Of Stock';
    }

    if(!this.props.merchant_store.allow_sold_out && product.track_stock && !product.variations_enabled && product.quantity <= 0) {
      return 'Out Of Stock';
    }

    if(product.variations_enabled  && !this.props.merchant_store.allow_sold_out && !product.variants.length) {
      return 'Out Of Stock';
    }

    if(!this.props.merchant_store.allow_sold_out && this.props.merchant_store.display_sold_out_as_sold_out && product.track_stock && !product.variations_enabled && this.currentProductIsOutOfStockDueToCart(product)) {
      return 'Out Of Stock (all in cart)';
    }

    // hidden products with variants, but 1 or more variants show
    if(!this.props.merchant_store.allow_sold_out && !this.props.merchant_store.display_sold_out_as_sold_out && product.track_stock && this.currentProductIsOutOfStockDueToCart(product, true)) {
      return 'Out Of Stock (all in cart)';
    }

    if(!this.props.merchant_store.allow_sold_out && this.props.merchant_store.display_sold_out_as_sold_out && product.track_stock &&  this.currentProductIsOutOfStockDueToCart(product, true)) {
      return 'Out Of Stock (all in cart)';
    }
    

    if(product.variations_enabled && product.variants.length) {
      
      const currentSelected = this.getCurrentProductVariant()[0];
      if(currentSelected) {
        return '₱ ' + new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,      
          maximumFractionDigits: 2,
       }).format(currentSelected.price);
      }

      let allPrices = product.variants.map(e => parseFloat( e.price));
      allPrices = [...new Set(allPrices)].sort(function(a,b) { return a - b;});

      if(allPrices.length === 1) {
        return '₱ ' + new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,      
          maximumFractionDigits: 2,
       }).format(allPrices[0]);
      } else {
        return `₱ ${new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,      
          maximumFractionDigits: 2,
          }).format(allPrices[0])} - ${new Intl.NumberFormat('en-US', {
              minimumFractionDigits: 2,      
              maximumFractionDigits: 2,
          }).format(allPrices[allPrices.length -1])}`;
      }
    } else {
      return '₱ ' + new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,      
        maximumFractionDigits: 2,
     }).format(product.price);
    }
  }

  getProductDisplay = (product) => {
    const currentProductV = this.getCurrentProductVariant()[0];
    let photoUrl = currentProductV ? currentProductV.photo.url : product.photo.url;

    if(!photoUrl) {
      photoUrl = this.getProductImage(product);
    }

    return (<div className="product-display-view">
      <div className="product-photo">
        <img src={photoUrl} alt="product_name"></img>
      </div>
      <div className="product-description">
        <div className="display-name">{product.product_name}</div>
        <div className="display-price">{this.getVariantPriceRange(product)}</div>
        <div className="display-description">{this.getDescriptionDisplay()}</div>
        {this.getVariantDisplay(product)}
        { this.willRenderTicker(product, currentProductV) && 
          <div style={{marginTop: 30, marginBottom: 30}}>
            <QuantityTicker 
              displayLabel={true}
              decrementQuantity={this.decrementQuantity}
              incrementQuantity={this.incrementQuantity}
              currentQuantity={this.state.currentQuantity}
              showInfoText={this.state.showQtyError}
              maxValue={currentProductV ? currentProductV.quantity : product.quantity}
              ></QuantityTicker>
          </div>
        }
        {this.state.currentQuantity > 1 && <div style={{marginTop: 15}}>Total: ₱ {this.state.currentPrice}</div>}
        <button onClick={this.addTuCart} className="confirm-btn" style={{marginTop: 20, width: 300}} disabled={this.isAddToBasketDisabled(product)}>
          {this.addToBasketText(product)}
        </button>
      </div>
    </div>);
  }

  willRenderTicker = (mainProduct, theCurrentProduct) => {
    let truth = true;

    if(this.props.merchant_store.allow_sold_out) {
      return true;
    }

    if(mainProduct.variations_enabled) {
      if(!theCurrentProduct) {
        truth = false;
      } else if(!!theCurrentProduct && theCurrentProduct.track_stock && theCurrentProduct.quantity <= 0) {
        truth = false;
      }
    } else {
      if(mainProduct.quantity <= 0 && mainProduct.track_stock) {
        truth = false;
      }
    }
    
    return truth;
  }

  isAddToBasketDisabled = (product) => {
    if(product.variations_enabled) {
      const currentProductVariant = this.getCurrentProductVariant()[0];
      if(currentProductVariant) {
        if(this.props.merchant_store.display_sold_out_as_sold_out && !this.props.merchant_store.allow_sold_out && product.track_stock) {
          if(currentProductVariant.quantity <= 0) {
            return true;
          }
        }
      } else {
        return !this.state.currentPrice;
      }
    }

    if(!this.props.merchant_store.allow_sold_out && product.track_stock && this.props.merchant_store.display_sold_out_as_sold_out && !product.variations_enabled && product.quantity <= 0) {
      return true;
    }
    if(!this.props.merchant_store.allow_sold_out && product.track_stock && this.props.merchant_store.display_sold_out_as_sold_out && !product.variations_enabled && this.currentProductIsOutOfStockDueToCart(product)) {
      return true;
    }

    if(!this.props.merchant_store.allow_sold_out && product.track_stock && !this.props.merchant_store.display_sold_out_as_sold_out && !product.variations_enabled && product.quantity <= 0) {
      return true;
    }
    
     // hidden products with variants, but 1 or more variants show
     if(!this.props.merchant_store.allow_sold_out && !this.props.merchant_store.display_sold_out_as_sold_out && product.track_stock && this.currentProductIsOutOfStockDueToCart(product, true)) {
      return true;
    }

    if(!this.props.merchant_store.allow_sold_out && product.track_stock && this.props.merchant_store.display_sold_out_as_sold_out && this.currentProductIsOutOfStockDueToCart(product, true)) {
      return true;
    }
    else {
      return !this.state.currentPrice;
    }
  }

  getProductDisplayMobile = (product) => {
    const currentProductV = this.getCurrentProductVariant()[0];
    let photoUrl = currentProductV ? currentProductV.photo.url : product.photo.url;

    if(!photoUrl) {
      photoUrl = this.getProductImage(product);
    }

    return (<div className="product-display-view-mobile">
      <div className="display-name">{product.product_name}</div>
      <div className="display-price">{this.getVariantPriceRange(product)}</div>
      <div className="display-photo">
        <img src={photoUrl} alt="product_name"></img>
      </div>
      <div className="display-description">{this.getDescriptionDisplay()}</div>

      {this.getVariantDisplay(product)}
      { this.willRenderTicker(product, currentProductV) &&
        <div style={{marginTop: 30, marginBottom: 30}}>
          <QuantityTicker  displayLabel={true} decrementQuantity={this.decrementQuantity} incrementQuantity={this.incrementQuantity} currentQuantity={this.state.currentQuantity}></QuantityTicker>
        </div>
      }
      
      {this.state.currentQuantity > 1 && <div style={{marginTop: 15}}>Total: {this.state.currentPrice}</div>}
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <button onClick={this.addTuCart} className="confirm-btn" style={{marginTop: 20}}  disabled={this.isAddToBasketDisabled(product)}>
          {this.addToBasketText(product)}
        </button>
      </div>
    </div>);
  }

  addToBasketText = (product) => {
    if(product.variations_enabled) {
      const currentProductVariant = this.getCurrentProductVariant()[0];
      if(currentProductVariant) {
        if(this.props.merchant_store.display_sold_out_as_sold_out && !this.props.merchant_store.allow_sold_out && product.track_stock) {
          if(currentProductVariant.quantity <= 0) {
            return 'Out of Stock';
          }
        }
      } else {
        return 'Select Variation';
      }
    } else {
      if(product.track_stock && product.quantity <= 0 && !this.props.merchant_store.allow_sold_out) {
        return 'Out of Stock';
      }

    }
    return 'Add to Basket';
}

  getVariantDisplay = (product) => {
    if(product.variations_enabled && product.variants.length) {
      const variant1 = product.variants[0].variant1_key;
      const variant2 = product.variants[0].variant2_key;

      return (<div className="product-display-view-product-variants">
        <div className="product-display-view-product-variants-row">
        <b><span className="red">*</span>&nbsp;{variant1}:</b>&nbsp;&nbsp;&nbsp;
          {this.state.variant1_displayed.sort().map(e => (<div key={e} className={`product-display-view-product-variants-row-element${this.state.variant1_selected === e ? ' selected': ''}`} onClick={() => {
            this.selectVariant1(e, product)
          }}>{e}</div>))}
        </div>
        {
          variant2 && <div className="product-display-view-product-variants-row"><b><span className="red">*</span>&nbsp;{variant2}:</b>&nbsp;&nbsp;&nbsp;
          {this.state.variant2_displayed.sort().map(e => (<div key={e} className={`product-display-view-product-variants-row-element${this.state.variant2_selected === e ? ' selected': ''}`} onClick={() => {
            this.selectVariant2(e, product)
          }}>{e}</div>))}
          </div>
        }
      </div>)
    }
  }

  handleDrawerOpen = () => {
    this.setState({showNavigation: !this.state.showNavigation});
  }

  getMaxQuantity = (product) => {

    const cart = this.getCurrentCart();

    // get current cart quantity of product
    if(cart) {
    const theProductInCart = cart.cartItems.find( p => p.product.id === product.id);
      if(theProductInCart) {
        const lessQuant = product.quantity - theProductInCart.quantity;
        return lessQuant;
      }
    }
    
    return product.quantity;
  }

  incrementQuantity = () => {
    const nextQuantity = this.state.currentQuantity + 1;
    // const showFullQtyToast = () => {
    //   toast("No more stock available", {
    //     position: toast.POSITION.BOTTOM_CENTER,
    //     autoClose:3000,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     type: "error",
    //     draggable: true});
    // }

    if(this.state.product.variations_enabled && this.state.product.variants.length) {
      const currentProductVariant = this.getCurrentProductVariant()[0];
      if(!currentProductVariant) {
        return;
      } else {
        if(!this.props.merchant_store.allow_sold_out && currentProductVariant.track_stock && nextQuantity > this.getMaxQuantity(currentProductVariant)) {
          this.setState({showQtyError: true});
          return;
        }
      }
    } else {
      if(!this.props.merchant_store.allow_sold_out && this.state.product.track_stock && nextQuantity > this.getMaxQuantity(this.state.product)) {
        this.setState({showQtyError: true});
        return;
      }
    }
    let priceToUse = this.state.product.price;
    if(this.getCurrentProductVariant()[0] && this.state.product.variations_enabled) {
      priceToUse =  this.getCurrentProductVariant()[0].price;
    }
    
    this.setState({currentPrice: priceToUse * nextQuantity, currentQuantity: nextQuantity});
  }

  decrementQuantity = () => {
    if(this.state.product.variations_enabled && this.state.product.variants.length && !this.getCurrentProductVariant()[0]) {
      return;
    }

    if(this.state.currentQuantity === 1) return;
    const priceToUse = this.state.product.variations_enabled ? this.getCurrentProductVariant()[0].price: this.state.product.price;

    const nextQuantity = this.state.currentQuantity - 1;
    this.setState({currentPrice: priceToUse * nextQuantity, currentQuantity: nextQuantity});
  }
  
  render() {
    if(this.state.error) {
      return (<FourOFour reason="wrong" merchant={this.props.merchant_store} link={this.props.match.url.replace('/product','')}/>);
    }
    const {product} = this.state;

    if(product) {
      return (
        <div className="cf-product-view">
          <div className="back-btn">
            <Link to={''} onClick={(e) => {e.preventDefault(); history.goBack()}} style={{textDecoration: 'none'}}>
              <ArrowLeft/>
                Back
            </Link>
          </div>
          { window.innerWidth > 768 ? this.getProductDisplay(product) : this.getProductDisplayMobile(product)}
        </div>);
    }
    return <OBLoader></OBLoader>;
  }
}


const mapStateToProps = (state) => {
  return {
    merchantCarts: state.cart.merchantCarts,
    merchant_store: state.customer.merchant_store,
  };
}

const mapDispatchToProps  = {
  onAddCartProduct,
  wizardGoToStep
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductView);
const initialState = {selected_product_section: null, shown_product_sections: [], pagination: null}

export default function shopReducer(state = initialState, action) {

  switch(action.type) {
    case 'SELECT_PRODUCT_SECTION':
      return {...state, selected_product_section: action.payload};
    case 'MOVE_PRODUCT_SECTION_VIEW':
      return state;
    case 'SET_PRODUCT_SECTIONS':
      if(action.payload[0].product_section !== 'All' && action.payload[0].product_section !== 'Search Results') {
        action.payload.unshift({product_section: "All", product_section_id: 0});
      }
      return {...state, shown_product_sections: action.payload};
    default:
      return state;
  }
}
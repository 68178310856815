
import axios from 'axios';
import { toast } from 'react-toastify';
import { readObject, saveObject } from '../libs/localstorage';
import { onSessionLogoutOnExpiry } from './sessionActions';

export const onMerchantStoreLoad = () => {
  return dispatch => {
    const access_token = localStorage.getItem('access_token');
    dispatch(onMerchantLoadStarted());
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/merchant_store`,
      headers: {
        'Authorization': access_token,
      }
    }).then( res => {
      dispatch(onMerchantLoadCompleted(res.data));
    }).catch((err) => {
      //donuting for now
      if(err && err.response) {
        if(err.response.status === 401 && err.response.data.error === 'Invalid token')
        dispatch(onSessionLogoutOnExpiry());
      }
    });
  };
}

export const onMerchantStoreUpdate = (data, formData = null) => {
  return dispatch => {
    const access_token = localStorage.getItem('access_token');
    const merchant_store_id = readObject("merchant")["id"];
    const onboarding_session_id = readObject("onboarding")["id"];
    const config = { headers: {Authorization: access_token } };
    dispatch(onMerchantUpdateStarted());

    const completeHandler = res => {
      toast("Store Updated!", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose:3000,
        closeOnClick: true,
        pauseOnHover: true,
        type: "success",
        draggable: true});
      saveObject(res.data["merchant"], 'merchant');
      dispatch(onMerchantUpdateCompleted(res.data));

    }

    const failHandler = error => {
      dispatch(onMerchantUpdateFailed());
      toast(error.response.data.error, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: "error"});
    }

    if(!data && formData) {
      formData.append("merchant_store_id", merchant_store_id);
      formData.append("onboarding_session_id", onboarding_session_id);
      axios.patch(`${process.env.REACT_APP_API_URL}/merchant_store`, formData,config).then(completeHandler).catch(failHandler);
    } else {
      axios.patch(`${process.env.REACT_APP_API_URL}/merchant_store`, {
        ...data, merchant_store_id, onboarding_session_id
      },config).then(completeHandler).catch(failHandler);
    }
  };
}

const onMerchantUpdateStarted = () => ({
  type: 'MERCHANT_UPDATE_STARTED'
});

const onMerchantUpdateCompleted = (data) => ({
  type: 'MERCHANT_UPDATE_COMPLETED',
  payload: data.merchant
});

const onMerchantUpdateFailed = () => ({
  type: 'MERCHANT_UPDATE_FAILED'
});

const onMerchantLoadStarted = () => ({
  type: 'MERCHANT_LOAD_STARTED'
});

const onMerchantLoadCompleted = (data) => ({
  type: 'MERCHANT_LOAD_COMPLETED',
  payload: data
});
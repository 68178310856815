import React, { useEffect, useState, } from 'react';

import {
    makeStyles,
    withStyles,
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    Typography
} from '@material-ui/core';

import { ReactComponent as Logo } from "../../../assets/logo-dashboard.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: 400,
        borderRadius: 20,
    },
    paper: {
        borderRadius: 20
    },
    logo: {
        margin: 20,
    },
    headerText: {
        fontSize: '1.2em',
        fontFamily: 'Kollektif',
        fontWeight: '600',
        lineHeight: '1.2em',
        marginLeft: 10,
        color: '#000',
        textAlign: 'center'
    },
    contentText: {
        fontSize: '1em',
        fontFamily: 'Kollektif',
        fontWeight: '400',
        lineHeight: '1.5em',
        marginLeft: 10,
        color: '#000',
        textAlign: 'center',
        marginTop: 20,
    },
    buttonConfirm: {
        borderRadius: 10,
        borderWidth: 2,
        borderColor: '#a257c5',
        fontSize: '18px',
        fontFamily: 'Kollektif',
        fontWeight: '600',
        textTransform: 'none',
        minWidth: 200,
        height: 55,
        marginTop: 30,
        marginBottom: 50,
        '&:hover': {
            borderColor: '#a257c5',
            borderWidth: 2,
        },
    },
}));

function ConfirmDialog(props) {

    const {
        show,
        showDialog,
        routeChange,
        currentPlan,
        type,
    } = props;

    const classes = useStyles();

    function goToDashboard(){
        routeChange();
        showDialog(false);
    }

    function setFreeMessage(){
        if(currentPlan && type === 'free'){
            return <>{'We are processing your request and we will send you a'}<b style={{color: '#a257c5'}}>{' confirmation email '}</b>{'within 24 hours.'}</>
        } else {
            return 'Enjoy using Orderbasket'
        }
    }

    function renderContent(){
        return(
            <div className={classes.root}>
                <Logo className={classes.logo} />
                <Typography variant="body1" className={classes.headerText}>
                    {'Thank you for your trust!'}
                </Typography>
                {
                    type === 'free' &&
                    <Typography variant="body1" className={classes.contentText}>
                        {setFreeMessage()}
                    </Typography>
                }
                {
                    type === 'standard' &&
                    <Typography variant="body1" className={classes.contentText}>
                        {'We are processing your request and we will send you a'}<b style={{color: '#a257c5'}}>{' confirmation email '}</b>{'within 24 hours.'}
                    </Typography>
                }
                {
                    type === 'custom' &&
                    <Typography variant="body1" className={classes.contentText}>
                        {'We are processing your request and we will send you a'}<b style={{color: '#a257c5'}}>{' plan proposal '}</b>{'to your email within 24 hours.'}
                    </Typography>
                }
                <Button
                     onClick={()=> goToDashboard()}
                     variant="contained"
                     color="primary"
                     className={classes.buttonConfirm}>
                     {'To Dashboard'}
                 </Button>
            </div>
        )
    }

    return (
        <div>
          <Dialog
            maxWidth={'sm'}
            open={show}
            classes={{
                paper: classes.paper
            }}>
            <DialogContent>
              {renderContent()}
            </DialogContent>
          </Dialog>
        </div>
    );
}

export default ConfirmDialog;
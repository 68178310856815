import { toast } from "react-toastify";
import { history } from "..";
import '../components/CustomerFacing/CustomerFacing.scss';

export const onIntializeCart = data => ({
  type: 'INITIALIZE_CART',
  payload: data
});

export const onClearReduxCart = (data) => ({
  type: 'CLEAR_REDUX_CART',
  payload: data

});

export const updateCartFromBE = (data,merchantStoreId) => ({
  type: 'UPDATE_CART_QUANTITIES_FROM_BE',
  payload: data,
  merchantStoreId: merchantStoreId

});


export const changeQuantityToCartItem = (idOfProduct, theQuantity, merchantStoreId ) => ({
  type: 'CHANGE_QUANTITY_TO_CART_ITEM',
  id: idOfProduct,
  quantity: theQuantity,
  merchantStoreId: merchantStoreId
});


export const onRemoveProduct = (payload) => {
  toast("Product successfully removed from cart!", {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose:3000,
    closeOnClick: true,
    pauseOnHover: true,
    className: "custom-toast",
    type: "success",
    draggable: true});

    return {
      type: 'REMOVE_PRODUCT_FROM_CART',
      payload: payload
    };
}


export const onAddCartProduct = (data, link) => {
  toast("Product successfully added to cart!", {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose:3000,
    closeOnClick: true,
    pauseOnHover: true,
    className: "custom-toast",
    type: "success",
    draggable: true});

  history.push(`/shop/${link}`);
  
  return {
   type: 'CART_ADD_PRODUCT',
   payload: data
}};


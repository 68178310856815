
const initialState = {loading: false, products: [], error: null, searchResults: [], searchMode: false, planMetadata: null, pageMetadata: null, showEditQuantityDialog: false, selectedProduct: {quantity: 1}, downgraded: false};

export default function productReducer(state = initialState, action) {
  switch(action.type) {
    case 'LOAD_PRODUCT_STARTED':
      return {...state, loading: true, searchResults: [], searchMode: false, products: [], pageMetadata: null};
    case 'ADD_PRODUCT_STARTED':
      return {...state, loading: true, searchResults: [], searchMode: false, pageMetadata: null};
    case 'LOAD_PRODUCT_COMPLETED':
      const _products = action.payload.products.products;

      let _downgraded = false;

      for(let p of _products.map(x => x.products).flat()) {
        if(!!p.downgrade_hidden) {
          _downgraded = true;
          break;
        }
      }

      return {...state, products: _products, downgraded: _downgraded, loading: false, searchResults: [], searchMode: false, planMetadata: action.payload.products.plan_metadata, pageMetadata: action.payload.products.page_meta };
    case 'SET_PLAN_METADATA':
      return {...state, planMetadata: action.payload.plan_metadata, pageMetadata: null};
    case 'SEARCH_PRODUCT_STARTED':
      return {...state, loading: true, searchResults: []};
    case 'DELETE_PRODUCT_STARTED':
      return {...state, loading: true, searchResults: []};
      case 'DELETE_PRODUCT_COMPLETED':
      return {...state, loading: false};
    case 'SEARCH_PRODUCT_COMPLETED':
      return {...state, searchResults: action.payload.searchResults, products: [], searchMode: true, loading: false, pageMetadata: action.payload.pageMetadata};
    case 'OPEN_QUANTITY_DIALOG_TOGGLED':
      return {...state, showEditQuantityDialog: true, selectedProduct: action.payload}
    case 'CLOSE_QUANTITY_DIALOG_TOGGLED':
      return {...state, showEditQuantityDialog: false}
    default:
      return state;
  }
}
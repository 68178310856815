const initialState = {loading: false, plan_metadata: null, metadata: {
  ongoing_orders: '-',
  for_dispatch: '-',
  unconfirmed: '-',
  processing: '-',
  on_the_way: '-',
  total_sales: '-'
  }, 
  recent_orders: [],
  recent_activity: []
};


export default function homeReducer(state = initialState, action) {
  switch(action.type) {
    case 'HOME_LOAD_STARTED':
      return {...state, loading: true};
    case 'HOME_LOAD_COMPLETED':
      return {...state, loading: false, plan_metadata: action.payload.plan_metadata, metadata: action.payload.metadata, recent_activity: action.payload.recent_activity};
    default:
      return state;
  }
}
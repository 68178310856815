export const onSelectSectionId = data => ({
  type: 'SELECT_PRODUCT_SECTION',
  payload: data
});

export const onMoveProductSectionView = data => ({
  type: 'MOVE_PRODUCT_SECTION_VIEW',
  payload: data
});

export const setProductSections = data => ({
  type: 'SET_PRODUCT_SECTIONS',
  payload: data
});
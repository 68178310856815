import React from 'react';
import { Route, Switch } from "react-router-dom";
import Logo from '../Logo/Logo';
import Signup from './Signup/Signup';
import PinEntry from './PinEntry/PinEntry';
import SetupYourShop from './SetupYourShop/SetupYourShop';
import AccountCreated from './AccountCreated/AccountCreated';

import Plans from './Plans/Plans';

// import ShowOffBrand from './ShowOffBrand/ShowOffBrand';
// import ContactInfo from './ContactInfo/ContactInfo';
// import AddDescription from './AddDescription/AddDescription';
// import FAQ from './FAQ/FAQ';
// import PaymentOptions from './PaymentOptions/PaymentOptions';
// import DeliveryOptions from './DeliveryOptions/DeliveryOptions';
// import WhereAreYou from './WhereAreYou/WhereAreYou';
// import AddProducts from './AddProducts/AddProducts';
// import Building from './Building/Building';
// import OpenBusiness from './OpenBusiness/OpenBusiness';
import SelectPlan from './SelectPlan/SelectPlan';

function Onboarding(){
  return(
    <div className="container">
      <Logo></Logo>
      <Switch>
        <Route path="/signup/account_created" component={AccountCreated}></Route>
        <Route path="/signup/setup_your_shop" component={SetupYourShop}></Route>
        {/* <Route path="/signup/show_off_brand" component={ShowOffBrand}></Route>
        <Route path="/signup/contact_info" component={ContactInfo}></Route>
        <Route path="/signup/add_description" component={AddDescription}></Route>
        <Route path="/signup/your_payment_options" component={PaymentOptions}></Route>
        <Route path="/signup/delivery_options" component={DeliveryOptions}></Route>
        <Route path="/signup/add_products" component={AddProducts}></Route>
        <Route path="/signup/building" component={Building}></Route>
        <Route path="/signup/open_for_business" component={OpenBusiness}></Route>
        <Route path="/signup/where_are_you" component={WhereAreYou}></Route>
        <Route path="/signup/faq_page" component={FAQ}></Route> */}
        <Route path="/signup/pin" component={PinEntry}></Route>
        {/* <Route path="/signup/plan" component={SelectPlan}></Route> */}
        <Route path="/signup/plan" component={Plans}></Route>

        <Route path="/" component={Signup}></Route>
      </Switch>
    </div>
  );
}

export default Onboarding;
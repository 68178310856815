import { createGlobalStyle } from 'styled-components';
import React from "react";
import { connect } from "react-redux";
import { history } from "../..";
import { OnLoadStore } from "../../actions/customerFacingActions";
import OBLoader from "../Loader/OBLoader";
import About from "./About/About";
import './CustomerFacing.scss';
import FAQ from "./FAQ/FAQ";
import Shop from "./Shop/Shop";
import FourOFour from "../FourOFour/FourOFour";
import { Route, Switch } from "react-router-dom";
import OrderWizard from "./OrderWizard/OrderWizard";
import { onSelectSectionId } from "../../actions/shopActions";
import Header from "./Header/Header";
import ProductView from "./ProductView/ProductView";
import HeaderMobile from "./Header/HeaderMobile";
import OrderPlaced from "./OrderPlaced/OrderPlaced";
import { Helmet } from 'react-helmet';
import MessengerCustomerChat from 'react-messenger-customer-chat/lib/MessengerCustomerChat';
import * as qs from 'query-string';

class CustomerFacing extends React.Component {
  state = {
    showNavigation: false,
    hash: '#shop',
    view: null,
  }

  componentWillUnmount() {
    this.unlisten();
  }

  extractStoreName = () => {
    return this.props.location.pathname.split("/shop/")[1].split("/")[0];
  }

  selectCategoryFromParams = (search, merchant_store, page = 1) => {
    if(merchant_store) {
      // if(merchant_store.experimental) {
      const searchParams = {page: page};
      if(this.props.shown_product_sections) {
        const selectedProductCategory = this.props.shown_product_sections.find( e => e.product_section_id == decodeURIComponent(search) );
        if(selectedProductCategory) {
          this.props.onSelectSectionId(selectedProductCategory.product_section_id);
        } else {
          this.props.onSelectSectionId(0);
        }
        searchParams["product_section_id"] = selectedProductCategory ?  selectedProductCategory.product_section_id : null;
        this.props.OnLoadStore(this.extractStoreName(), searchParams);
      }
      // }
      // else {
      //   const {products} = merchant_store;
      //   if(products) {
      //     const selectedProductCategory = products.find( e => e.product_section === decodeURIComponent(search) );

      //     if(selectedProductCategory) {
      //       this.props.onSelectSectionId(selectedProductCategory.product_section_id);
      //     } else {
      //       this.props.onSelectSectionId(0);
      //     }
      //   }
      // }
    }
  }

  componentDidMount() {
    this.props.OnLoadStore(this.extractStoreName());
    // this.props.OnSearchStore(this.extractStoreName());
    let changeState = {};
    if(this.props.location.hash) {
      changeState.hash = this.props.location.hash;
    }

    this.setState(changeState);
    this.unlisten = this.props.history.listen((location) => {
      if(location.search) {
        const qsObject = qs.parse(location.search);
        this.selectCategoryFromParams(qsObject.category, this.props.merchant_store, qsObject.page);
      }

      this.setState(this.setState({hash: location.hash}));
    });
  }

  changeHash = (e) => {
    const hash = `#${e.target.id}`;
    history.push(this.props.location.pathname + hash);
  }

  getSubContent = () => {
    if(this.state.hash === '#shop' || !this.state.hash) {
      return <Shop location={this.props.location}></Shop>;
    }
    if(this.state.hash === '#about') {
      return <About></About>;
    }
    if(this.state.hash === '#faq') {
      return <FAQ></FAQ>;
    }
  }

  render() {
    const {merchant_store} = this.props;
 
    if(merchant_store) {
      const faviconElement = document.getElementById("favicon");
      if(merchant_store.logo.url) 
        faviconElement.href = merchant_store.logo.url;
      if(merchant_store.is_closed) {
        return (<FourOFour reason="closed" merchant={merchant_store}></FourOFour>); 
      }
      let hex = merchant_store.hex_code || '#A357C6';

      const MerchantStyle =  createGlobalStyle`
        .customer-facing-parent {
          --merchant-color: ${hex};
        }
        .customer-toast {
          --merchant-color: ${hex};
        }
      `;

        return (
          <div className="customer-facing-parent">
            {
              !!merchant_store.fb_page_id && <MessengerCustomerChat pageId={merchant_store.fb_page_id} appId=""/>
            }
            <Helmet>
              <title>
                {merchant_store.merchant_name}
              </title>
            </Helmet>
            <MerchantStyle/>
            <Header location={this.props.location.pathname} hash={this.state.hash}/>
            <HeaderMobile location={this.props.location.pathname} hash={this.state.hash} shop_url={merchant_store.shop_url}/>
              <Switch>
                <Route exact={true} path={`/shop/${this.extractStoreName()}/cart`} component={OrderWizard}></Route>
                <Route path={`/shop/${this.extractStoreName()}/order-placed`} component={OrderPlaced}></Route>
                <Route path={`/shop/${this.extractStoreName()}/product/`} component={ProductView}></Route>
                <Route path="/"  render={(props) => (<div className="customer-facing">
                  {
                    !this.state.view && !!merchant_store.header_photo.url && <div className="header-img">
                      <img src={`${merchant_store.header_photo.url}`} alt="header_photo"></img>
                    </div>
                  }
                  {this.getSubContent()}
                  </div>)}>
                </Route>
              </Switch>
              <div className="spacer"></div>
              <div className="cf-footer">
                <a href="https://orderbasket.app">
                  <img src="/ob-gray-logo.svg" alt="Logo"></img>
                </a>
              </div>
          </div>
          );
    }
    else if(this.props.failed) {
      return (<FourOFour></FourOFour>);
    }
    else {
      return <div className="loading-container"><OBLoader></OBLoader></div>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    merchant_store: state.customer.merchant_store,
    shown_product_sections: state.shop.shown_product_sections,
    failed: state.customer.load_failed,
  };
}

const mapDispatchToProps = {
  OnLoadStore,
  onSelectSectionId,
}

export default connect(mapStateToProps,mapDispatchToProps )(CustomerFacing);

import axios from 'axios';
import { onSelectSectionId, setProductSections } from './shopActions';
import * as qs from 'query-string';

export const OnLoadOrder = (order_hash) => {
  return dispatch => {
    dispatch(cfLoadOrderStarted());

    axios.get(`${process.env.REACT_APP_API_URL}/order?order_hash=${order_hash}`).then( res => {
      dispatch(cfLoadOrderCompleted(res.data));
    }).catch(error => {
      dispatch(cfLoadOrderFailed());
    });
  }
}

export const OnLoadStore = (shop_url, query = {page: 1} ) => {
  return dispatch => {
    dispatch(loadStoreStarted());
    let requestUrl = `${process.env.REACT_APP_API_URL}/stores/${shop_url}?${qs.stringify(query)}`;
    axios.get(requestUrl).then( res => {
      if(res.data.merchant_store) {
        if(res.data.merchant_store.products.products.length) {
          dispatch(setProductSections(res.data.merchant_store.product_sections));
          dispatch(loadStoreCompleted(res));
          let productS = query.product_section_id ? query.product_section_id : 0;
          dispatch(onSelectSectionId(productS));
        } 
        else {
          dispatch(loadStoreCompleted(res));
        }
        // else if( res.data.merchant_store.products.length) {
        //   const productSections = res.data.merchant_store.products.map(e => {
        //     if(!e.products.length) return null;
        //     return {
        //       product_section: e.product_section,
        //       product_section_id: e.product_section_id
        //     }
        //   }).filter(x => x !== null);
          // dispatch(setProductSections(productSections));
          // dispatch(loadStoreCompleted(res));
          // dispatch(onSelectSectionId(res.data.merchant_store.products[0].product_section_id));
        }
    }).catch((error) => {
      dispatch(loadStoreFailed(error.response));
    });
  };
}

export function OnSearchStore(params){
  return dispatch => {
    dispatch(searchStoreStarted());

    axios.get(`${process.env.REACT_APP_API_URL}/stores/${params.url}/search_product?q=${params.query}`).then( res => {
      if(res.data.merchant_store) {
          dispatch(onSelectSectionId(res.data.merchant_store.products[0].product_section_id));
          const productSections = {
            product_section: 'Search Results',
            product_section_id: -1
          }
          //   return {
          //     product_section: e.product_section,
          //     product_section_id: e.product_section_id
          //   }
          // }).filter(x => x !== null);
          dispatch(setProductSections([productSections]));
        }
      dispatch(loadStoreCompleted(res));

    }).catch((error) => {
      dispatch(searchStoreFailed(error));
    });
  };
}

const cfLoadOrderStarted = () => ({
  type: 'CF_lOAD_ORDER_STARTED'
});

const cfLoadOrderCompleted = data => ({
  type: 'CF_lOAD_ORDER_COMPLETED',
  payload: data
});

const cfLoadOrderFailed = () => ({
  type: 'CF_lOAD_ORDER_FAILED'
});

const loadStoreStarted = () => ({
  type: 'LOAD_STORE_STARTED'
});

const loadStoreFailed= (res) => ({
  type: 'LOAD_STORE_FAILED',
  payload: res.data
});

const loadStoreCompleted = res => ({
  type: 'LOAD_STORE_COMPLETED',
  payload: res.data
});

const searchStoreStarted = () => ({
  type: 'SEARCH_STORE_STARTED'
});

const searchStoreFailed = (res) => ({
  type: 'SEARCH_STORE_FAILED',
  payload: res
});

export const toggleCFNavigation = () => ({
  type: 'TOGGLE_CF_NAVIGATION'
});

const initialState = {loading: false, faqs: []};

export default function faqReducer(state = initialState, action) {
  switch(action.type) {
    case 'FAQ_ADD_STARTED':
      return {...state, loading: true};
    case 'FAQ_SUCCESS':
      return {...state, loading: false, ...action.payload};
    case 'FAQ_SUCCESS_NO_DATA':
      return {...state, loading: false};
    case 'FAQ_DELETE_SUCCESS':
      return {...state, loading: false, ...action.payload};
    case 'FAQ_DELETE_STARTED':
      return {...state, loading: true};

    default:
      return state;
  }
}
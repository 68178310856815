export const saveObject = (object, key) => {
  return localStorage.setItem(key, JSON.stringify(object));
} 

export const readObject = (key) => {
  return JSON.parse(localStorage.getItem(key));
} 

export const saveValue = (value, key) => {
  return localStorage.setItem(key, value);
}
export const readValue = (key) => {
  return localStorage.getItem(key);
}
import React from "react";
import { Facebook, Instagram, Mail, Phone, Twitter } from "react-feather";
import { connect } from "react-redux";
import OBLoader from "../../Loader/OBLoader";
import './About.scss';

class About extends React.Component {

  getFeatherIcon(thing) {
    if(thing === 'facebook') {
      return <Facebook></Facebook>;
    }

    if(thing === 'twitter') {
      return <Twitter></Twitter>;
    }

    if(thing === 'instagram') {
      return <Instagram></Instagram>;
    }

    if(thing === 'phone') {
      return <Phone></Phone>;
    }

    if(thing === 'mail') {
      return <Mail></Mail>;
    }


  }

  getDeliveryFeePhrase = () => {
    return this.props.merchant_store.enable_fixed_delivery_fee ? ', fixed rates apply.' : ', customers shoulder shipping fees.';
  }

  getDeliveryCities = () => {
    return this.props.merchant_store.delivery_cities ? ` Available in: ${this.props.merchant_store.delivery_cities}` : '';
  }


  render() {
    if(this.props.merchant_store) {
      const socials = this.props.merchant_store.socials[0];
      return (<div className="inner-content cf-about">
        
      <div className="inner-content-container">
        <div className="center-text"> 
        { (this.props.merchant_store.tagline && this.props.merchant_store.tagline !== 'null' ) && 
          <span className="store-tagline">{this.props.merchant_store.tagline}</span> }
        </div>  
      { (this.props.merchant_store.description && this.props.merchant_store.description !== 'null' ) && <div className="section-label">About</div> }
      { (this.props.merchant_store.description && this.props.merchant_store.description !== 'null' )&& <div className="maintain-break">{this.props.merchant_store.description}</div> }
        <div className="section-label">Accepted Modes of Payment</div>
        {!!this.props.merchant_store.cod && <div style={{marginLeft: 10}}>- Cash on Delivery</div>}
        { 
          this.props.merchant_store.payment_options.map(e => {
            return (<div key={e.id} style={{marginLeft: 10}}>{`- ${e.mode_of_payment}`}</div>)
          })
        }
         <div className="section-label">Delivery Options</div>
          {!!this.props.merchant_store.will_ship_to_customer && <div style={{marginLeft: 10}}>- We ship to customers{this.getDeliveryFeePhrase()}{this.getDeliveryCities()}</div>}
          {!!this.props.merchant_store.customer_can_pickup && <div style={{marginLeft: 10}}>- Customers can pickup</div>}
          {!!this.props.merchant_store.customer_can_book_order && <div style={{marginLeft: 10}}>- Customer can book 3rd party delivery</div>}
        

        <div className="section-label">Contact Us</div>
        {
          socials && 
          socials["facebook"] && 
          <div className="socials">{this.getFeatherIcon('facebook')}
            <a target="_blank" href={`https://facebook.com/${socials["facebook"]}`}>
              {socials["facebook"]}
            </a>
          </div>
        }
        {
          socials && 
          socials["twitter"] && 
          <div className="socials">
            {this.getFeatherIcon('twitter')}
            <a target="_blank" href={`https://twitter.com/${socials["twitter"]}`}>
              {socials["twitter"]}
            </a>
          </div>
           
        }
        {
          socials && 
          socials["instagram"] && 
          <div className="socials">{this.getFeatherIcon('instagram')}
            <a target="_blank" href={`https://instagram.com/${socials["instagram"]}`}>
              {socials["instagram"]}
            </a>
          </div>
        }
        {
          this.props.merchant_store["phone_number"] && 
          <div className="socials">{this.getFeatherIcon('phone')}&nbsp;&nbsp;&nbsp;{this.props.merchant_store["phone_number"]}</div>
        }

        {
          this.props.merchant_store["email"] && 
          <div className="socials">{this.getFeatherIcon('mail')}&nbsp;&nbsp;&nbsp;{this.props.merchant_store["email"]}</div>
        }
      </div>
    </div>);
    }
    return (<OBLoader/>);
  } 
}
const mapStateToProps = (state) => {
  return {
    merchant_store: state.customer.merchant_store,
    failed: state.customer.load_failed
  };
}
export default connect(mapStateToProps)(About);
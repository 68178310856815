import React from "react";
import OBLoader from '../../../Loader/OBLoader';
import dayjs from 'dayjs';
import './OrderSummary.scss';
import {
  Typography,
} from '@material-ui/core';
class OrderSummary extends React.Component {

  componentDidMount() {
    // clear all promo local storage
    // localStorage.removeItem('promo-code')
    // localStorage.removeItem('promo-details')
  }

  numberWithCommas(value) {
    return value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getCartTotal = (cartItems, addShipping = false) => {
    const arrOfPrices = cartItems.filter(p => 'product_name' in p).map(ci => ci.quantity * ci.price);
    let sum = 0;
    arrOfPrices.forEach(element => {
      sum += element;
    });

    const delivery_fee = cartItems.filter(p => 'delivery_fee' in p)[0];
    if(addShipping && !!delivery_fee) {
      sum += parseFloat(delivery_fee['delivery_fee']);
    }

    return sum;
  }

  getCartDiv = (cartItems) => {
    const items = [];
    cartItems.filter(p => 'product_name' in p).forEach((ci, idx) => {
      if(ci.product_image) {
        items.push(<div key={`product_${idx}`} className="product-image-container">
          <img src={ci.product_image} alt='product-img'></img>
        </div>);
      }
      items.push(<div className="product-name-container" key={`product_${idx}`}>{ci.product_name}</div>);
      items.push(<div key={`quantity${idx}`} style={{justifyContent: 'flex-end'}}>x{ci.quantity}</div>);

      if(this.getDiscountedPrice(ci)) {
        items.push(<div key={`price_${idx}`} style={{justifyContent: 'flex-end'}}>
          <strike>{this.numberWithCommas(ci.quantity * ci.price)}</strike>
          <div>&nbsp;&nbsp;{this.numberWithCommas(this.getDiscountedPrice(ci))}</div>
        </div>);
      }
      else {
        items.push(<div key={`price_${idx}`} style={{justifyContent: 'flex-end'}}>{this.numberWithCommas(ci.quantity * ci.price)}</div>);
      }
    });
    return items;
  }

  getDiscountedPrice = (ci) => {
    if(this.props.form.discount_meta) {
    const cart = this.props.form.discount_meta.cart;

    if(!cart) {
      return false;
    }

    const discounted = cart.find(x => x.id === ci.id);

    if(discounted){
      if(discounted.discounted === true) {
        return discounted.new_total_price;
      }
    } else {
      return false;
    }

    } else {
      return false;
    }
  }

  summaryClass = (cartItems) => {
    // backwards compatiblity for previous orders that didn't have images
    let theClass = ' no-images';

    for(let ci of cartItems) {
      if(ci.product_image) {
        return '';
      }
    }

    return theClass;
  }

  computeEachDiscountTotal() {
    let discountTotal = 0;
    discountTotal = parseFloat(this.props.form.discount_meta.old_cart_total) - parseFloat(this.props.form.discount_meta.new_cart_total)

    return this.numberWithCommas(discountTotal)
  }

  // (Promo: Small Sale 2021 , Code: SMALL-SALE, P1000 off on entire order

  render() {
    let cartItems = [];
    if(this.props.form) {cartItems = this.props.form.item_orders;};
    const delivery_meta = this.props.form.delivery_fee;
    if(!this.props.merchant_store) {return <OBLoader/>}

    return (<div className="post-order-order-status cf-order-wizard-customer-summary">
      <div className="cf-order-wizard-customer-summary-row basket">
        <div className="section-label">My Basket</div>
        <div className={`cf-order-wizard-customer-summary-item-summary-element${this.summaryClass(cartItems)}`}>
          {this.getCartDiv(cartItems)}
        </div>

        {
          ((this.props.form.purchase_type === 'Delivery' && !!delivery_meta) || (this.props.form.discount_meta)) && <div className="subtotal">
          <div style={{marginBottom: 5}}>
            <span>Subtotal: </span>
            <span>₱ {this.numberWithCommas(this.getCartTotal(cartItems))} </span>
          </div>
          <div style={{marginBottom: 5}}>
            <span>Promo:</span>
            <div>
              {
                  this.props.form.discount_meta && this.props.form.discount_meta.type !== 'none' ?
                  this.props.form.discount_meta && this.props.form.discount_meta.type === 'free_shipping' ?
                  <Typography variant="body1">
                    {this.props.form.discount_meta.promo_name + ', Code: ' + this.props.form.discount_meta.promo_code }<b>{', Free Shipping'}</b>
                  </Typography>
                  : this.props.form.discount_meta && this.props.form.discount_meta.type === 'amount' ?
                  <Typography variant="body1">
                    {this.props.form.discount_meta.promo_name + ', Code: ' + this.props.form.discount_meta.promo_code }<b>{', ₱' + parseFloat(this.props.form.discount_meta.value) + ' off' }</b>{' on '}{this.props.form.discount_meta.apply_discount_to === 'each' ? <b>{'selected item/s'}</b> : <b>{'entire order'}</b>}
                  </Typography>
                  : this.props.form.discount_meta && this.props.form.discount_meta.type === 'percent' &&
                  <Typography variant="body1">
                    {this.props.form.discount_meta.promo_name + ', Code: ' + this.props.form.discount_meta.promo_code }{', '}<b>{parseFloat(this.props.form.discount_meta.value) * 100 + '% off' }</b>{' on '}{this.props.form.discount_meta.apply_discount_to === 'each' ? <b>{'selected item/s'}</b> : <b>{'entire order'}</b>}
                  </Typography>
                :
                <span>None</span>
              }
            </div>
          </div>

          <div style={{marginBottom: 5}}>
            <span>Special Instruction:</span>
              {this.props.form.special_instruction ? this.props.form.special_instruction : <span>None</span>}
          </div>
          {
            !!delivery_meta && <div style={{marginBottom: 5}}>
            <span>Shipping fee {this.props.form && this.props.form.discount_meta && this.props.form.discount_meta.type !== 'free_shipping' && '('+ delivery_meta['location'] +')'}:</span>
            {
              this.props.form && this.props.form.discount_meta === null ?
              <span>{delivery_meta['delivery_fee'] === 0 ? '-' : `₱ ${delivery_meta['delivery_fee']}` }</span>
              :
              this.props.form && this.props.form.discount_meta && this.props.form.discount_meta.type === 'free_shipping' && <span>{'Free Shipping'}</span>
            }
            {
              this.props.form && this.props.form.discount_meta && this.props.form.discount_meta.type !== 'free_shipping' &&
              <span>{delivery_meta['delivery_fee'] === 0 ? '-' : `₱ ${delivery_meta['delivery_fee']}` }</span>
            }
          </div>
          }

          {
            this.props.form && this.props.form.discount_meta &&
            <div style={{marginBottom: 5}}>
              <span>Discount ({this.props.form.discount_meta.promo_name}):</span>
              {
                this.props.form.discount_meta.type === 'free_shipping' && this.props.form.discount_meta.apply_discount_to !== 'each' &&
                  <span style={{color: 'red'}}>
                  {'Free Shipping'}
                  </span>
              }
              {
                  this.props.form.discount_meta.type === 'percent' && this.props.form.discount_meta.apply_discount_to !== 'each' &&
                  <span style={{color: 'red'}}>
                     - {parseFloat(this.props.form.discount_meta.value) * 100 + '% off'}
                  </span>
              }
              {
                  this.props.form.discount_meta.type === 'amount' && this.props.form.discount_meta.apply_discount_to !== 'each' &&
                  <span style={{color: 'red'}}>
                     - ₱ {this.numberWithCommas(parseInt(this.props.form.discount_meta.value))}
                  </span>
              }
              {
                this.props.form.discount_meta.apply_discount_to === 'each' &&
                <span style={{color: 'red'}}>
                  - ₱ {this.computeEachDiscountTotal()}
                </span>
              }
            </div>
          }
          
        </div>
        }

        <div className="total">
          <span>Grand Total: </span>
          <span>₱ {this.numberWithCommas(this.props.form.total_price)}</span>
        </div>
      </div>
    </div>
    );
  }

  getDeliveryAddress = () => {
    const address = [this.props.form.delivery_address.house_number, this.props.form.delivery_address.street, this.props.form.delivery_address.building, this.props.form.delivery_address.subdivision,this.props.form.delivery_address.barangay, this.props.form.delivery_address.city, this.props.form.delivery_address.province, this.props.form.delivery_address.zip];
    return address.join(" ");
  }
}

// const mapStateToProps = (state) => {
//   return {
//     merchant_store: state.customer.merchant_store,
//     merchantCarts: state.cart.merchantCarts,
//     form: state.wizard.form
//   }
// }

export default (OrderSummary);
import React from "react";
import { ArrowLeft } from "react-feather";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { history } from "../../..";
import { dismissOutOfStockError, getCurrentFormState,onOrderCreate,wizardBack,wizardGoToStep,wizardProceed,onValidateCart ,dismissValidateCartError} from "../../../actions/wizardActions";
import Cart from "./Cart/Cart";
import './OrderWizard.scss';
import Shipping from "./Shipping/Shipping";
import CustomerInfo from "./CustomerInfo/CustomerInfo";
import { AlertTriangle } from "react-feather";
import OrderSummary from "./OrderSummary/OrderSummary";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grow } from "@material-ui/core";

class OrderWizard extends React.Component {
  defaultProductImage = 'https://orderbasket-staging.s3-ap-southeast-1.amazonaws.com/default-product.png';

  extractStoreName = () => {
    return this.props.location.pathname.split("/shop/")[1].split("/cart")[0];
  }

  getCurrentCart = () => {
    if(this.props.merchantCarts && this.props.merchant_store) {
      const merchantCart = this.props.merchantCarts.filter(m => m.id === this.props.merchant_store.id);
      return merchantCart[0];
    }
  }

  getBackLinkLocation = () => {
    return `/shop/${this.extractStoreName()}#shop`;
  }

  onBackClick = (e) => {
    const currentCart = this.getCurrentCart();
    e.preventDefault();
    if(currentCart && this.props.currentStep  > 0) {
      this.props.wizardBack({merchant_store_id: this.props.merchant_store.id});
    }
    else {
      history.goBack();
    }
  }

  componentDidMount() {
    this.props.getCurrentFormState({merchant_store_id: this.props.merchant_store.id});

    setTimeout( () => {
      this.props.onValidateCart({
        merchant_store_id:this.props.merchant_store.id, 
        item_orders: this.prepareItemParams()
      })
    }, 0);
  }

  getCartTotal = () => {
    let sum = 0;

    const currentCart = this.getCurrentCart();
    if(currentCart) {
      const cartItems = currentCart.cartItems;
      const arrOfPrices = cartItems.map(ci => ci.quantity * ci.product.price);
      arrOfPrices.forEach(element => {
        sum += element;
      });
    }
    return sum;
  }

  canProceed = (values) => {
    const totalIsZero = this.getCartTotal() === 0;

    if(totalIsZero) {
      return false;
    }

    if(this.props.currentStep === 0) {

      if(!!this.props.cartValidationError) {
        return false;
      }
      return true;
    }
    if(this.props.currentStep === 1) {
      if(!values.purchase_type || !values.mode_of_payment) {
        return false;
      }

      if(values.purchase_type === 'Delivery' && this.props.merchant_store.enable_fixed_delivery_fee && !values.delivery_location) {
        const promoDetails = localStorage.getItem('promo-details');

        if(promoDetails) {
          if(JSON.parse(promoDetails).type !== 'free_shipping') {
            return;
          }
        } else {
          return false;
        }
      }
    }
    if(this.props.currentStep === 2) {
      if(
        !values.order_first_name || !values.order_last_name || !values.cellphone_number || !values.email ) {
        return false;
      }

      if(values.email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!values.email.match(re)) {
          return false;
        }
      }

      if(values.purchase_type === 'Delivery') {
        if(!values.house_number || !values.street || !values.barangay || !values.city || !values.province) {
          return false;
        }
      }
    }

    if(this.props.currentStep === 3 && this.props.submitting) {
      return false;
    }

    return true;
  }

  getDeliveryFee = (location) => {
    if(!location) {
      return -1;
    }

    const a = this.props.merchant_store.fixed_delivery_fee;
    const _location = a.find( e => e.location === location);
    if(_location) {
      return _location.fee;
    } else {
      return -1;
    }
  }

  getCartItemProduct = (product, parentProduct = null) => {
    let photoUrl = product.photo.url;

    if(!photoUrl && !!parentProduct) {
      photoUrl = parentProduct.photo.url;
    }

    return photoUrl || this.defaultProductImage;
  }

  prepareItemParams = () => {
    if(!this.getCurrentCart()) return [];

    return this.getCurrentCart().cartItems.map(
      e => {
        let product_name = `${e.product.product_name}${e.product.variant1_value ? ", " + e.product.variant1_value : ''}${e.product.variant2_value ? ", " + e.product.variant2_value : ''}`;
        let price = e.product.price;
        let quantity = e.quantity;
        let id = e.product.id;
        let product_image = this.getCartItemProduct(e.product, e.parentProduct);

        const theItem = {product_name,price,quantity, id, product_image};

        if(!!e.product.sku && !!e.product.sku.trim()) {
          theItem['sku'] = e.product.sku;
        }

        return theItem;
      });
  }

  proceed = (values) => {
    if(this.props.currentStep === 3) {
      const itemOrders = this.prepareItemParams();

      if(values.purchase_type === 'Delivery' && !!values.delivery_location && parseFloat(this.getDeliveryFee(values.delivery_location)) > -1 ) {
        itemOrders.push({delivery_fee: parseFloat(this.getDeliveryFee(values.delivery_location)), location: values.delivery_location});
      }

      values.item_orders = itemOrders;
      values.merchant_store_id = this.props.merchant_store.id;

      if(localStorage.getItem('promo-code') !== null){
        values.promo_code = localStorage.getItem('promo-code'); // nilagay ko na Bon me tinetest kasi ko . thanks --> Mikee
      }

      // localStorage.removeItem('promo-code')
      // localStorage.removeItem('cart')
      setTimeout(() => {
        this.props.onOrderCreate(values, this.props.merchant_store.shop_url);
      }, 0);
    }
    else {
      this.props.wizardProceed({
        form: values,
        step: this.props.currentStep + 1,
        merchant_store_id: this.props.merchant_store.id
      });
    }
  }

  onSubmit = () => {}

  validate = (v) => {
    const errors = {};

    if(v.email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!v.email.match(re)) {
        errors.email = 'Please put a valid email';
      }
    }

    return errors;
  }

  transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} timeout={1500} />;
  });

  outOfStockDialog = () => {
    return (
      <Dialog
        open={this.props.showErrorDialog}
        TransitionComponent={this.transition}
        className="out-of-stock-dialog"
        keepMounted>
          <div className="placed-error-details-header">
            <AlertTriangle></AlertTriangle>&nbsp;&nbsp;Your order was not processed
          </div>
        {!!this.props.orderPlaceError && this.props.orderPlaceError.length && <DialogContent>
          <p>The following products in your cart recently ran out of enough stock:</p>
            {
              this.props.orderPlaceError.map( (p, idx) => {
                return (<div className="placed-error-details-items" key={idx}>-&nbsp;
                  <b>{p.product_name}</b>
                  &nbsp;|&nbsp;Remaining stock: <b>{p.current_quantity}</b>
                </div>);
              })
            }
        </DialogContent>}
        <DialogActions style={{padding: "30px", justifyContent: 'center'}}>
          <button className="confirm-btn" style={{
            marginRight: 10,
            backgroundColor: `${this.props.merchant_store.hex_code || '#A357C6' }`,
            border: `2px solid ${this.props.merchant_store.hex_code || '#A357C6'}`
          }} onClick={() => {
            this.props.dismissOutOfStockError();
          }}>Edit Cart</button>
        </DialogActions>
      </Dialog>
    );
  }


  validateCartDialog = () => {
    return (
      <Dialog
        open={this.props.showValidateCartError}
        TransitionComponent={this.transition}
        className="out-of-stock-dialog"
        keepMounted>
          <div className="placed-error-details-header">
            <AlertTriangle></AlertTriangle>&nbsp;&nbsp;
            <div style={{display: 'flex'}}>Some item/s in your cart are no longer valid</div>
          </div>
        {/* {!!this.props.orderPlaceError && this.props.orderPlaceError.length && <DialogContent>
          <p>The following products in your cart recently ran out of enough stock:</p>
            {
              this.props.orderPlaceError.map( (p, idx) => {
                return (<div className="placed-error-details-items" key={idx}>-&nbsp;
                  <b>{p.product_name}</b>
                  &nbsp;|&nbsp;Remaining stock: <b>{p.current_quantity}</b>
                </div>);
              })
            }
        </DialogContent>} */}
        {/* <DialogContent>
          <p>
            yea baby
          </p>
          </DialogContent> */}
        <DialogActions style={{padding: "30px", justifyContent: 'center'}}>
          <button className="confirm-btn" style={{
            marginRight: 10,
            backgroundColor: `${this.props.merchant_store.hex_code || '#A357C6' }`,
            border: `2px solid ${this.props.merchant_store.hex_code || '#A357C6'}`
          }} onClick={() => {
            this.props.dismissValidateCartError();
          }}>Edit Cart</button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    return (
      <div className="cf-order-wizard">
        {this.outOfStockDialog()}
        {this.validateCartDialog()}
        { !!this.props.orderPlaceError && this.props.orderPlaceError.length && this.props.currentStep === 0 && <div className="order-place-error">
          <div className="order-place-error-icon">
            <AlertTriangle></AlertTriangle>
          </div>
          <div className="placed-error-details">
            <div className="placed-error-details-header">Your order was not processed because some items recently ran out of stock</div>
            {/* <p>The following products in your cart recently ran out of enough stock:</p>
            {
              this.props.orderPlaceError.map( (p, idx) => {
                return (<div className="placed-error-details-items" key={idx}>-&nbsp;
                  <b>{p.product_name}</b>
                  &nbsp;|&nbsp;Remaining stock: <b>{p.current_quantity}</b>
                </div>);
              })
            }
          </div> */}
          </div>
        </div>}

        { !!this.props.cartValidationError && this.props.cartValidationError.length && this.props.currentStep === 0 && <div className="order-place-error">
          <div className="order-place-error-icon">
            <AlertTriangle></AlertTriangle>
          </div>
          <div className="placed-error-details">
            <div className="placed-error-details-header">Some item/s in your cart are no longer valid, please go back to the shop and re-add them to cart</div>
          </div>
        </div>}
        <div className="cf-order-wizard-header" style={this.props.showNavigation ? {top: 160} : {}}>
          <div className="header-title">
            <div className="super-title">Checkout</div>
            {this.props.currentStep === 0 && <div className="section-label">Review Basket</div>}
            {this.props.currentStep === 1 && <div className="section-label">Shipping & Payment</div>}
            {this.props.currentStep === 2 && <div className="section-label">Customer Info</div>}
            {this.props.currentStep === 3 && <div className="section-label">Confirm Details</div>}
          </div>
          <div className="stepper">
            <div className="step-label">Step</div>
            {this.props.currentStep === 0 && <div className="step-count">1 of 4</div>}
            {this.props.currentStep === 1 && <div className="step-count">2 of 4</div>}
            {this.props.currentStep === 2 && <div className="step-count">3 of 4</div>}
            {this.props.currentStep === 3 && <div className="step-count">4 of 4</div>}
          </div>
          {/* <Stepper style={{marginLeft: 'auto', minWidth: 300, marginRight: "0", padding: 16, paddingRight: 0 }}
          activeStep={this.props.currentStep} alternativeLabel connector={<OBConnector />}
          >
            <Step key={0}>
              <StepLabel>{''}</StepLabel>
            </Step>
            <Step key={1}>
              <StepLabel>{''}</StepLabel>
            </Step>
            <Step key={2}>
              <StepLabel>{''}</StepLabel>
            </Step>
            <Step key={3}>
              <StepLabel>{''}</StepLabel>
            </Step>
          </Stepper> */}
        </div>
        <div className="cf-order-wizard-body">
          <Form
            onSubmit={this.onSubmit}
            validate={this.validate}
            initialValues={this.props.w_form}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values
            }) => {
              return (
                <div>
                {!this.props.currentStep && <Cart validProducts={this.props.validProducts}></Cart>}
                {this.props.currentStep === 1 && <Shipping values={values}></Shipping>}
                {this.props.currentStep === 2 && <CustomerInfo></CustomerInfo>}
                {this.props.currentStep === 3 && <OrderSummary></OrderSummary>}
                <div className='cf-order-wizard-footer'>
                  <Link to={this.getBackLinkLocation()} onClick={this.onBackClick} style={{textDecoration: 'none'}}>
                    <button className="confirm-btn-white"><ArrowLeft/>
                      Back
                    </button>
                  </Link>
                  <button className="confirm-btn" disabled={!this.canProceed(values)} onClick={(e) => {
                    e.preventDefault();
                    this.proceed(values)
                  }}>
                    {this.props.currentStep === 3 ? 'Place Order' : 'Proceed'}
                  </button>
                </div>
              </div>
              )
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    merchantCarts: state.cart.merchantCarts,
    merchant_store: state.customer.merchant_store,
    w_form: state.wizard.form,
    currentStep: state.wizard.currentStep,
    submitting: state.wizard.submitting,
    showNavigation: state.customer.showNavigation,
    orderPlaceError: state.wizard.orderPlaceError,
    showErrorDialog: state.wizard.showErrorDialog,
    showValidateCartError: state.wizard.showValidateCartError,
    cartValidationError: state.wizard.cartValidationError,
    validProducts: state.wizard.validProducts
  };
}

const mapDispatchToProps  = {
  getCurrentFormState,
  wizardProceed,
  wizardBack,
  onOrderCreate,
  wizardGoToStep,
  dismissOutOfStockError,
  onValidateCart,
  dismissValidateCartError

}

export default connect(mapStateToProps,mapDispatchToProps)(OrderWizard);

import React from 'react';


function Logo() {
  return (
    <span>
      <img src="/orderbasket_logo.png" alt="Logo" style={{verticalAlign: "top"}}></img>
      <span className="ob-purple" style={{fontWeight:"700", fontSize:"24px", paddingLeft: "12px", lineHeight: "1.6"}}>OrderBasket</span>
    </span>
  );
}

export default Logo;
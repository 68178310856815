import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            main:'#a257c5',
        },
        secondary: {
            main: '#f50057'
        },
    },
});

export default theme;
import {
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  List,
  ListItemText,
  ListItem,
  InputAdornment,
  Popper,
  Grow,
  Dialog
} from "@material-ui/core";
import React from "react";
import ReactDOM from 'react-dom';
import { ArrowRight, ArrowUp, ChevronUp, Menu } from "react-feather";
import {
  ObCheckbox,
  ValidationTextField,
  ValidationTextFieldRff,
} from "../../libs/ob_material";
import "./Landing.scss";
import ScrollableAnchor, { configureAnchors } from "react-scrollable-anchor";
import {
  onLandingFormSubmit,
  onLandingFreeDemoSubmit,
  onLandingGetInTouchSubmit,
  onLandingSearchOrder
} from "../../actions/landingActions";
import { connect } from "react-redux";
import OBLoader from "../Loader/OBLoader";
import { ReactComponent as SparkleSmall } from "../../assets/Icons/sparkle-sm.svg";
import { ReactComponent as Sparkle } from "../../assets/Icons/sparkle.svg";
import { ReactComponent as Check } from "../../assets/Icons/check.svg";
import { ReactComponent as Dash } from "../../assets/Icons/dash.svg";
import { Form } from "react-final-form";
import { history } from '../../index';
import { Link } from "react-router-dom";
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { onSessionCheck } from "../../actions/sessionActions";
import Slider from "react-slick";
import { ChevronLeft,ChevronRight } from "@material-ui/icons";



function LandingPrevArrow(props){
  const { className, style, onClick } = props;
  return <ChevronLeft className={className} style={{fill:'black', height: '2em', width: '2em'}} onClick={onClick}></ChevronLeft>;
}

function LandingRightArrow(props){
  const { className, style, onClick } = props;
  return <ChevronRight className={className} style={{fill:'black', height: '2em', width: '2em'}} onClick={onClick}></ChevronRight>;
}


class Landing extends React.Component {
  state = {
    open: false,
    octoberDisabled: true,
    octoberEmail: "",
    octoberEmailError: "",
    showScroll: false,
    getInTouchName: "",
    getInTouchEmail: "",
    getInTouchMessage: "",
    getInTouchDemoCheck: false,
    getInTouchSchedule: "",
    anchorElement: null,
    mobileDialog: false
  };

  carouselSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    prevArrow: <LandingPrevArrow/>,
    nextArrow: <LandingRightArrow/>
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    configureAnchors({ offset: -60 });

    const checkScrollTop = () => {
      if (!this.state.showScroll && window.pageYOffset > 3300) {
        this.setState({ showScroll: true });
      } else if (this.state && window.pageYOffset <= 3300) {
        this.setState({ showScroll: false });
      }
    };
    window.addEventListener("scroll", checkScrollTop);
  }

  handleOctoberEnterKey = (e) => {
    if(e.key === 'Enter' && !this.state.octoberDisabled) {
      this.handleGetNotifiedOctober();
    }
  }

  handleGetNotifiedOctober = () => {
    const formData = new FormData();

    formData.append("email", this.state.octoberEmail);
    formData.append("source", "get_notified_october");

    this.props.onLandingFormSubmit(formData);
  };

  validateEmail = (email) => {
    const re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    return re.test(String(email).toLowerCase());
  };

  scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  validateGetNotifiedOctober = () => {
    if (this.validateEmail(this.state.octoberEmail)) {
      this.setState({ octoberDisabled: false, octoberEmailError: "" });
    } else {
      this.setState({ octoberDisabled: true, octoberEmailError: "Please enter a valid email" });
    }
  };

  handleOctoberChange = (e) => {
    this.setState({ octoberEmail: e.target.value });
    this.validateGetNotifiedOctober();
  };

  validateScheduleDemo = values => {

    const errors = {};

    if(!this.validateEmail(values.email)) {
      errors.email = "Please enter a valid email";
    }

    if (!values.email) {
      errors.email = 'Required';
    }

    if (!values.date_avail) {
      errors.date_avail = 'Required';
    }

    if (!values.name) {
      errors.name = 'Required';
    }

    return errors;
  };

  validateGetInTouch = values => {
    const errors = {};
    if(!values) {return errors;}

    if(!this.validateEmail(values.email)) {
      errors.email = "Please enter a valid email";
    }

    if (!values.email) {
      errors.email = 'Required';
    }

    if (!values.message) {
      errors.message = 'Required';
    }

    if (!values.date_avail && this.state.getInTouchDemoCheck) {
      errors.date_avail = 'Required';
    }

    if (!values.name) {
      errors.name = 'Required';
    }

    return errors;

  };

  handleScheduleDemoSubmit = ({name, email, date_avail}) => {
    const formData = new FormData();

    formData.append("email", email);
    formData.append("name", name);
    formData.append("date_avail", date_avail);
    formData.append("demo_request", true);
    formData.append("source", "schedule_free_demo");

    this.props.onLandingFreeDemoSubmit(formData);
  };

  handleGetInTouchSubmit = ({name, email, date_avail,message}) => {
    const formData = new FormData();

    formData.append("email", email);
    formData.append("name", name);
    formData.append("date_avail", date_avail);
    formData.append("message", message);
    formData.append("demo_request", true);
    formData.append("source", "get_in_touch");

    this.props.onLandingGetInTouchSubmit(formData);
  };

  goToSignIn = (e) => {
    e.preventDefault();
    history.push('/signup');
  }

  handleClick = (event) => {
    event.preventDefault();
    if(this.state.anchorElement) {
      this.setState({anchorElement: null});
    } else {
      this.setState({anchorElement: event.currentTarget});
    }
  };

  handleClose = (e) => {
    this.setState({anchorElement: null});
  };

  submitSearchOrder = (values) => {
    const formData = new FormData();

    formData.append("search_order_number", values.search_order_number);
    formData.append("search_last_name", values.search_last_name);

    this.props.onLandingSearchOrder(formData);
  }

  validateSearchOrder = v => {
    const errors = {}
    if(!v.search_last_name) {
      errors.search_last_name = 'Required';
    }

    if(!v.search_order_number) {
      errors.search_order_number = 'Required';
    }

    return errors;
  }

  getFormObject = () => {
    return <Form
    onSubmit={this.submitSearchOrder}
    validate={this.validateSearchOrder}
    render={({ handleSubmit, form, submitting, pristine, values }) => (
      <form noValidate onSubmit={handleSubmit} style={{maxWidth: 250}}>
        <ValidationTextFieldRff
          style={{width: 250, marginBottom: 15}}
          variant="outlined"
          placeholder="Order Number"
          required={true}
          name="search_order_number"
          inputProps={{
            style: {textTransform: 'uppercase'},
            maxLength: 5,
          }}
          />
        <ValidationTextFieldRff
          variant="outlined"
          style={{width: 250}}
          placeholder="Last Name"
          required={true}
          name="search_last_name"
          inputProps={{
            style: {textTransform: 'uppercase'},
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton
                  onClick={handleSubmit}>
                  <ArrowRight></ArrowRight>
                </IconButton>
              </InputAdornment>
            ),
        }}></ValidationTextFieldRff>
      </form>)}
    />
  }

  handleMobileDialogOpen = () => {
    this.setState({mobileDialog:true});
  }

  handleMobileDialogClose = () => {
    this.setState({mobileDialog:false});
  }

  render() {
    return (
      <div>
        <MessengerCustomerChat pageId="100306071829881" appId=""/>
        <Dialog maxWidth={"sm"} open={this.state.mobileDialog} onClose={this.handleMobileDialogClose} aria-labelledby="form-dialog-title">
          <div style={{padding: 25, display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            {this.getFormObject()}
            {!!this.props.orderHashError && <p style={{color: 'rgb(215, 46,51)'}}><b>{this.props.orderHashError}</b></p>}
          </div>
        </Dialog>
        <Popper
          popperOptions={{
            modifiers: {
              offset: {
                  offset: '0,20',
              },
            },
          }}
          open={Boolean(this.state.anchorElement)}
          anchorEl={this.state.anchorElement}
          role={undefined} transition disablePortal>
            {({ TransitionProps }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: 'top'}}>
                <div className="search-order-landing">
                  {this.getFormObject()}
                  {!!this.props.orderHashError && <p style={{color: 'rgb(215, 46,51)'}}><b>{this.props.orderHashError}</b></p>}
                </div>
              </Grow>
            )}
        </Popper>
        <div className="landing-container shape-area-one">
          <IconButton
            className="scrollTop"
            style={{ display: this.state.showScroll ? "flex" : "none" }}
            onClick={this.scrollTop}>
            <ArrowUp style={{ height: 50, width: 50 }} />
          </IconButton>
          <div className="landing-header">
            <img
              src="/orderbasket_logo.png"
              alt="Logo"
              className="logo-img"
            ></img>
            <h1 className="logo-title">OrderBasket</h1>
            <div className="right-nav">
              <h4 style={{ margin: 0, marginRight: 23 }}>
                <a onClick={this.handleClick} href={'/'}>Track Order</a>
              </h4>
              <h4 style={{ margin: 0, marginRight: 23 }}>
                <a href="#features">Features</a>
              </h4>
              <h4 style={{ margin: 0, marginRight: 23 }}>
                <a href="#pricing">Pricing</a>
              </h4>
              <h4 style={{ margin: 0, marginRight: 23 }}>
                <a href="#contact us">Contact</a>
              </h4>
              <button className="confirm-btn" onClick={this.goToSignIn}>{this.props.isLoggedIn ? 'Dashboard' : 'Sign up' }</button>
            </div>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              style={{ marginLeft: "auto", paddingTop: 0, paddingBottom: 0 }}
              onClick={this.handleDrawerOpen}
              className="menu-btn"
            >
              <Menu />
            </IconButton>
            <Drawer variant="persistent" anchor="top" open={this.state.open}>
              <div>
                <IconButton onClick={this.handleDrawerClose}>
                  <ChevronUp></ChevronUp>
                </IconButton>
              </div>
              <Divider />
              <List>
                <ListItem button onClick={(e) => {
                  this.handleClick(e);
                  this.handleMobileDialogOpen(e);
                  }}>
                  <ListItemText primary={'Track Order'}/>
                </ListItem>
                {["Features", "Pricing", "Contact"].map((text, index) => (
                  <ListItem
                    button
                    key={text}
                    component="a"
                    href={`#${text.toLowerCase()}`}
                  >
                    <ListItemText primary={text} />
                  </ListItem>
                ))}
                <ListItem button
                  component="a"
                  href={'/login'}>
                  <ListItemText primary={this.props.isLoggedIn ? 'Dashboard' : 'Login'}/>
                </ListItem>
              </List>
            </Drawer>
          </div>

          <div className="content reverse" id="first-panel">
            <div className="left">
              <div>
                <div className="main-header">
                  Empower your
                </div>
                <br />
                <div className="main-header">
                  business today!
                </div>
              </div>
              <div className="subheader" style={{marginTop: 15}}>
                Share a personalized e-shop with your customers and easily
                manage your business all for&nbsp;
                <a
                  style={{ color: "#A357C6", textDecoration: "none" }}
                  href="#pricing"
                >
                  <b>FREE</b>
                </a>
              </div>

                <Link to="/signup" className="get-notified">
                  <h5>
                    Get Started!
                  </h5>
                </Link>
            </div>
            <div className="right">
              {/* <img
                id="phone-stall"
                src="/phone-stall.png"
                alt="landing-first"
              ></img> */}
              <video controls autoplay="true" muted playsinline="true" loop>
                <source src="https://orderbasket-media.s3.ap-southeast-1.amazonaws.com/OB+INTRO_FINAL.mp4" type="video/mp4"></source>
                Your browser does not support the video tag.
              </video>
              {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/BRK64ZUzVJI?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
            </div>
          </div>

          <ScrollableAnchor id={"features"}>
            <div className="section-header features">Features</div>
          </ScrollableAnchor>
          <div className="content reverse">
            <div className="left">
              <div className="left-header feature-header">Manage Your</div>
              <div className="left-header feature-header" style={{ marginBottom: 40 }}>
                Business
              </div>
              <div className="description-section">
                <div className="descriptor">Quick Look</div>
                Have an overview of your business and know what to do for the
                day from your phone or on the web
              </div>
              <div className="description-section">
                <div className="descriptor">Process Orders</div>
                View each order as they come in, plus mark and label them to
                suit your process
              </div>
              <div className="description-section">
                <div className="descriptor">Track Inventory</div>
                Sync your inventory levels and know which products have to be
                restocked real-time
              </div>
            </div>
            <div className="right">
              {/* <img src="/white_placeholder.png" alt="landing-first"></img> */}
              <video autoPlay loop playsInline muted>
                <source src="/manage-your-store.mov" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <div className="content">
            <div className="left">
              <video autoPlay loop playsInline muted>
                <source src="/set-up-shop.mov" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="right">
              <div style={{ marginBottom: 40 }}>
                <div className="left-header feature-header">
                  Set Up Shop
                </div>
                <div className="header-subtext">
                  Sample shop: <a href="https://orderbasket.app/shop/ufo-coffee">UFO Coffee</a>
                </div>
              </div>

              <div className="description-section">
                <div className="descriptor">Quick & Easy</div>
                Have a professional e-shop up to share on your on your social
                media pages in just a few minutes
              </div>
              <div className="description-section">
                <div className="descriptor">Your Brand In Mind</div>
                Have control over all the content, shop information, payment
                options, and more
              </div>
              <div className="description-section">
                <div className="descriptor">Share it</div>
                Funnel your orders to the store by posting a short link on your
                social media pages
              </div>
            </div>
          </div>
          <div className="content reverse">
            <div className="left">
              <div className="left-header feature-header">Communication</div>
              <div className="left-header feature-header" style={{ marginBottom: 40 }}>
                Made Easy
              </div>
              <div className="description-section">
                <div className="descriptor">Receive Orders</div>
                Get a notification for every order that comes in and view it
                from anywhere
              </div>
              <div className="description-section">
                <div className="descriptor">Send Automated Emails</div>
                Upon payment and order confirmation, a notification will
                automatically be sent
              </div>
              <div className="description-section">
                <div className="descriptor">Super Secure</div>
                Rest assured that all sensitive information are encrypted and
                will not be shared with anyone
              </div>
            </div>
            <div className="right">
              <video autoPlay loop playsInline muted>
                <source src="/comms-made-easy.mov" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
        <div className="testimonial-carousel">
          <div class="header">
            User Stories from Merchants
          </div>
          <Slider {...this.carouselSettings}>
            <div className="carousel-item">
              <div className="carousel-item2">
                <img src="/Testimonials/OD CASEFY.png" alt="casefy testimonial"/>
                <div className="testimonial-container">
                  <div className="testimonial-name">Alyssa Villanueva</div>
                  <div className="testimonial-text">
                    Founder of <b>
                      <a href="https://orderbasket.app/shop/casefy-ph" rel="noopener noreferrer" target="_blank">Casefy.ph</a>
                    </b>
                  </div>
                  <br/>
                  <div className="testimonial-text">
                    "Having a website like OrderBasket means customers are always able to find us anytime, anywhere, even outside of business hours.
                    <br/>
                    <br/>
                    OrderBasket offers the user convenience as they can access the information they need in the easiest way. It also provides a quick and easy way of communicating information between the buyers and sellers. Lastly, OrderBasket is very open to our opinions and always ready to answer our questions to improve our website."
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="carousel-item2">
                <img src="/Testimonials/OD TESTIMONIAL- FBV.png" alt="fbv testimonial"/>
                <div className="testimonial-container">
                  <div className="testimonial-name">Erika Jacinto</div>
                    <div className="testimonial-text">
                      Founder of <b>
                        <a href="https://orderbasket.app/shop/frozenberriesandveggies" rel="noopener noreferrer" target="_blank">Frozen Berries and Veggies</a>
                      </b>
                    </div>
                    <br/>
                    <div className="testimonial-text">
                      "OrderBasket helps small business owners like me to present my products to my customers in an easy, detailed and hassle-free manner! Even if I'm away, details of every order are compiled with complete information, so it reduces time and effort both for me and my loyal patrons. OrderBasket is the personification of convenience indeed!
                      <br/>
                      <br/>
                      Now, it has a new feature such as promos and discounts for every customer to enjoy. Selling and Shopping have never been so easy since OrderBasket came!"
                    </div>
                  </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="carousel-item2">
                <img src="/Testimonials/OD TESTIMONIAL- Home Table Deli.png" alt="htd testimonial"/>
                <div className="testimonial-container">
                  <div className="testimonial-name">Tia de Guzman</div>
                  <div className="testimonial-text">
                    Founder of <b>
                      <a href="https://orderbasket.app/shop/hometabledeli" rel="noopener noreferrer" target="_blank">Home Table Deli</a>
                      </b>
                    </div>
                    <br/>
                    <div className="testimonial-text">
                      "As a start up, I wanted a website that is easy to use both for the customer and as a merchant - that is exactly what OrderBasket gave me. 
                      <br/>
                      <br/>
                      The interface is very straightforward, and you can manage the store by yourself. It’s also convenient for my customers that promos and shipping fees are integrated upon check out. 
                      <br/>
                      <br/>
                      I look forward to all the other features they will develop in the future."
                    </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>

        {/* <div className="schedule-free-demo">
          <div className="header">Schedule a Free Demo</div>
          Learn more about how your business can use OrderBasket

          <Form
            onSubmit={this.handleScheduleDemoSubmit}
            validate={this.validateScheduleDemo}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form noValidate onSubmit={handleSubmit}>
            <div className="form-input-container left">
              <ValidationTextFieldRff
                variant="outlined"
                label="Your name"
                name="name"
                // onChange={this.handleScheduleDemoNameChange}
              ></ValidationTextFieldRff>
            </div>
            <div className="form-input-container">
              <ValidationTextFieldRff
                variant="outlined"
                label="Your email"
                name="email"
                // onChange={this.handleScheduleDemoEmailChange}
                // error={this.state.scheduleDemoEmailError !== ""}
                // label={this.state.scheduleDemoEmailError ? this.state.scheduleDemoEmailError : "Your email"}
              ></ValidationTextFieldRff>
            </div>
            <div className="form-input-container" style={{ width: "100%" }}>
              <ValidationTextFieldRff
                variant="outlined"
                label="Your available dates"
                name="date_avail"
                // onChange={this.handleScheduleDemoScheduleChange}
              ></ValidationTextFieldRff>
            </div>
            <div className="confirm-btn-container">
              {!this.props.loadingScheduleFreeDemo &&
                !this.props.landingScheduleFreeDemo && (
                  <input
                    type="submit"
                    className="confirm-btn"
                    value="Send"
                    style={{ width: 195, marginTop: 56 }}
                  ></input>
                )}
              {this.props.loadingScheduleFreeDemo &&
                !this.props.landingScheduleFreeDemo && (
                  <OBLoader style={{ marginTop: 30 }} />
                )}
            </div>
            {!this.props.loadingScheduleFreeDemo &&
              this.props.landingScheduleFreeDemo && (
                <div className="thankyou">
                  <div className="header">Thank you for your message!</div>
                  We will coordinate with you via email
                </div>
              )}
          </form>
            )}>

            </Form>

        </div> */}
        <div className="logos-container">
          <div className="section-header">
            Trusted by
          </div>
          <div className="logos">
            <a target="_blank" rel="noreferrer noopener" href="https://orderbasket.app/shop/puzzleandstitchph"><img src="https://orderbasket-media.s3.ap-southeast-1.amazonaws.com/75+Products+(custom)_Puzzle+and+Stitch.jpeg" alt="puzzles-stich"></img></a>
            <a target="_blank" rel="noreferrer noopener" href="https://orderbasket.app/shop/aglowph"><img src="https://orderbasket-media.s3.ap-southeast-1.amazonaws.com/B7494A19-F0F0-401D-907D-63C6B675CA37.jpeg" alt="aglow"></img></a>
            <a target="_blank" rel="noreferrer noopener" href="https://orderbasket.app/shop/1940ph"><img src="https://orderbasket-media.s3.ap-southeast-1.amazonaws.com/Free_1940+PH.png" alt="1940"></img></a>
            <a target="_blank" rel="noreferrer noopener" href="https://orderbasket.app/shop/florentis-by-scents-n-etcetera"><img src="https://orderbasket-media.s3.ap-southeast-1.amazonaws.com/Free_Florenties.png" alt="Free_Florenties"></img></a>
            <a target="_blank" rel="noreferrer noopener" href="https://orderbasket.app/shop/heybiblioph"><img src="https://orderbasket-media.s3.ap-southeast-1.amazonaws.com/Free_Hey+Biblio.png" alt="Hey biblio"></img></a>
            <a target="_blank" rel="noreferrer noopener" href="https://orderbasket.app/shop/mrs-goodshoppe"><img src="https://orderbasket-media.s3.ap-southeast-1.amazonaws.com/Free_Mrs.+Goodshoppe.jpeg" alt="GoodShoppe"></img></a>
            <a target="_blank" rel="noreferrer noopener" href="https://orderbasket.app/shop/solstone"><img src="https://orderbasket-media.s3.ap-southeast-1.amazonaws.com/Free_Sol+Stone+3.png" alt="SolStone"></img></a>
            <a target="_blank" rel="noreferrer noopener" href="https://orderbasket.app/shop/suresafeph"><img src="https://orderbasket-media.s3.ap-southeast-1.amazonaws.com/Free_Sure+Safe+Solutions.png" alt="SureSafe"></img></a>
            <a target="_blank" rel="noreferrer noopener" href="https://orderbasket.app/shop/vimbymm"><img src="https://orderbasket-media.s3.ap-southeast-1.amazonaws.com/Free_Vim+by+May+_+Marie.png" alt="Vibymm"></img></a>
            <a target="_blank" rel="noreferrer noopener" href="https://orderbasket.app/shop/ranescoldbrew"><img src="https://orderbasket-media.s3.ap-southeast-1.amazonaws.com/RCB_Logo_Final.png" alt="Ranes Cold Brew"></img></a>
            <a target="_blank" rel="noreferrer noopener" href="https://orderbasket.app/shop/a-berries"><img src="https://orderbasket-media.s3.ap-southeast-1.amazonaws.com/Standard_A+Berries.png" alt="A Berries"></img></a>
            {/* <a target="_blank" rel="noreferrer noopener" href="https://orderbasket.app/shop/a-berries"><img src="https://orderbasket-media.s3.ap-southeast-1.amazonaws.com/Standard_A+Berries.png" alt="A Berries"></img></a> */}
            <a target="_blank" rel="noreferrer noopener" href="https://orderbasket.app/shop/frozenberriesandveggies"><img src="https://orderbasket-media.s3.ap-southeast-1.amazonaws.com/Standard_Berries+and+Veggies.jpeg" alt="Berries and Veggies"></img></a>
            <a target="_blank" rel="noreferrer noopener" href="https://orderbasket.app/shop/hometabledeli"><img src="https://orderbasket-media.s3.ap-southeast-1.amazonaws.com/Standard_Home+Table+Deli.png" alt="Home Table Deli"></img></a>
            <a target="_blank" rel="noreferrer noopener" href="https://orderbasket.app/shop/papeteriebox"><img src="https://orderbasket-media.s3.ap-southeast-1.amazonaws.com/Standard_Papeterie+Box.png" alt="Papeterie Box"></img></a>
            <a target="_blank" rel="noreferrer noopener" href="https://orderbasket.app/shop/saliwph"><img src="https://orderbasket-media.s3.ap-southeast-1.amazonaws.com/Standard_Saliw.png" alt="Saliw.ph"></img></a>
            {/* <a target="_blank" rel="noreferrer noopener" href="https://orderbasket.app/shop/casefy-ph"><img src="https://orderbasket-media.s3.ap-southeast-1.amazonaws.com/Unli-products+(custom)_casefy.ph.jpeg" alt="Casefy"></img></a> */}
            <a target="_blank" rel="noreferrer noopener" href="https://orderbasket.app/shop/casefy-ph"><img src="/Testimonials/casefy.png" alt="Casefy"></img></a>
          </div>
        <div className="many-more">and <span className="number">300+</span> other merchants!</div>


        </div>

        <div className="landing-container shape-area-two">
          <ScrollableAnchor id={"pricing"}>
            <div className="section-header" style={{ marginBottom: 80 }}>
              Pricing Plans
            </div>
          </ScrollableAnchor>
          <div className="pricing-plan-container">
            <div className="pricing-section">
              <div className="pricing-plan-title">Free Forever</div>
              <div className="pricing-plan-entry">
                <Check></Check>Online shop website
              </div>
              <div className="pricing-plan-entry">
                <Check></Check>Order management
              </div>
              <div className="pricing-plan-entry">
                <Check></Check>Stock tracking
              </div>
              <div className="pricing-plan-entry">
                <Check></Check>Automated email notifications
              </div>
              <div className="pricing-plan-entry">
                <Check></Check>Lite marketing support
              </div>
              <div className="pricing-plan-entry center">
                <b>Up to...</b>
              </div>
              <div className="pricing-plan-entry">
                <Check></Check>1 account manager
              </div>
              <div className="pricing-plan-entry">
                <Check></Check>8 product listings
              </div>
              <div className="pricing-plan-entry">
                <Check></Check>3 variations per product
              </div>
              <div className="pricing-plan-entry">
                <Check></Check>1 variation attribute
              </div>
              <div className="pricing-plan-entry">
                <Check></Check>50 orders per month
              </div>
              <div className="footer">
                Perfect for&nbsp;
                <b>small businesses and social sellers</b> who are just starting
                out
              </div>
            </div>
            <div className="pricing-section middle">
              <div className="pricing-plan-title">
                Standard
                <div style={{ marginLeft: "auto", fontSize: 16 }}>
                  ₱ 499
                  <br />
                  <div style={{ fontSize: 11, textAlign: "center" }}>
                    /month
                  </div>
                </div>
              </div>
              <div className="pricing-plan-entry">
                <Check></Check>Online shop website
              </div>
              <div className="pricing-plan-entry">
                <Check></Check>Order management
              </div>
              <div className="pricing-plan-entry">
                <Check></Check>Stock tracking
              </div>
              <div className="pricing-plan-entry">
                <Check></Check>Automated email notifications
              </div>
              <div className="pricing-plan-entry">
                <SparkleSmall></SparkleSmall>Pro marketing support
              </div>
              <div className="pricing-plan-entry center">
                <b>Up to...</b>
              </div>
              <div className="pricing-plan-entry">
                <SparkleSmall></SparkleSmall>3 account managers
              </div>
              <div className="pricing-plan-entry">
                <SparkleSmall></SparkleSmall>30 product listings
              </div>
              <div className="pricing-plan-entry">
                <SparkleSmall></SparkleSmall>20 variations per product
              </div>
              <div className="pricing-plan-entry">
                <SparkleSmall></SparkleSmall>2 variation attributes
              </div>
              <div className="pricing-plan-entry">
                <SparkleSmall></SparkleSmall>350 orders per month
              </div>

              <div className="footer">
                Perfect for&nbsp;
                <b>more established businesses</b> with regular customers
              </div>
            </div>
            <div className="pricing-section">
              <div className="pricing-plan-title">
                <Sparkle style={{ marginRight: 12 }}></Sparkle>Custom Plan
                <div style={{ marginLeft: "auto", fontSize: 16 }}>
                  ₱ _ _ _
                </div>
              </div>
              <div className="pricing-plan-entry">
                <Check></Check>Online shop website
              </div>
              <div className="pricing-plan-entry">
                <Check></Check>Order management
              </div>
              <div className="pricing-plan-entry">
                <Check></Check>Stock tracking
              </div>
              <div className="pricing-plan-entry">
                <Check></Check>Automated email notifications
              </div>
              <div className="pricing-plan-entry">
                <SparkleSmall></SparkleSmall>Pro marketing support
              </div>
              <div className="pricing-plan-entry center">
                <b>Up to...</b>
              </div>
              <div className="pricing-plan-entry">
              <SparkleSmall></SparkleSmall>Unlimited account managers
              </div>
              <div className="pricing-plan-entry">
                <SparkleSmall></SparkleSmall>Unlimited product listings
              </div>
              <div className="pricing-plan-entry">
                <SparkleSmall></SparkleSmall>Unlimited product variations
              </div>
              <div className="pricing-plan-entry">
                <SparkleSmall></SparkleSmall>2 variation attributes
              </div>
              <div className="pricing-plan-entry">
                <SparkleSmall></SparkleSmall>Unlimited orders
              </div>
              <div className="footer">
                Let us adjust to whatever your business needs, might they be&nbsp;<b>big or small</b>
              </div>
            </div>
          </div>
          <div className="section-header use-orderbasket">
            Use OrderBasket for&nbsp;
            <span style={{ color: "#A357C6" }}>FREE</span> for as long as you
            need, and only upgrade as your business grows!
          </div>
        </div>

        <div className="schedule-free-demo">
          <ScrollableAnchor id={"contact us"}>
            <div className="header">Get in touch</div>
          </ScrollableAnchor>
          <div style={{ marginBottom: 56 }}>
            Expect a reply from us within a day
          </div>

          <Form
            onSubmit={this.handleGetInTouchSubmit}
            validate={this.validateGetInTouch}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className="form-input-container left">
              <ValidationTextFieldRff
                variant="outlined"
                label="Your name"
                // onChange={this.handlegetInTouchNameChange}
                name="name"
              ></ValidationTextFieldRff>
            </div>
            <div className="form-input-container">
              <ValidationTextFieldRff
                variant="outlined"
                // error={this.state.getInTouchEmailError !== ""}
                // label={this.state.getInTouchEmailError ? this.state.getInTouchEmailError : "Your email"}
                // onChange={this.handlegetInTouchEmailChange}
                label="Your email"
                name="email"
              ></ValidationTextFieldRff>
            </div>
            <div
              style={{ flexBasis: "100%", marginTop: 18, marginBottom: 48 }}
              className="form-input-container"
            >
              <ValidationTextFieldRff
                variant="outlined"
                label="Your message"
                name="message"
                // onChange={this.handlegetInTouchMessageChange}
                multiline
                rows={4}
                rowsmax={4}
              ></ValidationTextFieldRff>
            </div>
            <div className="one-column">
              <div className="form-input-container">
                <FormControlLabel
                  style={{ color: "#000000C6" }}
                  control={
                    <ObCheckbox
                      value={this.state.getInTouchDemoCheck}
                      onChange={(e) => {
                        this.setState({
                          getInTouchDemoCheck: e.target.checked,
                        });
                        this.validateGetInTouch();
                      }}
                    />
                  }
                  label="Schedule a free demo with us"
                />
              </div>

              {this.state.getInTouchDemoCheck && (
                <div className="form-input-container">
                  <ValidationTextFieldRff
                    variant="outlined"
                    label="Your available dates"
                    name="date_avail"
                    // onChange={this.handlegetInTouchScheduleChange}
                  ></ValidationTextFieldRff>
                </div>
              )}

              <div className="confirm-btn-container">
                {!this.props.loadingGetInTouchDemo &&
                  !this.props.landingGetInTouchDemo && (
                    <input
                      type="submit"
                      className="confirm-btn"
                      value="Send"
                      style={{ width: 195, marginTop: 56 }}
                    ></input>
                  )}
                {this.props.loadingGetInTouchDemo &&
                  !this.props.landingGetInTouchDemo && <OBLoader></OBLoader>}
              </div>
            </div>
            {!this.props.loadingGetInTouchDemo &&
              this.props.landingGetInTouchDemo && (
                <div className="thankyou">
                  <div className="header">Thank you for your message!</div>
                  We will coordinate with you via email
                </div>
              )}
          </form>)}></Form>
        </div>

        <div className="landing-footer">
          <div className="icons">
            <a
              href="https://www.facebook.com/orderbasketapp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/Icons/facebook-landing.svg" alt="facebook"></img>
            </a>
            <a
              href="https://www.instagram.com/orderbasketapp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/Icons/instagram-landing.svg"
                alt="insta"
                style={{ marginLeft: 10 }}
              ></img>
            </a>
          </div>
          <div className="footer-header">OrderBasket</div>
          <div className="footer-text">
            Philippines. All rights reserved. 2021
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loadingOctober: state.landing.loadingOctober,
    landingOctoberSuccess: state.landing.landingOctoberSuccess,
    loadingScheduleFreeDemo: state.landing.loadingScheduleFreeDemo,
    landingScheduleFreeDemo: state.landing.landingScheduleFreeDemo,
    loadingGetInTouchDemo: state.landing.loadingGetInTouchDemo,
    landingGetInTouchDemo: state.landing.landingGetInTouchDemo,
    orderHash: state.landing.orderHash,
    orderHashError: state.landing.orderHashError,
    isLoggedIn: state.session.isLoggedIn,
    session: state.session.session
  };
};

const mapDispatchToProps = {
  onLandingFormSubmit,
  onLandingFreeDemoSubmit,
  onLandingGetInTouchSubmit,
  onLandingSearchOrder,

};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);

import React from "react";
import { connect } from 'react-redux';
import { wizardGoToStep } from '../../../../actions/wizardActions';
import { Link } from "react-router-dom";
import '../../PostOrder/OrderSummary/OrderSummary.scss';

import {
  Typography,
} from '@material-ui/core';
class OrderSummary extends React.Component {
  defaultProductImage = 'https://orderbasket-staging.s3-ap-southeast-1.amazonaws.com/default-product.png';

  state = {
    promotionDetails: { type: 'none' }
  }

  componentDidMount(){
    if(localStorage.getItem('promo-details')){
      this.setState({
        promotionDetails: JSON.parse(localStorage.getItem('promo-details'))
      })
    } else {
      this.setState({
        promotionDetails: { type: 'none' }
      })
    }
  }

  numberWithCommas(value) {
    return value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getCartTotal = (cartItems, form, addShipping = false) => {
    const arrOfPrices = cartItems.map(ci => ci.quantity * ci.product.price);
    let sum = 0;
    arrOfPrices.forEach(element => {
      sum += element;
    });

    if(addShipping && form.delivery_location && parseFloat(this.getDeliveryFee(form.delivery_location))) {
      sum += parseFloat(this.getDeliveryFee(form.delivery_location));
    }

    return sum;
  }

  getProductName = (product) => {
    return `${product.product_name}${product.variant1_value ? ", " + product.variant1_value : ''}${product.variant2_value ? ", " + product.variant2_value : ''}`
  }

  getCurrentCart = () => {
    if(this.props.merchantCarts && this.props.merchant_store) {
      const merchantCart = this.props.merchantCarts.filter(m => m.id === this.props.merchant_store.id);
      return merchantCart[0];
    }
  }

  getCartItemProduct = (product, parentProduct = null) => {
    let photoUrl = product.photo.url;

    if(!photoUrl && !!parentProduct) {
      photoUrl = parentProduct.photo.url;
    }

    return photoUrl || this.defaultProductImage;
  }

  getCartDiv = (cartItems) => {
    const items = [];
    cartItems.forEach((ci, idx) => {
      items.push(<div key={`product_${idx}`} className="product-image-container">
        <img src={this.getCartItemProduct(ci.product, ci.parentProduct)} alt='product-img'></img>
      </div>);
      items.push(<div key={`product_${idx}`}>{this.getProductName(ci.product)}</div>);
      items.push(<div key={`quantity${idx}`} style={{justifyContent: 'flex-end'}}>x{ci.quantity}</div>);

      if(this.getDiscountedPrice(ci)) {
        items.push(<div key={`price_${idx}`} style={{justifyContent: 'flex-end'}}>
          <strike>{ci.quantity * ci.product.price}</strike>
          <div>&nbsp;&nbsp;{parseFloat(this.getDiscountedPrice(ci))}</div>
        </div>);
      } else {
        items.push(<div key={`price_${idx}`} style={{justifyContent: 'flex-end'}}>{ci.quantity * ci.product.price}</div>);
      }
    });
    return items;
  }

  getDiscountedPrice = (ci) => {
    if(this.state.promotionDetails.type === 'none' || this.state.promotionDetails.success === false) {
      return false;
    }

    const cart = this.state.promotionDetails.cart;
    if(!cart) {
      
      return false;
    }

    const discounted = cart.find(x => x.id === ci.product.id);

    if(discounted){
      if(discounted.discounted === true) {
        return discounted.new_total_price;
      }
    } else {
      return false;
    }
  }

  getDeliveryFee = (location) => {

    if(!location || this.props.form.purchase_type !== 'Delivery') {
      return -1;
    }

    const a = this.props.merchant_store.fixed_delivery_fee;

    const _location = a.find( e => e.location === location);
    if(_location) {
      return _location.fee;
    } else {
      return -1;
    }
  }

  computeEachDiscountTotal() {
    let discountTotal = 0;
    discountTotal = parseFloat(this.state.promotionDetails.old_cart_total) - parseFloat(this.state.promotionDetails.new_cart_total)

    return this.numberWithCommas(discountTotal)
  }

  renderTotalSection(){
    let cartItems = [];
    if(this.getCurrentCart()) {
      cartItems = this.getCurrentCart().cartItems;
    }
    return(
      <div style={{width: '100%', display: 'flex', flexDirection: 'column', marginTop: 20, borderTop: '1px solid #ececec'}}>
        {
          this.state.promotionDetails.type === 'none' && this.props.form.purchase_type === 'Delivery' && !!this.props.form.delivery_location && parseFloat(this.getDeliveryFee(this.props.form.delivery_location)) > -1 &&
          <div style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: 15
          }}>
            <div style={{fontWeight: '600'}}>
              Shipping fee ({this.props.form.delivery_location})
            </div>
            <span style={{fontWeight: '600'}}>
            {parseFloat(this.getDeliveryFee(this.props.form.delivery_location)) === 0 ? '-' : `₱ ${parseFloat(this.getDeliveryFee(this.props.form.delivery_location))}`}
            </span>
          </div>
        }
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 15
        }}>
          <div style={{fontWeight: '600'}}>
            Grand Total
          </div>
          {
            this.getDeliveryFee(this.props.form.delivery_location) > -1 ?
              <span style={{fontWeight: '600'}}>
                  ₱ {this.numberWithCommas(this.getCartTotal(cartItems) + parseFloat(this.getDeliveryFee(this.props.form.delivery_location)))}
              </span>
            :
              <span style={{fontWeight: '600'}}>
                  ₱ {this.numberWithCommas(this.getCartTotal(cartItems))}
              </span>
          }
        </div>
      </div>
    )
  }

  renderTotalSectionDiscount(){
    let cartItems = [];
    if(this.getCurrentCart()) {
      cartItems = this.getCurrentCart().cartItems;
    }
    return(
      <div style={{width: '100%', display: 'flex', flexDirection: 'column', marginTop: 20, borderTop: '1px solid #ececec',}}>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 15
        }}>
          <div>
            Subtotal
          </div>
          <span>
              ₱ {this.numberWithCommas(this.getCartTotal(cartItems))}
          </span>
        </div>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 15
        }}>
          <div>
            Discount
          </div>
            {
                this.state.promotionDetails.type === 'free_shipping' && this.state.promotionDetails.apply_discount_to !== 'each' &&
                <span>
                {'Free Shipping'}
                </span>
            }
            {
                this.state.promotionDetails.type === 'percent' && this.state.promotionDetails.apply_discount_to !== 'each' &&
                <span>
                   - {parseFloat(this.state.promotionDetails.value) * 100 + '% off'}
                </span>
            }
            {
                this.state.promotionDetails.type === 'amount' && this.state.promotionDetails.apply_discount_to !== 'each' &&
                <span>
                   - ₱ {this.numberWithCommas(parseInt(this.state.promotionDetails.value))}
                </span>
            }
            {
              this.state.promotionDetails.apply_discount_to === 'each' &&
              <span>
                - ₱ {this.computeEachDiscountTotal()}
              </span>
            }
        </div>
        {
          this.state.promotionDetails.type !== 'free_shipping' && this.props.form.purchase_type === 'Delivery' && !!this.props.form.delivery_location && parseFloat(this.getDeliveryFee(this.props.form.delivery_location)) > -1 &&
          <div style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: 15
          }}>
            <div style={{fontWeight: '600'}}>
              Shipping fee ({this.props.form.delivery_location})
            </div>
            <span style={{fontWeight: '600'}}>
            {parseFloat(this.getDeliveryFee(this.props.form.delivery_location)) === 0 ? '-' : `₱ ${parseFloat(this.getDeliveryFee(this.props.form.delivery_location))}`}
            </span>
          </div>
        }
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 15
        }}>
          <div style={{fontWeight: '600'}}>
             Grand Total
          </div>
          {
              this.state.promotionDetails.type !== 'free_shipping' && this.getDeliveryFee(this.props.form.delivery_location) > -1 ?
              <span style={{fontWeight: '600'}}>
                  ₱ {this.numberWithCommas(parseFloat(this.state.promotionDetails.new_cart_total) + parseFloat(this.getDeliveryFee(this.props.form.delivery_location)))}
              </span>
            : this.state.promotionDetails.type === 'free_shipping' && this.state.promotionDetails.type !== 'none' ?
              <span style={{fontWeight: '600'}}>
                  ₱ {this.numberWithCommas(parseFloat(this.state.promotionDetails.new_cart_total))}
              </span>
            :
              this.state.promotionDetails.type !== 'free_shipping' && this.state.promotionDetails.type !== 'none' &&
              <span style={{fontWeight: '600'}}>
                  ₱ {this.numberWithCommas(parseFloat(this.state.promotionDetails.new_cart_total))}
              </span>
          }
        </div>
      </div>
    )
  }

  render() {
    let cartItems = [];
    if(this.getCurrentCart()) {
      cartItems = this.getCurrentCart().cartItems;
    }

    const {form} = this.props;
    const {id} = this.props.merchant_store;

    console.debug(this.state.promotionDetails)

    return (
      <div className="cf-order-wizard-customer-summary-from-wizard cf-order-wizard-content">
        <div className="cf-order-wizard-customer-summary-row basket">
          <b>My Basket</b>
          {/* <span className="back-arrow" onClick={() => {this.props.wizardGoToStep(0,id)}}>Edit</span> */}
          <div className="cf-order-wizard-customer-summary-item-summary-element">
            {this.getCartDiv(cartItems)}
          </div>
          {/* {
            form.purchase_type === 'Delivery' && !!form.delivery_location && parseFloat(this.getDeliveryFee(form.delivery_location)) > -1 && <div className="subtotal">
            <div><span>Subtotal: </span>
              <span>₱ {this.getCartTotal(cartItems,form)} </span>
              </div>
              <div><span>Shipping fee ({form.delivery_location}) </span>
              <span>{parseFloat(this.getDeliveryFee(form.delivery_location)) === 0 ? '-' : `₱ ${parseFloat(this.getDeliveryFee(form.delivery_location))}` }</span>
              </div>
            </div>
          }
          <div className="total">
            <span>Grand Total: </span>
            <span>₱ {form.purchase_type === 'Delivery' && !!form.delivery_location && parseFloat(this.getDeliveryFee(form.delivery_location)) > -1 ? this.getCartTotal(cartItems, form, true) : this.getCartTotal(cartItems, form)}</span>
          </div> */}
          {
            this.state.promotionDetails && this.state.promotionDetails.promo_code ? this.renderTotalSectionDiscount() : this.renderTotalSection()
          }
        </div>

        <div className="cf-order-wizard-customer-summary-row">
          <b>Promo</b>
          <div className="cf-order-wizard-customer-summary-sub-row">
            {
                this.state.promotionDetails && this.state.promotionDetails.type !== 'none' ?
                  this.state.promotionDetails && this.state.promotionDetails.type === 'free_shipping' ?
                  <Typography variant="body1">
                    {this.state.promotionDetails.promo_name + ', Code: ' + this.state.promotionDetails.promo_code }<b>{', Free Shipping'}</b>
                  </Typography>
                  : this.state.promotionDetails && this.state.promotionDetails.type === 'amount' ?
                  <Typography variant="body1">
                    {this.state.promotionDetails.promo_name + ', Code: ' + this.state.promotionDetails.promo_code }<b>{', ₱' + parseFloat(this.state.promotionDetails.value) + ' off' }</b>{' on '}{this.state.promotionDetails.apply_discount_to === 'each' ? <b>{'selected item/s'}</b> : <b>{'entire order'}</b>}
                  </Typography>
                  : this.state.promotionDetails && this.state.promotionDetails.type === 'percent' &&
                  <Typography variant="body1">
                    {this.state.promotionDetails.promo_name + ', Code: ' + this.state.promotionDetails.promo_code }{', '}<b>{parseFloat(this.state.promotionDetails.value) * 100 + '% off' }</b>{' on '}{this.state.promotionDetails.apply_discount_to === 'each' ? <b>{'selected item/s'}</b> : <b>{'entire order'}</b>}
                  </Typography>
              :
              <span>None</span>
            }
          </div>
        </div>

        <div className="cf-order-wizard-customer-summary-row">
          <b>Special Instruction</b>
          {/* <span className="back-arrow" onClick={() => {this.props.wizardGoToStep(0,id)}}>Edit</span> */}
          <div className="cf-order-wizard-customer-summary-sub-row">
            {form.special_instruction ? form.special_instruction : <span>None</span>}
          </div>
        </div>

        <div className="cf-order-wizard-customer-summary-row">
          <b>Shipping</b>
          {/* <span className="back-arrow"  onClick={() => {this.props.wizardGoToStep(1,id)}}>Edit</span> */}
          <div className="cf-order-wizard-customer-summary-sub-row">
            {form.purchase_type === 'Delivery' && 'Ship item to me'}
            {form.purchase_type === 'Book' && 'I will book courier'}
            {form.purchase_type === 'Pick-up' && 'I will pick up item'}
          </div>
          {
            form.purchase_type === 'Delivery' && <div className="cf-order-wizard-customer-summary-sub-row">
              {this.getDeliveryAddress(form)}
          </div>
          }
        </div>

        <div className="cf-order-wizard-customer-summary-row">
          <b>Mode of Payment</b>
          {/* <span className="back-arrow" onClick={() => {this.props.wizardGoToStep(1,id)}}>Edit</span> */}
          <div className="cf-order-wizard-customer-summary-sub-row">
            {form.mode_of_payment === 'Cash' && 'Cash'}
            {form.mode_of_payment === 'Online' && 'Online'}
          </div>
        </div>

        <div className="cf-order-wizard-customer-summary-row">
          <b>Customer Info</b>
          {/* <span className="back-arrow"  onClick={() => {this.props.wizardGoToStep(2,id)}}>Edit</span> */}
          <div className="cf-order-wizard-customer-summary-sub-row">
            {form.order_first_name + ' ' + form.order_last_name}
          </div>
          <div className="cf-order-wizard-customer-summary-sub-row">
            {form.cellphone_number}
          </div>
          <div className="cf-order-wizard-customer-summary-sub-row">
            {form.email}
          </div>

          {  !!form.insta_handle && 
            <div className="cf-order-wizard-customer-summary-sub-row">
              Instagram - {form.insta_handle}
            </div>
          }
          { !!form.fb_handle && 
            <div className="cf-order-wizard-customer-summary-sub-row">
              Facebook - {form.fb_handle}
            </div>
          }
          
        </div>
      </div>
    );
  }

  getDeliveryAddress = (form) => {
    return (<div>
      <div>{`${form.house_number} ${form.street}`}</div>
      {!!form.building && <div>{form.building}</div>}
      {!!form.subdivision && <div>{form.subdivision}</div>}
      <div>{`${form.barangay} ${form.city}`}</div>
      <div>{`${form.province} ${!form.zip ? '' : form.zip}`}</div>
    </div>);
  }
}

const mapStateToProps = (state) => {
  return {
    merchant_store: state.customer.merchant_store,
    merchantCarts: state.cart.merchantCarts,
    form: state.wizard.form
  }
}

const mapDispatchToProps = {
  wizardGoToStep
}

export default connect(mapStateToProps,mapDispatchToProps)(OrderSummary);
const initialState = {loading: false, product_sections: [], error: null};

export default function productSectionsReducer(state = initialState, action) {
  switch(action.type) {
    case 'LOAD_PRODUCT_SECTION_STARTED':
      return {...state, loading: true, error: null};
    case 'ADD_PRODUCT_SECTION_STARTED':
      return {...state, loading: true, error: null};
    case 'LOAD_PRODUCT_SECTION_COMPLETED':
      return {...state, product_sections: action.payload.product_sections, loading: false, error: null};
    case 'ADD_PRODUCT_SECTION_ERROR':
      return {...state, loading: false, error: action.payload.error_msg};
    case 'CLEAR_PRODUCT_SECTION_ERROR':
      return {...state, loading: false, error: null};
    case 'DELETE_PRODUCT_SECTION_STARTED':
      return {...state, loading: true};
    case 'DELETE_PRODUCT_SECTION_COMPLETED':
      return {...state, loading: false, error: null};
    case 'DELETE_PRODUCT_SECTION_ERROR':
      return {...state, loading: false, error: action.payload.error_msg};
    default:
      return state;
  }
}
import { toast } from "react-toastify";
import { history } from "..";
import { readObject, saveObject } from "../libs/localstorage";

const initialState = {
  isLoggedIn: false,
  session: null,
  error: null,
  signupState: null
};


export default function session(state = initialState, action) {
  switch(action.type) {
    case 'SIGNUP_STATE_CHECK':
      let signupState = readObject('signupState');

      if(!signupState && action.payload === 'signup') {
        signupState = {page: action.payload};
        saveObject(signupState, "signupState");
      }

      return {...state, signupState:signupState};
    case 'SESSION_CHECK':
      return {...state, session: action.payload.session, isLoggedIn: action.payload.isLoggedIn};
    case 'SESSION_LOGIN':
      return {...state, isLoggedIn: true, session: action.payload.session};
    case 'SESSION_LOGIN_STATE_ONLY':
      return {...state, isLoggedIn: action.payload };
    case 'SESSION_LOGOUT':
      return {...state, isLoggedIn: false, session: null};
    case 'SESSION_LOGIN_FAILED':
      return {...state, error: action.payload.error};
    case 'SESSION_CLEAR_ERROR':
      return {...state, error: null};
    default:
      return state;
  }
}
import React from "react";
import { connect } from "react-redux";
import OBLoader from "../../Loader/OBLoader";
import './FAQ.scss';
class FAQ extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      items: this.props.merchant_store.faqs
    };
  }


  render() {
    if(this.props.merchant_store) {

      const faqs = this.props.merchant_store.faqs;
      // var arrangedFaqs = [];
      var arrangedFaqs = faqs;

      // if(faqs[0].sort_order) {
      //   for (var x=1; x<=faqs.length; x++) {
      //     arrangedFaqs.push(faqs.find(faq => faq.sort_order === 'item-'+x));
      //   }
      // } else {
      //   arrangedFaqs = faqs;
      // }

      return (
      <div className="inner-content cf-faq">
        <div className="inner-content-container">
          <div className="section-label">Frequently Asked Questions</div> 
              { arrangedFaqs.map( e => 
                <div className="faq-row">
                  <div className="question">
                    <b>{e.question}</b><br/>
                  </div>
                  <div>
                    {e.answer}
                  </div>
                </div>)
                
              }
          </div>
      </div>
      
      );
    }
    return (<OBLoader/>);
  }
    
   
}
const mapStateToProps = (state) => {
  return {
    merchant_store: state.customer.merchant_store,
    failed: state.customer.load_failed
  };
}
export default connect(mapStateToProps)(FAQ);
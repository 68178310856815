const initialState = {
  loadingOctober: false,
  landingOctoberSuccess: false, 
  loadingScheduleFreeDemo: false, 
  landingScheduleFreeDemo: false,
  loadingGetInTouchDemo: false, 
  landingGetInTouchDemo: false,
  orderHash: null,
  orderHashError: null
};

export default function landingReducer(state = initialState, action) {
  switch(action.type) {
    case 'LANDING_STARTED':
      return {...state, loadingOctober: true};
    case 'LANDING_COMPLETED':
      return {...state, loadingOctober: false, landingOctoberSuccess: true};
    case 'LANDING_SCHEDULE_STARTED':
      return {...state, loadingScheduleFreeDemo: true};
    case 'LANDING_SCHEDULE_COMPLETED':
      return {...state, loadingScheduleFreeDemo: false, landingScheduleFreeDemo: true};
    case 'LANDING_GET_IN_TOUCH_STARTED':
        return {...state, loadingGetInTouchDemo: true};
    case 'LANDING_GET_IN_TOUCH_COMPLETED':
        return {...state, loadingGetInTouchDemo: false, landingGetInTouchDemo: true};
    case 'LANDING_SEARCH_FAILED':
      return {...state, orderHash: null, orderHashError: 'Cannot find order'};
    case 'LANDING_SEARCH_STARTED':
      return {...state, orderHash: null, orderHashError: null};
    case 'LANDING_SEARCH_COMPLETED': 
      return {...state, orderHash: action.payload.hash, orderHashError: null};
    default:
      return state;
  }
}